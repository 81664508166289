import { meInstance } from "config";
import { getHeader } from "config";

export const findAllChronicles = async (query = {}) => {
  return meInstance.get(
    `/public/chronicle${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};
