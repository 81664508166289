export const tableHeadingSD = [
  { label: "Name", field: "name" },
  { label: "Start Date", field: "startDate" },
  { label: "End Date", field: "endDate" },
  { label: "Role", field: "role" },
  // { label: "Openings", field: "opening" },
  { label: "", field: "copyLink" },
  { label: "", field: "openLink" },
];

export const tableHeadingSDTemplate = [
  { label: "Name", field: "name" },
  { label: "", field: "deleteview" },
];
export const driveCategory = {
  "Package-specific": [
    "Observership",
    "Apprenticeship",
    "Fellowship",
    "Fusion Scholarship",
    "Internship",
  ],
  "Offering-specific": ["Industry Safari", "Expert Reflections"],
  "Gig-specific": ["Deliverable based", "Hourly based", "Time-period based"],
  "Initiative-specific": ["TISA", "HEXY", "Project 40", "Townhall"],
  "Contract-specific": ["P1", "P2", "P3", "P4", "P5"],
};

export const SDTableHeadColumnsUsers = [
  { label: "", field: "profile_picture" },
  { label: "Name", field: "first_name" },
  { label: "College", field: "college" },
  { label: "Email", field: "email" },
  { label: "Phone", field: "phone_number" },
];
