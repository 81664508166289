import Tabs from "components/Comman/Tabs";
import { getApplicant } from "config/APIs/applicants";
import { questions } from "helpers/constants/form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

const tabs = [
  { label: "Neo-Generalism", value: "Neo-Generalism" },
  { label: "Agile", value: "Agile" },
  { label: "Interests", value: "Interests" },
  { label: "Preferences", value: "Preferences" },
];

function Applicant() {
  const { id } = useParams();
  const colleges = useSelector((state) => state?.college?.list);
  const [details, setDetails] = useState();
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const getDetails = async () => {
    try {
      const res = await getApplicant(id);
      const applicant = res.data.data;
      setDetails(applicant);
    } catch (err) {
      console.log("Fetch drive error", err);
    }
  };
  const [selectedTab, setSelectedTab] = useState("Neo-Generalism");

  const getScore = (a1, a2) => {
    let sum = 0;
    console.log(a1, a2);
    a1?.map((i) => {
      sum = sum + i?.score;
    });
    a2?.map((i) => {
      sum = sum + i?.score;
    });

    return sum;
  };

  const getAnswer = (key, index) => {
    let value = null;

    switch (key) {
      case "assessment1": {
        value = details?.assessment1?.data[index]?.value;

        break;
      }
      case "assessment2": {
        value = details?.assessment2?.data[index]?.value;

        break;
      }
      case "weekendWork": {
        value = details?.applicantWorkEx?.weekendWork;

        break;
      }
      case "workHours": {
        value = details?.applicantWorkEx?.workHours;

        break;
      }
      case "workTimings": {
        value = details?.applicantWorkEx?.workTimings;

        break;
      }
      case "priorExperience": {
        value = details?.applicant_personalInfo?.priorExperience;

        break;
      }
      case "workExperience": {
        value = details?.applicant_personalInfo?.workExperience;

        break;
      }
      default:
    }

    return value;
  };

  return (
    <div>
      <div
        style={{
          boxShadow:
            "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.10), 0px 0px 16px 0px rgba(0, 0, 0, 0.05)",
        }}
        className="bg-white p-4"
      >
        <div className="p-4 flex flex-col space-y-10">
          <h1 className="text-primary-gray-800 font-poppins text-xl font-medium text-center">
            {details?.firstName} {details?.lastName}
          </h1>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center space-x-2">
              <p className="text-primary-gray-800 font-inter text-sm font-semibold">
                Score:{" "}
              </p>
              <p className="text-primary-gray-800 font-inter text-sm font-semibold">
                {getScore(
                  details?.assessment1?.data,
                  details?.assessment2?.data
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <p className="text-primary-gray-400 font-inter text-xs font-semibold">
              Details
            </p>
            <div className="flex flex-col space-y-2">
              <div className="px-2 space-x-4 flex flex-row items-center text-primary-gray-800 font-inter text-xs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M13.333 2.66797H2.66634C1.93301 2.66797 1.33967 3.26797 1.33967 4.0013L1.33301 12.0013C1.33301 12.7346 1.93301 13.3346 2.66634 13.3346H13.333C14.0663 13.3346 14.6663 12.7346 14.6663 12.0013V4.0013C14.6663 3.26797 14.0663 2.66797 13.333 2.66797ZM13.333 12.0013H2.66634V5.33464L7.99967 8.66797L13.333 5.33464V12.0013ZM7.99967 7.33464L2.66634 4.0013H13.333L7.99967 7.33464Z"
                    fill="#9C9A96"
                  />
                </svg>
                <p>{details?.email}</p>
              </div>
              <div className="px-2 space-x-4 flex flex-row items-center text-primary-gray-800 font-inter text-xs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14 10.9467V13.304C14.0001 13.4728 13.9361 13.6353 13.8211 13.7588C13.706 13.8823 13.5484 13.9575 13.38 13.9693C13.0887 13.9893 12.8507 14 12.6667 14C6.77533 14 2 9.22467 2 3.33333C2 3.14933 2.01 2.91133 2.03067 2.62C2.04248 2.45163 2.11772 2.29401 2.2412 2.17894C2.36468 2.06387 2.52722 1.99992 2.696 2H5.05333C5.13603 1.99992 5.2158 2.03057 5.27715 2.08601C5.33851 2.14145 5.37706 2.21772 5.38533 2.3C5.40067 2.45333 5.41467 2.57533 5.428 2.668C5.56049 3.59262 5.832 4.49189 6.23333 5.33533C6.29667 5.46867 6.25533 5.628 6.13533 5.71333L4.69667 6.74133C5.5763 8.79097 7.2097 10.4244 9.25933 11.304L10.286 9.868C10.328 9.80933 10.3892 9.76725 10.459 9.7491C10.5288 9.73095 10.6028 9.73787 10.668 9.76867C11.5113 10.1692 12.4104 10.4401 13.3347 10.572C13.4273 10.5853 13.5493 10.6 13.7013 10.6147C13.7835 10.6231 13.8596 10.6617 13.9149 10.7231C13.9702 10.7844 14.0001 10.8641 14 10.9467Z"
                    fill="#9C9A96"
                  />
                </svg>
                <p>{details?.phoneNumber}</p>
              </div>
              <div className="px-2 space-x-4 flex flex-row items-center text-primary-gray-800 font-inter text-xs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M15.5584 5.42928L8.58488 3.28678C8.20487 3.17003 7.79487 3.17003 7.41512 3.28678L0.441375 5.42928C-0.147125 5.61003 -0.147125 6.38828 0.441375 6.56903L1.65713 6.94253C1.39038 7.27228 1.22637 7.67453 1.21012 8.11503C0.969375 8.25303 0.799875 8.50203 0.799875 8.79928C0.799875 9.06878 0.941875 9.29553 1.14638 9.44053L0.508125 12.3125C0.452625 12.5623 0.642625 12.7993 0.898375 12.7993H2.30113C2.55713 12.7993 2.74713 12.5623 2.69163 12.3125L2.05338 9.44053C2.25788 9.29553 2.39988 9.06878 2.39988 8.79928C2.39988 8.51003 2.23813 8.26803 2.00837 8.12753C2.02737 7.75203 2.21938 7.42003 2.52562 7.20953L7.41488 8.71178C7.64138 8.78128 8.07588 8.86803 8.58463 8.71178L15.5584 6.56928C16.1471 6.38828 16.1471 5.61028 15.5584 5.42928ZM8.81962 9.47653C8.10637 9.69553 7.49863 9.57453 7.17988 9.47653L3.55437 8.36278L3.19988 11.1993C3.19988 12.083 5.34888 12.7993 7.99988 12.7993C10.6509 12.7993 12.7999 12.083 12.7999 11.1993L12.4454 8.36253L8.81962 9.47653Z"
                    fill="#9C9A96"
                  />
                </svg>
                <p>
                  {
                    colleges?.find((c) => c?.id == details?.associatedCollegeId)
                      ?.college_name
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 px-4">
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className="mt-10">
          <div className="flex flex-col space-y-8 mt-10">
            {questions[selectedTab]?.map((item, index) => {
              return (
                <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col space-y-4">
                  {item?.subtitle && (
                    <div className="flex flex-row items-center space-x-3 text-primary-green-medium font-poppins text-2xs font-medium leading-4 tracking-[1px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M14.0003 5.33203C13.0337 5.33203 12.4937 6.29203 12.7137 7.00536L10.347 9.3787C10.147 9.3187 9.85366 9.3187 9.65366 9.3787L7.95366 7.6787C8.18033 6.96536 7.64033 5.9987 6.66699 5.9987C5.70033 5.9987 5.15366 6.9587 5.38032 7.6787L2.34033 10.712C1.62699 10.492 0.666992 11.032 0.666992 11.9987C0.666992 12.732 1.26699 13.332 2.00033 13.332C2.96699 13.332 3.50699 12.372 3.28699 11.6587L6.32032 8.6187C6.52032 8.6787 6.81366 8.6787 7.01366 8.6187L8.71366 10.3187C8.48699 11.032 9.02699 11.9987 10.0003 11.9987C10.967 11.9987 11.5137 11.0387 11.287 10.3187L13.6603 7.95203C14.3737 8.17203 15.3337 7.63203 15.3337 6.66536C15.3337 5.93203 14.7337 5.33203 14.0003 5.33203Z"
                          fill="#178229"
                        />
                        <path
                          d="M10.0003 6L10.627 4.62L12.0003 4L10.627 3.38L10.0003 2L9.38699 3.38L8.00033 4L9.38699 4.62L10.0003 6ZM2.33366 7.33333L2.66699 6L4.00033 5.66667L2.66699 5.33333L2.33366 4L2.00033 5.33333L0.666992 5.66667L2.00033 6L2.33366 7.33333Z"
                          fill="#178229"
                        />
                      </svg>{" "}
                      <p>{item?.subtitle}</p>
                    </div>
                  )}
                  <p className="text-primary-gray-800 font-inter text-xs font-light">
                    {item?.questionComponent || item?.question}
                  </p>
                  {item?.type == "scale" ? (
                    <div className="border border-primary-gray-200 m-1 text-primary-gray-800 font-inter text-2xs font-light rounded-full py-1.5 px-3 flex flex-row items-center justify-center">
                      {getAnswer(item?.key, index)}
                    </div>
                  ) : item?.options ? (
                    <div className="flex flex-row flex-wrap -ml-1 -mt-1">
                      {item?.options?.map((op) => {
                        if (
                          item?.type == "choice" &&
                          getAnswer(item?.key) === op
                        )
                          return (
                            <div className="border border-primary-green-dark bg-primary-green-lightest m-1 text-primary-green-dark font-inter text-2xs font-light rounded-full py-1.5 px-3 flex flex-row items-center justify-center">
                              {op}
                            </div>
                          );
                        else if (
                          item?.type == "choices" &&
                          getAnswer(item?.key)?.includes(op)
                        )
                          return (
                            <div className="border border-primary-green-dark bg-primary-green-lightest m-1 text-primary-green-dark font-inter text-2xs font-light rounded-full py-1.5 px-3 flex flex-row items-center justify-center">
                              {op}
                            </div>
                          );
                        else
                          return (
                            <div className="border border-primary-gray-200 m-1 text-primary-gray-800 font-inter text-2xs font-light rounded-full py-1.5 px-3 flex flex-row items-center justify-center">
                              {op}
                            </div>
                          );
                      })}
                    </div>
                  ) : (
                    <div className="border border-primary-gray-200 m-1 text-primary-gray-800 font-inter text-2xs font-light rounded-full py-1.5 px-3 flex flex-row items-center justify-center">
                      Fixed Work Timings
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Applicant;
