import { ChevronRightIcon } from "@heroicons/react/outline";
import { Fire } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import moment from "moment";
import { MdLocalFireDepartment } from "react-icons/md";
function TalentRequestCard({ item, ctaText = "View Details", cta }) {
  return (
    <>
      <div className="p-6 lg:px-10 space-y-6 hidden lg:block">
        <div className="flex flex-col items-start lg:grid lg:grid-cols-5 gap-6">
          <div className="flex flex-col items-start space-y-1 font-lato text-primary-gray-800">
            <p className="text-base font-semibold">{item?.name}</p>
            <p className="text-sm font-light">
              For {item?.project?.title || item?.designation?.role?.role_name}
            </p>
          </div>
          <div className="flex flex-col items-start text-left space-y-1 font-lato text-primary-gray-800">
            <p className="text-base font-semibold">{item?.openings}</p>
            <p className="text-sm font-light">Openings</p>
          </div>
          <div className="flex flex-col items-start text-left space-y-1 font-lato text-primary-gray-800">
            <p className="text-base font-semibold">
              {moment(item?.expiryDate).format("ll")}
            </p>
            <p className="text-sm font-light">Expiring On</p>
          </div>
          <div className="flex flex-col items-center space-y-1 font-lato text-primary-gray-800">
            <p className="text-xs font-semibold">
              <span className="flex items-center p-1 rounded bg-primary-red-100">
                <span>
                  <MdLocalFireDepartment
                    className="text-primary-red-900"
                    size={16}
                  />
                </span>
                <span className="text-xs font-semibold">{item?.priority}</span>
              </span>
            </p>
            <p className="text-sm font-light">Priority</p>
          </div>
          <div className="flex flex-col items-end space-y-1 font-lato text-primary-gray-800">
            <ChronosButton text={ctaText} secondary onClick={cta} />
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-end text-primary-gray-250 font-lato text-xs font-light">
          Requested by {item?.creator?.first_name}
        </div>
      </div>

      <div className="p-4 relative font-lato bg-white rounded-lg shadow-md border border-primary-80 space-y-2 block lg:hidden">
        <div className="flex flex-col items-start space-y-1 font-lato text-primary-gray-800">
          <p className="text-xs text-primary-green-700 font-semibold">
            {item?.name}
          </p>
          <p className="text-2xs text-primary-gray-350 font-light">
            For {item?.project?.title || item?.designation?.role?.role_name}
          </p>
        </div>

        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-row items-center justify-start w-1/2 space-x-2">
            <div className="flex items-center">
              <span>
                <Fire className="text-primary-red-900" size={12} />
              </span>
              <span className="text-xs font-semibold">{item?.priority}</span>
            </div>
            <p className="text-2xs font-light text-primary-gray-350">
              {moment(item?.expiryDate).format("ll")}
            </p>
          </div>
          <div className="w-full flex flex-row items-center justify-end text-primary-gray-250 font-lato text-3xs font-light">
            Requested by {item?.creator?.first_name}
          </div>
        </div>

        {cta && (
          <ChevronRightIcon
            className="w-4 h-4 absolute top-2 right-4 text-primary-green-700"
            onClick={cta}
          />
        )}
      </div>
    </>
  );
}

export default TalentRequestCard;
