export const PROSPECTS = "Prospects";
export const INTERVIEW = "Interview";
export const OFFER = "Offer";
export const ONBOARDING = "Onboarding";

export const TALENT_SHORTLIST_STAGES = [
  PROSPECTS,
  INTERVIEW,
  OFFER,
  ONBOARDING,
];

export const TALENT_SHORTLIST_STAGES_COLORS = {
  [PROSPECTS]: {
    color: "text-primary-orange-400",
    backgroundColor: "bg-primary-orange-100",
  },
  [INTERVIEW]: {
    color: "text-primary-blue-400",
    backgroundColor: "bg-primary-blue-100",
  },
  [OFFER]: {
    color: "text-primary-indigo-400",
    backgroundColor: "bg-primary-indigo-100",
  },
  [ONBOARDING]: {
    color: "text-primary-green-400",
    backgroundColor: "bg-primary-green-100",
  },
};

export const TALENT_SHORTLIST_STAGES_STATUS = {
  [PROSPECTS]: ["Waiting for Response", "Interested", "Not Interested"],
  [INTERVIEW]: [
    "First Round",
    "Second Round",
    "Third Round",
    "Fourth Roundg",
    "Dropped",
  ],
  [OFFER]: [
    "Offer Letter Not Sent",
    "Offer Letter Sent",
    "Offer Accepted",
    "Not Responding",
    "Offer Not Accepted",
  ],
  [ONBOARDING]: [
    "Tribe Onboarding",
    "Clan Onboarding",
    "Project Onboarding",
    "Project Onboarding",
    "Dropped",
  ],
};
