import React, { useState } from "react";
import PageHeader from "components/Layout/PageHeader";
import TalentRequestCard from "components/TalentRequest/Card/RequestCard";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import SearchBox from "components/Comman/Inputs/SearchBox";
import SortIcon from "@mui/icons-material/Sort";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const DriveRequests = () => {
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  const history = useHistory();
  const list = useSelector((state) => state?.talent?.list);
  return (
    <>
      <PageHeader back name="Drive Requests" />
      <div className="lg:px-5 lg:pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="p-4 lg:p-6 space-y-6 lg:mt-2 bg-white lg:rounded-2xl">
          {window.innerWidth > 720 ? (
            <div className="relative max-w-5xl flex flex-col lg:flex-row items-center justify-between space-y-4 lg:space-y-0 lg:space-x-4">
              <SearchBox
                placeholder="Find a template!"
                search={search}
                setSearch={setSearch}
              />
            </div>
          ) : (
            <div className="flex basis-1/2 flex-start px-4 gap-2 items-center content-center justify-center border border-primary-gray-150">
              <div className="flex py-3 px-4 gap-4 items-center justify-center w-full">
                <SortIcon fontSize="20px" />
                <p>Sort</p>
              </div>
              <div className="border-2 border-primary-gray-150 self-stretch content-center rounded" />
              <div className="flex py-3 px-4 gap-4 items-center justify-center w-full">
                <FilterAltOutlinedIcon fontSize="20px" />
                <p className="w-">Filter</p>
              </div>
            </div>
          )}
          <div className="py-3 px-2 lg:px-6 lg:py-6 rounded-lg bg-primary-gray-50">
            <div className="bg-white divide-y hidden lg:flex flex-col">
              {list?.map((t) => {
                return (
                  <>
                    <TalentRequestCard
                      item={t}
                      ctaText="Create Drive"
                      cta={() =>
                        history.push({
                          pathname: "/drive/create/0",
                          state: { talentRequestId: t?.id },
                        })
                      }
                    />
                  </>
                );
              })}
            </div>
            <div className="space-y-3 flex lg:hidden flex-col">
              {list?.map((t) => {
                return (
                  <>
                    <TalentRequestCard
                      item={t}
                      cta={() => {
                        history.push(`/talents/${t?.id}`);
                      }}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriveRequests;
