export const SHORTLIST_FOR_ORIENTATTION = "Shortlisted for Orientation";
export const VALUE_CULTURE_FIT = "Value & Culture Fit Round";
export const LIFE_ONBOARDING = "Life Onboarding";
export const ME_PROFILING = "Me Profiling";
export const PGM_SESSION = "PGM Session";

export const APPLICANT_STAGES = [
  SHORTLIST_FOR_ORIENTATTION,
  VALUE_CULTURE_FIT,
  LIFE_ONBOARDING,
  ME_PROFILING,
  PGM_SESSION,
];

export const APPLICANT_STAGES_COLORS = {
  [SHORTLIST_FOR_ORIENTATTION]: {
    color: "text-primary-indigo-400",
    backgroundColor: "bg-primary-indigo-100",
  },
  [VALUE_CULTURE_FIT]: {
    color: "text-primary-green-400",
    backgroundColor: "bg-primary-green-100",
  },
  [LIFE_ONBOARDING]: {
    color: "text-primary-orange-400",
    backgroundColor: "bg-primary-orange-100",
  },
  [ME_PROFILING]: {
    color: "text-primary-blue-400",
    backgroundColor: "bg-primary-blue-100",
  },
  [PGM_SESSION]: {
    color: "text-primary-red-400",
    backgroundColor: "bg-primary-red-100",
  },
};

export const APPLICANT_STAGES_STATUS = {
  [SHORTLIST_FOR_ORIENTATTION]: [
    "Orientation Not Scheduled",
    "Orientation Scheduled",
    "Orientation Attended",
    "Orientation Not Attended",
  ],
  [VALUE_CULTURE_FIT]: [
    "Interview Not Scheduled",
    "Interview Scheduled",
    "Selected for FTP",
    "Not Responding",
    "Dropped",
  ],
  [LIFE_ONBOARDING]: [
    "Onboarding Email Not Sent",
    "Onboarding Email Sent",
    "Onboarded",
    "Not Responding",
    "Dropped",
  ],
  [ME_PROFILING]: [
    "Profiling Not Started",
    "Phase 1 Completed",
    "Profiling Completed",
    "Inactive",
    "Dropped",
  ],
  [PGM_SESSION]: [
    "Session Not Scheduled",
    "Session Scheduled",
    "Session Attended",
    "Not Responding",
    "Dropped",
  ],
};
