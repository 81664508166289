import PageHeader from "components/Layout/PageHeader";
import CollegeList from "components/Colleges";

/**
 * Displays College Management Component
 * @param {Object} permissions
 * @returns
 */

const Colleges = () => {
  return (
    <div className="">
      <PageHeader name="CGC Management" />
      <div className="px-6">
        <CollegeList />
      </div>
    </div>
  );
};

export default Colleges;
