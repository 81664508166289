import { createSlice } from "@reduxjs/toolkit";
import { getAttributes } from "config/APIs/attributes";
import { getAllAccess } from "config/APIs/common";
import { getAllUsersAdmin, getRolesUserAdmin } from "config/APIs/users";
import * as CollegeAPI from "config/APIs/users/colleges";

const initialState = {
  users: [],
  colleges: [],
  roles: [],
  ranks: [],
  designations: [],
  projects: [],
  allProjects: [],
  mentors: [],
  departments: [],
  status: [],
  attributeTypes: [],
  attributes: [],
  crew: [],
  cgc: [],
};

export const dropdown = createSlice({
  name: "dropdown",
  initialState,
  reducers: {
    updateUsers: (state, action) => {
      state.users = action.payload.users;
    },
    updateMentors: (state, action) => {
      state.mentors = action.payload.users;
    },
    updateDepartments: (state, action) => {
      state.departments = action.payload.departments;
    },
    updateCollege: (state, action) => {
      state.colleges = action.payload.colleges;
    },
    updateRoles: (state, action) => {
      state.roles = action.payload.roles;
    },
    updateRanks: (state, action) => {
      state.ranks = action.payload.ranks;
    },
    updateStatus: (state, action) => {
      state.status = action.payload.status;
    },
    updateAttributesType: (state, action) => {
      state.attributeTypes = action.payload.type;
    },
    updateDesignation: (state, action) => {
      state.designations = action.payload.designations;
    },
    updateProject: (state, action) => {
      state.projects = action.payload.projects;
    },
    updateAllProject: (state, action) => {
      state.allProjects = action.payload.allProjects;
    },
    updateReduxDropdownList: (state, action) => {
      let field = action.payload.field;
      state[field] = action.payload.list;
    },
  },
});

export const {
  updateUsers,
  updateCollege,
  updateRanks,
  updateRoles,
  updateStatus,
  updateDesignation,
  updateProject,
  updateAllProject,
  updateMentors,
  updateDepartments,
  updateAttributesType,
  updateReduxDropdownList,
} = dropdown.actions;
export default dropdown.reducer;

export function fetchAllUsers() {
  return async (dispatch) => {
    try {
      const response = await getAllUsersAdmin({ is_active: true });

      if (response.status === 200) {
        let data = response.data.data.response;

        // Dispatching user data to redux-store
        dispatch(
          updateUsers({
            users: data,
          })
        );
      }
    } catch (err) {
      console.log("user fetching error", err);
    }
  };
}

export function fetchAllCollege(type = "colleges") {
  return async (dispatch) => {
    try {
      const response = await CollegeAPI.findAll(type);
      if (response.status === 200) {
        let data = response.data.data.response;

        dispatch(
          updateReduxDropdownList({
            list: data,
            field: type,
          })
        );
      }
    } catch (err) {
      console.log("college fetching error", err);
    }
  };
}
export function fetchAllAccess({ type, query }) {
  return async (dispatch) => {
    try {
      const response = await getAllAccess(type, query);
      if (response.status === 200) {
        let data = response.data[type] || response.data?.data[type];

        // Dispatching designation data to redux-store
        dispatch(
          updateReduxDropdownList({
            list: data,
            field: type,
          })
        );
      }
    } catch (err) {
      console.log("Designation fetching error", err);
    }
  };
}
export function fetchAllRoles() {
  return async (dispatch) => {
    try {
      const response = await getRolesUserAdmin();
      if (response.status === 200) {
        const { roles } = response.data.data;

        // Dispatching roles data to redux-store
        dispatch(
          updateRoles({
            roles: roles,
          })
        );
      }
    } catch (err) {
      console.log("Roles fetching error", err);
    }
  };
}

export function fetchAllAttributesType(type = "attributeTypes") {
  return async (dispatch) => {
    try {
      const response = await getAttributes(type);
      if (response.status === 200) {
        const data = response.data.data;

        dispatch(
          updateReduxDropdownList({
            list: data,
            field: type,
          })
        );
      }
    } catch (err) {
      console.log("AttributesType fetching error", err);
    }
  };
}
