import { createSlice } from "@reduxjs/toolkit";
import { getAllEntity } from "config/APIs/sourcingDrive";
import { feBaseLink } from "config";

const initialState = {
  list: [],
  templates: [],
};

// Storing members
export const sourcingDrive = createSlice({
  name: "sourcingDrive",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload.list;
    },
    updateTemplate: (state, action) => {
      state.templates = action.payload.list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList, updateTemplate } = sourcingDrive.actions;

export default sourcingDrive.reducer;

export function fetchAllDrives() {
  return async (dispatch) => {
    try {
      const response = await getAllEntity({ drive: true, showStats: true });
      if (response.status === 200) {
        let data = response.data.data;
        data = data?.map((i) => ({
          ...i,
          copyLink: `${feBaseLink?.applicationForm}/${i?.code}`,
          openLink: `${feBaseLink?.applicationForm}/${i?.code}`,
        }));
        // Dispatching roles data to redux-store
        dispatch(
          updateList({
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("SD fetching error", err);
    }

    try {
      const response = await getAllEntity({
        is_template: true,
      });
      if (response.status === 200) {
        let data = response.data.data;

        // Dispatching roles data to redux-store
        dispatch(
          updateTemplate({
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("SD fetching error", err);
    }
  };
}
