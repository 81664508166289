// Used for Sourcing Drive and Profiling Templates

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

//Redux
import { useDispatch } from "react-redux";
import * as SourcingDriveAPI from "config/APIs/sourcingDrive";
import { showToast } from "redux/toaster";
import Details from "components/Templates/CreateEditView/Details";
import ProfilingAttributes from "components/Templates/CreateEditView/ProfilingAttributes";
import TemplateSelection from "components/Templates/CreateEditView/Details/TemplateSelection";
import PageHeader from "components/Layout/PageHeader";
import ChronosButton from "components/Comman/Buttons";

const defaultValue = {
  opening: 0,
  sectionMetaData: {
    "Personal Information": [],
    "Working Preferences": [],
    "Work History": [],
    Personality: [],
    Skills: [],
    Interests: [],
    Other: [],
  },
};
function CreateEditTemplate({ isDrive, talentRequest, create, update }) {
  const { createEdit: type, id, type: templateDrive } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [newData, setNewData] = useState(defaultValue);
  const [creating, setCreating] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleCreate = async () => {
    if (!("name" in newData) || newData?.name === "") {
      dispatch(showToast({ message: "Enter a valid Name", type: "error" }));
      return;
    }
    setCreating(true);

    let { sectionMetaData, colleges, ...body } = newData;

    if (
      !sectionMetaData["Personal Information"] ||
      (sectionMetaData["Personal Information"] &&
        !sectionMetaData["Personal Information"]?.find(
          (i) => i?.name == "Name"
        )) ||
      !sectionMetaData["Personal Information"]?.find(
        (i) => i?.name == "Email"
      ) ||
      !sectionMetaData["Personal Information"]?.find(
        (i) => i?.name == "Phone Number"
      )
    ) {
      showErrorNotification("Form must contain Name, Email and Phone Number");
      return;
    }

    delete body?.evp;
    delete body?.role;
    delete body?.owner;
    delete body?.templateSections;

    let tempSecData = {};
    Object.keys(sectionMetaData)?.map((key) => {
      let t = [...sectionMetaData[key]];
      t = t?.filter((i) => i?.name !== null && i?.name !== "");

      tempSecData[key] = t;
    });

    body["sectionMetaData"] = tempSecData;
    if (templateDrive == "template") {
      body["is_template"] = true;
    }
    if (talentRequest) {
      body["talentRequestId"] = talentRequest?.id;
    }
    if (colleges?.length > 0) {
      body.colleges = colleges?.map((i) => i?.id);
    }
    try {
      const response = await SourcingDriveAPI.createEntity(body);
      if (response.data) {
        history.goBack();
      }
    } catch (err) {
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
  };

  const handleUpdate = async () => {
    setUpdating(true);
    let { sectionMetaData, colleges, ...body } = newData;

    delete body?.evp;
    delete body?.role;
    delete body?.owner;
    delete body?.templateSections;

    Object.keys(sectionMetaData)?.map((key) => {
      let t = sectionMetaData[key];
      t = t?.filter((i) => i?.name !== null && i?.name !== "");
      sectionMetaData[key] = t;
    });

    body["sectionMetaData"] = sectionMetaData;
    if (templateDrive == "template") {
      body["is_template"] = true;
    }
    if (colleges?.length > 0) {
      body.colleges = colleges?.map((i) => i?.id);
    }
    if (talentRequest) {
      body["talentRequestId"] = talentRequest?.id;
    }
    try {
      const response = await SourcingDriveAPI.updateEntity(id, body);
      if (response.data) {
        history.goBack();
      }
    } catch (err) {
      console.log("Sourcing Drive update err:", err);
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
  };

  const getDetails = async () => {
    setFetching(true);
    try {
      const response = await SourcingDriveAPI.getOneEntity(id);
      if (response.data) {
        let data = response?.data?.data;
        if (
          !Object.keys(data["sectionMetaData"])?.includes(
            "Personal Information"
          )
        ) {
          data["sectionMetaData"]["Personal Information"] =
            data["sectionMetaData"]["Personal Info"];
        }
        setNewData(data);
      }
    } catch (err) {
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
    setFetching(false);
  };

  useEffect(() => {
    if (type == "edit") {
      getDetails();
    }
  }, []);

  useEffect(() => {
    if (type == "create" && isDrive && talentRequest && create) {
      handleCreate();
    }
  }, [create]);

  useEffect(() => {
    if (type == "edit" && isDrive && talentRequest && update) {
      handleUpdate();
    }
  }, [update]);

  return (
    <>
      {!isDrive && (
        <PageHeader
          back
          name={
            isDrive || templateDrive == "drive"
              ? "Create Sourcing Drive"
              : "Create Profiling Template"
          }
          ctaComponent={
            <div className="flex flex-row items-center justify-end space-x-5">
              <ChronosButton
                text="Cancel"
                secondary
                onClick={() => {
                  history.goBack();
                }}
              />
              <ChronosButton
                text={`${type == "create" ? "Create" : "Update"} ${
                  templateDrive == "drive" ? "Drive" : "Template"
                }`}
                primary
                onClick={() => {
                  type == "create" ? handleCreate() : handleUpdate();
                }}
              />
            </div>
          }
        />
      )}
      <div className="px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className={`w-full space-y-6 pb-20 ${isDrive ? "-mt-5" : ""}`}>
          <div className="w-full flex flex-col items-stretch lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
            <div
              className={`${
                isDrive || templateDrive == "drive"
                  ? "w-full lg:w-4/12"
                  : "w-full"
              }`}
            >
              <Details
                fetching={fetching}
                details={newData}
                setDetails={setNewData}
                type={type}
                templateDrive={
                  isDrive ? "drive" : templateDrive ? templateDrive : "template"
                }
              />
            </div>
            {(isDrive || templateDrive == "drive") && (
              <TemplateSelection details={newData} setDetails={setNewData} />
            )}
          </div>

          {isDrive || templateDrive == "drive" ? (
            !newData?.templateSections ? (
              <div className="bg-white rounded-2xl py-4 px-8 flex flex-row items-center justify-center h-[30vh] text-primary-gray-200 font-lato text-xl font-semibold">
                Select a template to continue
              </div>
            ) : (
              <>
                <ProfilingAttributes
                  details={newData}
                  setDetails={setNewData}
                  defaultSection
                  fetching={fetching}
                  type={type}
                />
                <ProfilingAttributes
                  details={newData}
                  setDetails={setNewData}
                  fetching={fetching}
                  type={type}
                />
              </>
            )
          ) : (
            <>
              <ProfilingAttributes
                details={newData}
                setDetails={setNewData}
                defaultSection
                fetching={fetching}
                type={type}
              />
              <ProfilingAttributes
                details={newData}
                setDetails={setNewData}
                fetching={fetching}
                type={type}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateEditTemplate;
