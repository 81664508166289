import React, { useState } from "react";
import UserList from "components/FTP/UserList";
import UserDetails from "components/FTP/UserDetails";
import { Drawer } from "@mui/material";
import PageHeader from "components/Layout/PageHeader";

function FTP() {
  const [selectedUser, setSelectedUser] = useState();
  return (
    <>
      <PageHeader name="Future Talent Pool" />
      <div className="px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="block lg:mt-5">
          <div className="grid lg:grid-cols-2 gap-8 font-lato">
            <UserList
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />

            <div className="hidden lg:block">
              <UserDetails selectedUser={selectedUser} />
            </div>

            <Drawer
              anchor="bottom"
              open={selectedUser && window.innerWidth < 1024 ? true : false}
              onClose={() => setSelectedUser()}
              PaperProps={{
                style: { maxHeight: "100vh" },
              }}
            >
              <UserDetails
                selectedUser={selectedUser}
                closeModal={() => {
                  setSelectedUser();
                }}
              />
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
}

export default FTP;
