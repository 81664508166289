import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as DriveAPI from "config/APIs/sourcingDrive";
import moment from "moment";
import { MdLocalFireDepartment } from "react-icons/md";
import { Transition, Disclosure } from "@headlessui/react";
import { BiChevronUp } from "react-icons/bi";
import { LongTabs } from "components/Comman/Tabs";
import ApplicantTable from "components/SourcingDrive/Drive/ApplicantTable";

import PageHeader from "components/Layout/PageHeader";
import ChronosButton from "components/Comman/Buttons";
import { ArrowRight } from "@phosphor-icons/react";
import BoardView from "components/SourcingDrive/Drive/BoardView";
import TalentRequestCard from "components/TalentRequest/Card/RequestCard";
import ShortlistedList from "components/SourcingDrive/Drive/ShortlistedList";

const tabs = [
  {
    label: "All Applicants",
    value: "all",
  },
  {
    label: "Shortlisted Applicants",
    value: "shortlisted",
  },
];

function SourcingDrivePage() {
  const { id } = useParams();

  const history = useHistory();
  const [details, setDetails] = useState({});

  const [applicants, setApplicants] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      getDetails();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  const getDetails = async () => {
    try {
      const response = await DriveAPI.getOneEntity(id);
      const drive = response.data.data;
      setDetails(drive);
      setApplicants(drive?.applicants);
    } catch (err) {
      console.log("Fetch drive error", err);
    }
  };

  return (
    <>
      <PageHeader back name="Sourcing Drive Details" />
      <div className="lg:px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px] space-y-6">
        <div className="px-6 py-4 bg-white rounded-2xl space-y-4 w-full">
          <Disclosure
            defaultOpen
            className="bg-white rounded-2xl space-y-4 w-full"
          >
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full flex flex-row items-center justify-between">
                  <div className="w-full flex items-center justify-between pb-4 border-b border-gray-200">
                    <div className="flex flex-col items-start space-y-0.5">
                      <p className="text-lg font-light text-primary-green-dark font-lato">
                        {details?.name}
                      </p>
                      <p className="text-sm font-light text-primary-gray-800 font-lato">
                        Typeform UTM: {details?.utm}
                      </p>
                      <p className="text-sm font-light text-primary-gray-800 font-lato">
                        {moment(details?.startDate).format("ll")} to{" "}
                        {moment(details?.endDate).format("ll")}
                      </p>
                    </div>
                    <BiChevronUp
                      className={
                        open
                          ? "transform w-6 scale-125"
                          : "-rotate-180 w-6 scale-125"
                      }
                    />
                  </div>
                </Disclosure.Button>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="w-full text-primary-gray-800 font-lato rounded-2xl space-y-8">
                    <div className="flex flex-row items-center justify-between lg:px-12">
                      <p className="text-primary-gray-800 font-lato text-sm font-light">
                        <span className="text-primary-green-700 font-karla text-3xl font-medium">
                          {applicants?.length || 0}
                        </span>
                        &nbsp; Applicants
                      </p>
                      <ChronosButton
                        text="Review Template"
                        underline
                        tertiary
                        onClick={() => {
                          history.push(
                            `/template/edit/${details?.template?.id}`
                          );
                        }}
                      />
                    </div>
                    <div className="bg-primary-gray-50 rounded-2xl space-y-4 py-4 px-4 lg:px-10">
                      <h1 className="text-primary-gray-800 font-karla text-base font-medium">
                        Associated Talent Request
                      </h1>
                      <div className="lg:grid lg:grid-cols-5 gap-6 hidden">
                        <div className="flex flex-col items-start space-y-1 font-lato text-primary-gray-800">
                          <p className="text-sm font-semibold">
                            {details?.talentRequest?.name}
                          </p>
                          <p className="text-sm font-light">
                            For{" "}
                            {details?.talentRequest?.project?.title ||
                              details?.talentRequest?.designation?.role
                                ?.role_name}
                          </p>
                        </div>
                        <div className="flex flex-col items-start text-left space-y-1 font-lato text-primary-gray-800">
                          <p className="text-sm font-semibold">
                            {details?.talentRequest?.openings}
                          </p>
                          <p className="text-sm font-light">Openings</p>
                        </div>
                        <div className="flex flex-col items-start text-left space-y-1 font-lato text-primary-gray-800">
                          <p className="text-sm font-semibold">
                            {moment(details?.talentRequest?.expiryDate).format(
                              "ll"
                            )}
                          </p>
                          <p className="text-sm font-light">Expiring On</p>
                        </div>
                        <div className="flex flex-col items-center space-y-1 font-lato text-primary-gray-800">
                          <p className="text-xs font-semibold">
                            <span className="flex items-center p-1 rounded bg-primary-red-100">
                              <span>
                                <MdLocalFireDepartment
                                  className="text-primary-red-900"
                                  size={12}
                                />
                              </span>
                              <span className="text-xs font-semibold">
                                {details?.talentRequest?.priority}
                              </span>
                            </span>
                          </p>
                          <p className="text-sm font-light">Priority</p>
                        </div>
                        <div className="flex flex-col items-end space-y-1 font-lato text-primary-gray-800">
                          <ChronosButton
                            text="View Details"
                            tertiary
                            underline
                            icon={
                              <ArrowRight
                                size={20}
                                className="ml-2 -rotate-45"
                              />
                            }
                            onClick={() => {
                              history.push(
                                `/talents/${details?.talentRequest?.id}`
                              );
                            }}
                          />
                        </div>
                      </div>
                      <TalentRequestCard
                        item={details?.talentRequest}
                        cta={() => {
                          history.push(
                            `/talents/${details?.talentRequest?.id}`
                          );
                        }}
                      />
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
        <div className="bg-white rounded-2xl font-lato text-primary-gray-800">
          <LongTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />

          {selectedTab == "all" && <ApplicantTable applicants={applicants} />}
          {selectedTab == "shortlisted" && (
            <div>
              <div className="hidden lg:block">
                <BoardView
                  roleId={details?.role?.id}
                  onUpdate={() => getDetails()}
                  data={applicants?.filter((a) => a?.shortlisted !== null)}
                />
              </div>
              <div className="block lg:hidden">
                <ShortlistedList
                  roleId={details?.role?.id}
                  onUpdate={() => getDetails()}
                  data={applicants?.filter((a) => a?.shortlisted !== null)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SourcingDrivePage;
