export const constantOptions = {
  "Personal Information": [
    { name: "Name", type: "input", id: "Name" },
    { name: "Gender", type: "gender", id: "Gender" },
    { name: "Email", type: "email", id: "Email" },
    { name: "Phone Number", type: "phoneNumber", id: "PhoneNumber" },
    { name: "Profile Pic", type: "filePicker", id: "ProfilePic" },
    { name: "College", type: "college", id: "College" },
    { name: "Year", type: "year", id: "YearOfStudy" },
    { name: "Stream", type: "input", id: "Stream" },
  ],
  "Working Preferences": [
    { name: "Learning Ratio", type: "doubleRange", id: "LearningRatio" },
  ],
  "Work History": [
    { name: "Work/Portfolio Link", type: "input", id: "PortfolioLink" },
    { name: "LinkedIn Profile", type: "input", id: "LinkedInLink" },
    { name: "Resume", type: "filePicker", id: "ResumeUrl" },
  ],
  Personality: [],
  Skills: [],
  Interests: [],
  Other: [],
};

export const profilingSections = [
  "Personal Information",
  "Working Preferences",
  "Work History",
  "Personality",
  "Skills",
  "Interests",
  "Other",
];

export const profilingSectionsInput = [
  { label: "Text", value: "text" },
  { label: "Select one", value: "select" },
  { label: "Multi-select", value: "multiselect" },
  // { label: "Text", value: "text" },
];
