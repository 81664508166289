import React from "react";
import { Redirect } from "react-router-dom";

import CGC from "pages/CGCs";
import College from "pages/CGCs/College";
import Community from "pages/Community";
import SourcingDrive from "pages/SourcingDrive";
import LinkedIn from "pages/LinkedIn";
import SourcingDrivePage from "pages/SourcingDrive/Drive";
import { checkAppAccess } from "helpers/utils/accessCheck";
import FTP from "pages/FTP";
import TalentPage from "pages/Talents";
import TalentInfo from "pages/Talents/TalentInfo";
import {
  COMMUNITY,
  SOURCING_DRIVE,
  TALENT_REQUESTS,
  TEMPLATES,
} from "helpers/constants/tabs";
import Templates from "pages/Templates";
import CreateEditTemplate from "pages/Templates/CreateEditTemplate";
import CreateEditDrive from "pages/SourcingDrive/CreateEditDrive";
import ApplicantResponse from "pages/Applicants/ApplicantResponse";
import DriveRequests from "pages/SourcingDrive/DriveRequests";
import CommunityCount from "pages/Community/Count";
import RoleWiseCommunity from "pages/Community/RoleWiseCommunity";
import Wishlist from "pages/Wishlist";
import MemberDetails from "components/Community/MemberDetails";
import Live from "pages/Live";
import Applicant from "pages/Applicants";
import Colleges from "pages/Colleges";
// Authenticated Paths

// Array of routes only a logged in user can access
const privateRoutes = [
  {
    path: "/home/live",
    component: Live,
  },
  {
    path: "/home/community/count",
    component: CommunityCount,
    name: COMMUNITY,
    tab: COMMUNITY,
  },
  {
    path: "/home/community/roleWise",
    component: RoleWiseCommunity,
    name: COMMUNITY,
    tab: COMMUNITY,
  },
  {
    path: "/home/community/:id",
    component: MemberDetails,
    name: "Member Details",
  },
  {
    path: "/home/community",
    component: Community,
    name: COMMUNITY,
    tab: COMMUNITY,
  },
  {
    path: "/home/ftp",
    component: CommunityCount,
  },
  {
    path: "/home/drives",
    component: SourcingDrive,
    name: SOURCING_DRIVE,
    tab: SOURCING_DRIVE,
  },
  {
    path: "/home/wishlist",
    component: Wishlist,
  },
  {
    path: "/drives/:id",
    component: SourcingDrivePage,
    name: "Sourcing Drive Details",
    tab: SOURCING_DRIVE,
  },
  {
    path: "/home/templates",
    component: Templates,
    name: TEMPLATES,
    tab: TEMPLATES,
  },
  {
    path: "/home/talents",
    component: TalentPage,
    name: TALENT_REQUESTS,
    tab: TALENT_REQUESTS,
  },
  {
    path: "/talents/:id",
    component: TalentInfo,
    name: TALENT_REQUESTS,
    tab: TALENT_REQUESTS,
  },
  {
    path: "/home/cgc",
    component: CGC,
  },
  {
    path: "/cgcManagement",
    component: Colleges,
  },
  {
    path: "/college/:id",
    component: College,
  },
  {
    path: "/home/linkedIn",
    component: LinkedIn,
  },

  {
    path: "/drive/requests",
    component: DriveRequests,
    tab: SOURCING_DRIVE,
    name: "",
  },
  {
    path: "/applicant/:id/response",
    component: ApplicantResponse,
    tab: SOURCING_DRIVE,
    name: "",
  },
  {
    path: "/applicant/:id",
    component: Applicant,
    tab: SOURCING_DRIVE,
    name: "",
  },
  {
    path: "/drive/:createEdit/:id",
    component: CreateEditDrive,
    tab: SOURCING_DRIVE,
    name: "",
  },
  {
    path: "/:type/:createEdit/:id",
    component: CreateEditTemplate,
    tab: SOURCING_DRIVE,
    name: "Template Builder",
  },

  {
    path: "/",
    exact: true,
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          window.innerWidth < 1024 ? (
            <Redirect to="/home/community" />
          ) : (
            <Redirect to="/home/community/count" />
          )
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <Redirect to="/auth/signIn" />
      ),
  },
];

export { privateRoutes };
