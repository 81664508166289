import { stargazeInstance } from "config";
import { getHeader } from "config";

export const getAllAlumni = (query = {}) =>
  stargazeInstance.get(
    `/alumni${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
