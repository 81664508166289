import React, { useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import CGCCard from "./CGCCard";
import { useSelector } from "react-redux";

const CGCList = ({ cgc, data, search }) => {
  const membersList = useSelector((state) => state?.college?.users);
  const [toggle, setToggle] = useState(true);

  return (
    <div className="flex px-4 flex-col flex-start gap-2">
      <div className="flex p-2 justify-between items-center border-b border-neutral-200">
        <div className="flex flex-row items-center space-x-4">
          {/* <img src={cgc?.image?.url} alt="" className="w-8 h-8 rounded-full" /> */}
          <h1 className="font-inter text-xs font-semibold text-primary-neutral-800">
            {cgc?.name}
          </h1>
        </div>
        <div
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          {toggle ? <MdExpandLess /> : <MdExpandMore />}
        </div>
      </div>

      {toggle && (
        <div className="px-2 lg:px-12 py-3 gap-6 grid md:grid-cols-2 lg:grid-cols-3">
          {data
            ?.sort((a, b) => {
              return (
                membersList?.filter(
                  (c) => c?.details?.college == b?.college_name
                )?.length -
                membersList?.filter(
                  (c) => c?.details?.college == a?.college_name
                )?.length
              );
            })
            ?.map((item) => {
              if (
                !search ||
                (search &&
                  search !== "" &&
                  item?.college_name?.toLowerCase()?.includes(search))
              )
                return <CGCCard cgc={cgc} data={item} crew={cgc?.crew} />;
            })}
        </div>
      )}
    </div>
  );
};

export default CGCList;
