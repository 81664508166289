import { getHeader } from "config";
import { stargazeInstance } from "config";
import { corePublicInstance } from "config";

export const findAllCollege = async (query = {}) => {
  return corePublicInstance.get(
    `/college${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`
  );
};

export const getCollegeById = async (id) =>
  corePublicInstance.get(`/college/${id}`);

export const updateCollege = async (id, body) =>
  corePublicInstance.patch(`/college/${id}`, body);

export const findAllCollegeApplicants = async (id, query = {}) => {
  return stargazeInstance.get(
    `/college/${id}/applicants${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const findAllUsers = async (query = {}) => {
  return corePublicInstance.get(
    `/user${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`
  );
};
