import PageHeader from "components/Layout/PageHeader";
import React from "react";
import LiveCard from "./LiveCard";
import EmptyState from "components/Comman/EmptyState";

const LiveMobileView = ({ list }) => {
  return (
    <>
      <PageHeader name="Live Stargazing Activity" />
      <div className="flex py-4 flex-col flex-start flex-[1_0_0]">
        {list?.length > 0 ? (
          list?.map((item) => {
            return <LiveCard item={item} />;
          })
        ) : (
          <EmptyState text="No updates found!" />
        )}
      </div>
    </>
  );
};

export default LiveMobileView;
