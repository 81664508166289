import { createSlice } from "@reduxjs/toolkit";
import { getAllFTPs } from "config/APIs/ftp";
import { logError } from "helpers/utils/common/logError";

const initialState = {
  list: [],
  roleWiseList: [],
};

// Storing members
export const ftp = createSlice({
  name: "ftp",
  initialState,
  reducers: {
    updateList: (state, action) => {
      let list = action.payload.list;
      let roles = [];
      let mem = [];
      list.map((item) => {
        let role = item?.appliedRole;
        if (roles.find((e) => e?.id == role?.id)) {
          mem.map((item1) => {
            if (item1?.id == role?.id) {
              item1.members.push(item);
            }
          });
        } else {
          let obj = { ...role };
          obj["members"] = [item];
          roles.push(role);
          mem.push(obj);
        }
      });

      state.rankWiseList = mem;
      state.list = list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = ftp.actions;

export default ftp.reducer;

export function fetchAllFTPs() {
  return async (dispatch) => {
    try {
      const response = await getAllFTPs();
      if (response.status === 200) {
        let data = response.data.data;

        dispatch(updateList({ list: data }));
      }
    } catch (err) {
      logError("Fetch all ftp: ", err);
    }
  };
}
