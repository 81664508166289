import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import { useDispatch } from "react-redux";
import {
  APPLICANT_STAGES,
  APPLICANT_STAGES_COLORS,
} from "helpers/constants/applicants";

/**
 * Displays the draggable tasks under a particular tasks status
 * @param {Object} prefix - index of status (i.e 0 if "Not Started")
 * @param {Array} elements - Array containing tasks details
 * @param {Function} setConfirmDeleteModal Function to set the boolean value on deleting the task card
 * @param {Function} setToDelete - Function to set item details to delete
 * @param {Object} permissions
 * @param {Function} onUpdate
 * @param {Function} onEdit
 * @returns
 */

const DraggableElement = ({ prefix, elements, onUpdate, roleId }) => {
  let dispatch = useDispatch();
  // const [addNew, setAddNew] = useState(false);
  // const [creating, setCreating] = useState(false);
  // const [newTask, setNewTask] = useState({
  //   title: "",
  //   status: prefix,
  //   dueDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
  // });

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     setAddNew(false);

  //     setNewTask({
  //       title: "",
  //       status: prefix,
  //       dueDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
  //     });
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [elements]);

  // const createNewTask = async (e) => {
  //   setNewTask({ ...newTask, title: e.target.value });
  //   if (creating) {
  //     return;
  //   }
  //   if (e.key === "Enter" || e.type === "blur") {
  //     if (newTask.title == "") {
  //       return;
  //     }
  //     setCreating(true);

  //     try {
  //       let body = {
  //         ...newTask,
  //       };

  //       const response = await API.createGoal(body);
  //       onUpdate();
  //       let { id } = getAdmin();
  //       let d = response?.data?.data;
  //       d["creatorId"] = id;
  //       dispatch(addAGoal({ goal: d }));
  //     } catch (err) {
  //       console.log("Error", err);
  //     }
  //     setCreating(false);
  //   }
  // };

  return (
    <Droppable droppableId={`${prefix}`}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ minHeight: "100px", minWidth: "380px" }}
        >
          <div className="">
            <div className="px-6 py-4 flex flex-row items-center justify-between">
              <div className="flex flex-row items-center space-x-4 font-lato">
                <div
                  className={`text-sm rounded-lg px-4 py-2 font-semibold ${APPLICANT_STAGES_COLORS[prefix]?.color} ${APPLICANT_STAGES_COLORS[prefix]?.backgroundColor}`}
                >
                  {prefix}
                </div>
                <p className="text-xs font-inter text-primary-gray-300">
                  {elements?.length || 0}
                </p>
              </div>
            </div>
            {/* {prefix == "NotStarted" && (
              <>
                {addNew ? (
                  <Card className="md:mx-1 mb-4 kanban-add-new-card mx-auto md:bg-white px-2 py-1 mt-2">
                    <div className="px-1 py-1">
                      <div>
                        <div className="flex flex-row items-center space-y-2">
                          <p className="font-lato text-2xl font-semibold tracking-tight text-primary-gray-600 inline-block align-middle w-11/12">
                            <input
                              autoFocus
                              placeholder="Add a Title"
                              onBlur={(e) => {
                                createNewTask(e);
                              }}
                              onKeyPress={(e) => {
                                createNewTask(e);
                              }}
                              value={newTask?.title}
                              name="Goal Title"
                              className="text-2xl font-semibold w-full focus:outline-none"
                              onChange={(e) => {
                                setNewTask({
                                  ...newTask,
                                  title: e.target.value,
                                });
                              }}
                            ></input>
                          </p>
                          {creating && (
                            <div className="w-5 h-5 border border-gray-900 border-t-0 animate-spin rounded-full" />
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                ) : (
                  <button
                    onClick={() => setAddNew(true)}
                    className="px-4 py-2.5 text-primary-gray-600 font-normal text-sm cursor-default transform transition ease-in-out duration-150 hover:scale-105"
                  >
                    + New
                  </button>
                )}
              </>
            )} */}
            {elements?.length > 0 &&
              elements?.map((ele, index) => {
                return (
                  <>
                    <ListItem
                      roleId={roleId}
                      key={ele.id}
                      item={ele}
                      index={index}
                      onUpdate={onUpdate}
                    />
                    {provided.placeholder}
                  </>
                );
              })}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default DraggableElement;
