import { Plus } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import EmptyState from "components/Comman/EmptyState";
import React from "react";

const UserCard = ({ user, addUser }) => {
  return (
    <div className="flex-[1_0_0%] items-start min-w-[280px] flex-col p-6 space-y-4 shadow rounded-2xl border border-primary-gray-150">
      <div className="flex py-2 items-start gap-4">
        <img src={user?.image?.url} className="rounded-full w-14 h-14" />
        <div className="flex flex-col items-start gap-2">
          <p className="text-lg font-semibold">{`${user?.firstName} ${user?.lastName}`}</p>
          <p className="text-md font-light">{user?.appliedRole?.role_name}</p>
        </div>
      </div>
      <div className="flex px-2 flex-col itmes-start gap-2 items-stretch">
        <p className="text-sm font-light self-stretch text-ellipsis">
          {user?.college?.college_name}
        </p>
        <p className="text-sm font-light self-stretch text-ellipsis">
          {user?.branch}
        </p>
      </div>
      <div className="flex flex-row items-center justify-center">
        <ChronosButton
          onClick={() => {
            addUser(user);
          }}
          tertiary
          icon={<Plus size={14} className="mr-2" />}
          reverseIcon
          text="Add To Shortlist"
        />
      </div>
    </div>
  );
};

const ShortlistTalent = ({ users, addUserHandler }) => {
  //Search Bar

  // Filter By
  //User Cards

  return (
    <>
      {users?.length > 0 ? (
        <div className="grid grid-cols-3 max-[1200px]:grid-cols-2 max-[670px]:grid-cols-1 content-start self-stretch items-start flex-wrap gap-6 px-2 h-[794px]">
          {users?.map((user) => {
            return <UserCard user={user} addUser={addUserHandler} />;
          })}{" "}
        </div>
      ) : (
        <EmptyState text="No Talent found for the talent request!" />
      )}
    </>
  );
};

export default ShortlistTalent;
