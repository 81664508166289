import { corePublicInstance } from "config";

export const findAllCrew = async (query = {}) => {
  return corePublicInstance.get(
    `/crew${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`
  );
};
export const updateCrew = async (id, body) =>
  corePublicInstance.patch(`/crew/${id}`, body);
