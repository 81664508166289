import { stargazeInstance, getHeader } from "..";

/**
 * To get token after number verification
 */
export const loginWithFirebaseAuthToken = (token) => {
  return stargazeInstance.post(`/auth/mobile/token`, { token });
};

/**
 * To verify if Phone Number exists in DB and is a Chronos user (admin)
 */
export const verifyPhoneNumberForOTP = (phone_number) => {
  return stargazeInstance.post(`/auth/mobile/verify`, { phone_number });
};

export const refreshToken = () =>
  stargazeInstance.get("/auth/token", getHeader());
