import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import * as TalentRequestAPI from "config/APIs/talent";
import { showToast } from "redux/toaster";
import ChronosButton from "components/Comman/Buttons";
import {
  USER_TYPE_CUSTOMER_FIC,
  USER_TYPE_CUSTOMER_FWS,
} from "helpers/constants/users";
import { fetchAllAccess } from "redux/dropdown";
import { Minus, Plus } from "@phosphor-icons/react";
import RadioInput from "components/Comman/Inputs/RadioInput";
import DropdownInputForObject from "components/Comman/Inputs/DropdownInputForObject";
import DateInput from "components/Comman/Inputs/DateInput";

const defaultValue = {
  title: "",
  description: "",
  openings: 1,
  roleId: "",
};

function TalentRequestCreateEdit({
  closeModal,
  isOpen,
  onCreate,
  onUpdate,
  editValues,
  project,
}) {
  const dispatch = useDispatch();
  const dropdownItems = useSelector((state) => state.dropdown);
  const [data, setData] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isOpen && editValues) {
      setData(editValues);
    }
    return () => {
      isMounted = false;
    };
  }, [isOpen, editValues]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isOpen) {
      if (dropdownItems?.roles?.length == 0) {
        dispatch(fetchAllAccess({ type: "roles" }));
      }
      if (dropdownItems?.ranks?.length == 0) {
        dispatch(fetchAllAccess({ type: "ranks" }));
      }
      if (dropdownItems?.designations?.length == 0) {
        dispatch(fetchAllAccess({ type: "designations" }));
      }
    }
    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleCreate = async () => {
    if (data?.roleId == "" || !data?.roleId) {
      setFieldErrors({ role: { msg: "Please select a role!" } });
      return;
    }
    if (data?.skillLevel == "" || !data?.skillLevel) {
      setFieldErrors({ skillLevel: { msg: "Please select a skill level!" } });
      return;
    }

    setCreating(true);
    let designation = dropdownItems?.designations?.list?.find(
      (d) =>
        d?.role?.id == data?.roleId &&
        d?.rank?.rank_name
          ?.toLowerCase()
          ?.includes(data?.skillLevel?.toLowerCase())
    );
    try {
      let body = {
        ...data,
        projectId: project?.id,
        designationId: designation?.id,
        name: designation?.designation_name,
      };

      const response = await TalentRequestAPI.createEntity(body);
      closeModal();
      onCreate();
      setData(defaultValue);

      showSuccessNotification("Request created successfully!");
    } catch (err) {
      console.log("Error", err);
      switch (err.response?.status) {
        case 422:
          let error = {},
            { data } = err.response.data;
          for (let key of Object.keys(data)) {
            if (key.split(".")[0] === "task")
              error[key.split(".")[1]] = data[key];
          }
          setFieldErrors(error);
          break;
        case 401:
          console.log(err?.response);
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification(err.response?.data?.message);
      }
    }
    setCreating(false);
  };

  const handleUpdate = async () => {
    if (data?.roleId == "" || !data?.roleId) {
      setFieldErrors({ role: { msg: "Please select a role!" } });
      return;
    }
    if (data?.skillLevel == "" || !data?.skillLevel) {
      setFieldErrors({ skillLevel: { msg: "Please select a skill level!" } });
      return;
    }

    setCreating(true);
    let designation = dropdownItems?.designations?.list?.find(
      (d) =>
        d?.role?.id == data?.roleId &&
        d?.rank?.rank_name
          ?.toLowerCase()
          ?.includes(data?.skillLevel?.toLowerCase())
    );
    try {
      let body = {
        ...data,
        projectId: project?.id,
        designationId: designation?.id,
        name: designation?.designation_name,
      };

      const response = await TalentRequestAPI.updateEntity(
        editValues?.id,
        body
      );
      closeModal();
      onUpdate();
      setData(defaultValue);

      showSuccessNotification("Request created successfully!");
    } catch (err) {
      console.log("Error", err);
      switch (err.response?.status) {
        case 422:
          let error = {},
            { data } = err.response.data;
          for (let key of Object.keys(data)) {
            if (key.split(".")[0] === "task")
              error[key.split(".")[1]] = data[key];
          }
          setFieldErrors(error);
          break;
        case 401:
          console.log(err?.response);
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification(err.response?.data?.message);
      }
    }
    setCreating(false);
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100%" : "560px",
        },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
        setData(defaultValue);
      }}
      transitionDuration={250}
    >
      <div className="bg-white md:max-w-xl lg:h-screen mx-auto w-full transform transition-all ease-in-out duration-150">
        <div className="flex flex-row items-end justify-between px-5 pt-4 rounded-t-20px">
          <h5
            className="flex flex-col items-start text-black font-karla text-xl font-medium"
            id="exampleModalLiveLabel"
          >
            {editValues
              ? `Update ${editValues?.title}`
              : `New Talent Request for`}
          </h5>

          <button
            aria-label="Close"
            type="button"
            onClick={() => {
              closeModal();
              setData(defaultValue);
            }}
          >
            <XIcon className="h-6 w-6 text-primary-gray-1000" />
          </button>
        </div>

        <div className="mt-8 flex w-full flex-col items-start px-5 space-y-8 h-auto transition-all ease-in-out duration-150">
          <RadioInput
            value={data?.purpose}
            setValue={(val) => {
              setData({ ...data, purpose: val });
            }}
            label="What is this talent request for?"
            list={[
              {
                label: "Internal Projects",
                value: "Internal Projects",
              },
              {
                label: "Fusion Work Study (FWS)",
                value: "Fusion Work Study (FWS)",
              },
              {
                label: "Fusion Industrial Contracts (FIC)",
                value: "Fusion Industrial Contracts (FIC)",
              },
            ]}
          />
          <RadioInput
            value={data?.userType}
            setValue={(val) => {
              setData({ ...data, userType: val });
            }}
            label="Contract Type"
            list={[
              {
                label: "Fusion Work Study (FWS)",
                value: USER_TYPE_CUSTOMER_FWS,
              },
              {
                label: "Fusion Industrial Contract (FIC)",
                value: USER_TYPE_CUSTOMER_FIC,
              },
            ]}
          />

          <RadioInput
            minItems={3}
            value={data?.skillLevel}
            setValue={(val) => {
              setData({ ...data, skillLevel: val });
            }}
            label="Skill level"
            list={
              data?.userType == USER_TYPE_CUSTOMER_FIC
                ? [
                    {
                      label: "P1",
                      value: "Level 1",
                    },
                    {
                      label: "P2",
                      value: "Level 2",
                    },
                  ]
                : [
                    {
                      label: "P3",
                      value: "Level 3",
                    },
                    {
                      label: "P4",
                      value: "Level 4",
                    },
                    {
                      label: "P5",
                      value: "Level 5",
                    },
                  ]
            }
            showError={fieldErrors?.skillLevel?.msg ? true : false}
            errorMessage={fieldErrors?.skillLevel?.msg}
          />

          <DropdownInputForObject
            label="Role"
            field="roleId"
            details={data}
            setDetails={setData}
            list={
              dropdownItems?.roles?.map((r) => ({
                label: r?.role_name,
                value: r?.id,
              })) || []
            }
            showError={fieldErrors?.role?.msg ? true : false}
            errorMessage={fieldErrors?.role?.msg}
          />

          <div className="w-full lg:w-1/2">
            <label className="input-label">No. of Openings</label>
            <div className="flex flex-row items-center space-x-2">
              <div
                onClick={() => {
                  setData({
                    ...data,
                    openings:
                      parseInt(data?.openings) !== 1
                        ? parseInt(data?.openings) - 1
                        : 1,
                  });
                }}
                className={`rounded p-1 cursor-pointer flex flex-row items-center justify-center bg-primary-green-lightest text-primary-green-dark`}
              >
                <Minus scale={12} />
              </div>
              <div className="border-b border-primary-gray-400 text-xl border-r-0 border-l-0 border-t-0 px-2.5 w-10">
                <input
                  defaultValue={1}
                  value={data?.openings || 1}
                  onChange={(e) => {
                    e.target.value = e.target.value
                      .trimStart()
                      .replace(/[^0-9]/gi, "");

                    setData({ ...data, openings: parseInt(e.target.value) });
                  }}
                  className={`w-full appearance-none border-0 text-primary-gray-1000 font-inter  leading-6 placeholder:text-primary-gray-300 bg-transparent focus:outline-none focus:ring-transparent`}
                ></input>
              </div>
              <div
                onClick={() => {
                  setData({
                    ...data,
                    openings: parseInt(data?.openings) + 1 || 1,
                  });
                }}
                className={`rounded p-1 cursor-pointer flex flex-row items-center justify-center bg-primary-green-lightest text-primary-green-dark`}
              >
                <Plus scale={12} />
              </div>
            </div>
          </div>
          <RadioInput
            minItems={3}
            value={data?.priority}
            setValue={(val) => {
              setData({ ...data, priority: val });
            }}
            label="Priority"
            list={[1, 2, 3, 4, 5, 6]?.map((p) => ({
              label: `🔥 P${p}`,
              value: p,
            }))}
          />
          <div className="w-full flex flex-col space-y-5 lg:space-y-0 lg:space-x-2.5 lg:flex-row items-stretch justify-between">
            <div className="w-full lg:w-1/2">
              <label className="input-label">Request Expiry</label>

              <DateInput
                date={data?.expiryDate}
                onChange={(value) => setData({ ...data, expiryDate: value })}
              />
            </div>
          </div>
        </div>
        <div className="mt-8 flex w-full flex-row items-center justify-between px-5 pb-5">
          <ChronosButton
            text="Cancel"
            secondary
            onClick={() => {
              closeModal();
              setData(defaultValue);
            }}
          />
          <ChronosButton
            loader={creating}
            text={editValues ? "Update Request" : "Add Request"}
            primary
            onClick={() => {
              editValues ? handleUpdate() : handleCreate();
            }}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default TalentRequestCreateEdit;
