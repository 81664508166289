import { User } from "@phosphor-icons/react";
import StatusDropdown from "components/Comman/Inputs/StatusDropdown";
import { updateApplicant } from "config/APIs/applicants";
import { APPLICANT_STAGES_STATUS } from "helpers/constants/applicants";
import moment from "moment";
import React, { useEffect, useState } from "react";
function ShortlistCard({ item, onUpdate }) {
  const [status, setStatus] = useState("");

  useEffect(() => {
    setStatus(item?.status);
  }, [item]);

  const updateStatus = async (val) => {
    // let mainBody = {
    //   status: val,
    // };
    // try {
    //   const response = await updateApplicant(item.id, mainBody);
    //   setStatus(val);
    //   onUpdate();
    // } catch (err) {
    //   console.log(err);
    // }
  };

  return (
    <div className="bg-white p-4 shadow space-y-4 rounded-2xl">
      <div className="flex flex-row items-center space-x-5">
        {item.image?.url ? (
          <img
            className="h-8 w-8 rounded-full object-cover"
            src={item.image?.url || "/assets/images/defaultUser.png"}
            alt=""
          />
        ) : (
          <div className="h-8 w-8 text-primary-green-700 rounded-full object-cover bg-primary-green-30 flex flex-row items-center justify-center">
            <User size={24} />
          </div>
        )}
        <div className="flex flex-col items-start space-y-0.5">
          <h1 className="text-primary-gray-800 font-lato text-sm font-semibold">
            {item?.firstName} {item?.lastName ? item?.lastName : ""}
          </h1>
          <p className="text-primary-gray-800 font-lato text-xs font-light">
            {item?.branch}, {item?.collegeYear}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-start space-y-1.5 text-primary-gray-400 font-lato text-xs font-light">
        <p className="">{item?.college?.college_name}</p>
        <p className="">{item?.email}</p>
        <p className="">{item?.phoneNumber}</p>
      </div>
      <div className="w-full">
        <StatusDropdown
          details={item}
          value={status}
          setValue={(val) => {
            updateStatus(val);
          }}
          list={APPLICANT_STAGES_STATUS[item?.stage]?.map((a) => ({
            label: a,
            value: a,
          }))}
          colorList={[
            "text-primary-orange-300",
            "text-primary-blue-400",
            "text-primary-green-300",
            "text-primary-yellow-700",
            "text-primary-red-darker",
          ]}
        />
      </div>
      <div className="flex flex-row items-center space-x-2.5 justify-end text-primary-gray-250 font-lato font-semibold">
        <p className="text-xs">
          Shortlisted On: {moment(item?.shortlisted?.date).format("ll")}
        </p>
      </div>
    </div>
  );
}

export default ShortlistCard;
