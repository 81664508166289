import PageHeader from "components/Layout/PageHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { showToast } from "redux/toaster";

import SearchBox from "components/Comman/Inputs/SearchBox";
import DrivesCard from "components/SourcingDrive/Drive/DrivesCard";
import Toggle from "components/Comman/Inputs/Toggle";

function SourcingDrive() {
  const college = useSelector((state) => state?.college?.list);
  const ftp = useSelector((state) => state?.ftp?.list);
  const cgc = useSelector((state) => state?.college?.cgc);
  const dispatch = useDispatch();
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  return (
    <>
      <PageHeader name="Drives" showMobile />
      <div className="lg:px-5 lg:py-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="p-4 lg:px-8 lg:py-2 lg:bg-white rounded-2xl gap-6">
          {window.innerWidth > 640 ? (
            <div className="relative max-w-xl flex flex-col lg:flex-row items-center justify-between space-y-4 lg:space-y-0 lg:space-x-4">
              <SearchBox
                placeholder="Find a college!"
                search={search}
                setSearch={setSearch}
              />
            </div>
          ) : (
            <>
              <div className="pb-2.5 flex flex-row items-center justify-between">
                <div className="flex flex-row items-center space-x-1">
                  <p className="font-inter text-sm font-semibold pr-2">
                    College
                  </p>{" "}
                  <Toggle enabled={active} setEnabled={setActive} />
                  <p className="font-inter text-sm font-semibold">CGCs</p>
                </div>
              </div>
              <div className="flex pb-4 items-center gap-4">
                <SearchBox
                  placeholder="Start typing to find..."
                  search={search}
                  setSearch={setSearch}
                />
                <img
                  src="/assets/svg/drives/RiListSettingsLine.svg"
                  alt=""
                  className="w-6 h-6"
                />
              </div>
            </>
          )}
          <div className="hidden lg:block mt-5" style={{ overflow: "scroll" }}>
            <table
              style={{ minWidth: "1056px" }}
              className="text-sm table-fixed font-lato"
            >
              <thead className="text-left border-b border-gray-200 bg-primary-green-30">
                <div className="p-4">
                  <tr className="grid grid-cols-7">
                    <th className="col-span-2">
                      <p className="px-4 py-2 mr-6 font-semibold text-primary-green-900">
                        Drive Name
                      </p>
                    </th>
                    {/* <th>
                      <p className="px-4 py-2 mr-6 font-semibold text-primary-green-900">
                        Role
                      </p>
                    </th> */}

                    <th className="text-center">
                      <p className="px-4 py-2 mr-6 font-semibold text-primary-green-900">
                        Applicants
                      </p>
                    </th>
                    <th className="text-center">
                      <p className="px-4 py-2 mr-6 font-semibold text-primary-green-900">
                        Applicants this week
                      </p>
                    </th>
                    <th className="col-span-2">
                      <p className="px-4 py-2 font-semibold text-primary-green-900">
                        Actions
                      </p>
                    </th>
                  </tr>
                </div>
              </thead>
              <tbody>
                {(!active ? college : cgc)?.map((drive) => {
                  if (drive?.college_name)
                    if (
                      search?.searchText == "" ||
                      (search?.searchText !== "" &&
                        drive?.college_name
                          ?.toLowerCase()
                          ?.includes(search?.searchText?.toLowerCase()))
                    )
                      if (ftp?.find((a) => a?.collegeId == drive?.id))
                        return (
                          <tr
                            onClick={() => {
                              !active && history.push(`/college/${drive?.id}`);
                            }}
                            className="cursor-pointer grid grid-cols-7 p-4 my-2 bg-gray-50 rounded-2xl font-lato"
                          >
                            <td className="col-span-2">
                              <p className="flex items-center h-full px-4 py-2 mr-6 font-semibold">
                                {drive?.college_name}
                              </p>
                            </td>
                            {/* <td>
                          <p className="flex items-center h-full px-4 py-2 mr-6 font-light text-gray-800">
                            {drive?.role?.role_name}
                          </p>
                        </td> */}

                            <td className="text-center">
                              <p className="flex items-center justify-center h-full px-4 py-2 mr-6 font-light text-gray-800">
                                {ftp?.filter((a) => a?.collegeId == drive?.id)
                                  ?.length ||
                                  drive?.applicantsLength ||
                                  0}
                              </p>
                            </td>
                            <td className="text-center">
                              <p className="flex items-center justify-center h-full px-4 py-2 mr-6 font-light text-gray-800">
                                {ftp?.filter((a) => a?.collegeId == drive?.id)
                                  ?.length ||
                                  drive?.applicantsLength ||
                                  0}
                              </p>
                            </td>
                            <td className="col-span-2">
                              <p className="flex items-center justify-between w-full h-full px-4 py-2">
                                {/* <div
                              onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(drive?.copyLink);
                                dispatch(
                                  showToast({
                                    message: "Link copied!",
                                    type: "success",
                                  })
                                );
                              }}
                              className="border-b border-primary-green-700 text-primary-green-700"
                            >
                              Copy Link
                            </div> */}
                                <div
                                  onClick={() => {
                                    !active &&
                                      history.push(`/college/${drive?.id}`);
                                  }}
                                  className="border-b border-primary-green-700 text-primary-green-700"
                                >
                                  View Applicants
                                </div>
                              </p>
                            </td>
                          </tr>
                        );
                })}
              </tbody>
            </table>
          </div>
          <div className="pt-3 pb-6 px-2 lg:px-6 lg:py-6 rounded-lg block lg:hidden bg-primary-gray-50">
            <div className="space-y-3 flex lg:hidden flex-col">
              {(!active ? college : cgc)?.map((drive) => {
                if (
                  search?.searchText == "" ||
                  (search?.searchText != "" &&
                    search?.searchText
                      ?.toLowerCase()
                      ?.includes(drive?.name?.toLowerCase()))
                )
                  if (ftp?.find((a) => a?.collegeId == drive?.id))
                    return (
                      <>
                        <DrivesCard
                          type={active ? "cgc" : ""}
                          drive={drive}
                          onClick={() => {
                            !active && history.push(`/college/${drive?.id}`);
                          }}
                        />
                      </>
                    );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SourcingDrive;
