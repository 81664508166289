export const questions = {
  "Neo-Generalism": [
    {
      id: 1,
      key: "assessment1",
      subtitle: "Adapting to dynamically changing responsibilities",
      question:
        "I am comfortable with frequently changing tasks outside my primary area of expertise.",
      description: "",
      type: "scale",
      scores: [0, 1, 2, 3, 4],
      left: "Not So Much",
      right: "Very Comfortable",
    },
    {
      id: 2,
      key: "assessment1",
      subtitle: "Preferred learning approach",
      question:
        "When faced with the need to learn a new skill or skills, I prefer learning it by myself with the wealth of online information.",
      description: "",
      type: "scale",
      scores: [0, 1, 2, 3, 4],
      left: "Not Often",
      right: "Often",
    },
    {
      id: 3,
      key: "assessment1",
      subtitle: "Problem solving in teams",
      question:
        "When stuck on a problem encountered while working on a task, I don't rely on others to come up with solutions.",
      description: "",
      type: "scale",
      scores: [4, 3, 2, 1, 0],
      left: "Not Often",
      right: "Often",
    },
    {
      id: 4,
      key: "assessment1",
      subtitle: "Specialist or Generalist",
      question:
        "I am the kind of person interested in performing multiple roles beyond what my job description states.",
      description: "",
      type: "scale",
      scores: [4, 3, 2, 1, 0],
      left: "Not So Much",
      right: "Very Interested",
    },
    {
      id: 5,
      key: "assessment1",
      subtitle: "Love for tools and technology",
      question:
        " I am comfortable with learning new digital tools which I haven't used before.",
      description: "",
      type: "scale",
      scores: [0, 1, 2, 3, 4],
      left: "Not So Much",
      right: "Very Comfortable",
    },
    {
      id: 6,
      key: "assessment1",
      subtitle: "Coping with failures",
      question:
        "I am the kind of person who responds positively to setbacks or failures in work",
      description: "",
      type: "scale",
      scores: [4, 3, 2, 1, 0],
      left: "Not Often",
      right: "Often",
    },
    {
      id: 7,
      key: "assessment1",
      subtitle: "Lone wolf or Hunting in packs",
      question:
        "I prefer working with team members from similar region/locality.",
      description: "",
      type: "scale",
      scores: [4, 3, 2, 1, 0],
      left: "Not Often",
      right: "Highly Preferred",
    },
    {
      id: 8,
      key: "assessment1",
      subtitle: "Approach to Deliverables",
      question:
        "I focus more on completing goals assigned to me, than wasting time figuring out how they would contribute to the overall goals of the team or organization.",
      description: "",
      type: "scale",
      scores: [4, 3, 2, 1, 0],
      left: "Not Often",
      right: "Often",
    },
  ],
  Agile: [
    {
      id: 1,
      key: "assessment2",
      subtitle: "Communicating at work",
      question:
        "How comfortable are you with expressing your ideas clearly and concisely in written communication & verbal?",
      description: "",
      type: "scale",
      scores: [0, 1, 2, 3, 4],
      left: "Not So Much",
      right: "Very Comfortable",
    },
    {
      id: 2,
      key: "assessment2",
      subtitle: "Values at work ",
      question:
        "How comfortable are you working with people who uphold good ethical values?",
      description: "",
      type: "scale",
      scores: [4, 3, 2, 1, 0],
      left: "Not So Much",
      right: "Very Comfortable",
    },
    {
      id: 3,
      key: "assessment2",
      subtitle: "Remote work prodigy",
      question:
        "How comfortable do you consider yourself in managing your time and meeting deadlines when working remotely?",
      description: "",
      type: "scale",
      scores: [0, 1, 2, 3, 4],
      left: "Not So Much",
      right: "Very Comfortable",
    },
    {
      id: 4,
      key: "assessment2",
      subtitle: "Time management at work",
      question:
        "Do you prefer putting in hours of work, rather than focusing only on achieving deliveries?",
      description: "",
      type: "scale",
      scores: [4, 3, 2, 1, 0],
      left: "Not Often",
      right: "Often",
    },
    {
      id: 5,
      key: "assessment2",
      subtitle: "Ownership at work ",
      question:
        "How comfortable are you in making decisions independently when faced with changes in your assigned goals/tasks?",
      description: "",
      type: "scale",
      scores: [0, 1, 2, 3, 4],
      left: "Not So Much",
      right: "Very Comfortable",
    },
    {
      id: 6,
      key: "assessment2",
      subtitle: "Team collaboration at work ",
      question:
        "How comfortable are you at collaborating with colleagues through digital channels despite being physically apart?",
      description: "",
      type: "scale",
      scores: [0, 1, 2, 3, 4],
      left: "Not So Much",
      right: "Very Comfortable",
    },
    {
      id: 7,
      key: "assessment2",
      subtitle: "Innovation at work",
      question:
        "Do you prefer proactively taking initiative to propose new ideas, improvements, or solutions to the goals of the company?",
      description: "",
      type: "scale",
      scores: [4, 3, 2, 1, 0],
      left: "Not Often",
      right: "Often",
    },
  ],
  Interests: [
    {
      id: 1,
      key: "brandingMarketingRoles",
      questionComponent: (
        <>
          Would you like to contribute to a startup's{" "}
          <span className="font-semibold">branding and marketing</span> needs?
        </>
      ),
      description: "If yes, in what roles?",
      type: "choices",
      options: [
        "Not Interested",
        "Graphic Designer",
        "Brand Strategist",
        "Illustrator",
        "Copywriter",
      ],
    },
    {
      id: 2,
      key: "digitalTransform",
      questionComponent: (
        <>
          Would you like to contribute to a startup's{" "}
          <span className="font-semibold">{`digital transformation `}</span>
          needs?
        </>
      ),
      description: "If yes, in what roles?",
      type: "choices",
      options: [
        "Not Interested",
        "Project Manager",
        "Frontend Developer",
        "Backend Developer",
        "Fullstack Developer",
        "UI/UX Designer",
        "UI/UX Researcher",
        "Product Manager",
        "Product Strategist",
      ],
    },
    {
      id: 3,
      key: "brandingMarketingRoles",
      questionComponent: (
        <>
          Would you like to contribute to a startup's{" "}
          <span className="font-semibold">branding and marketing</span> needs?
        </>
      ),
      description: "If yes, in what roles?",
      type: "choices",
      options: [
        "Not Interested",
        "Content Marketing Strategist",
        "Marketing Manager",
        "Graphic Designer",
        "Video Editor",
        "Social Media Manager",
      ],
    },
    {
      id: 4,
      key: "businessDev",
      questionComponent: (
        <>
          Would you like to contribute to a startup's{" "}
          <span className="font-semibold">{`business development `}</span>
          needs?
        </>
      ),
      description: "If yes, in what roles?",
      type: "choices",
      options: [
        "Not Interested",
        "Startup Market Researcher",
        "Sales Manager",
        "Business Analyst",
        "Customer Relations Manager",
      ],
    },
    {
      id: 5,
      key: "talentDev",
      questionComponent: (
        <>
          Would you like to contribute to a startup's{" "}
          <span className="font-semibold">talent development management</span>{" "}
          needs?
        </>
      ),
      description: "If yes, in what roles?",
      type: "choices",
      options: [
        "Not Interested",
        "Talent Recruiter",
        "Talent Manager",
        "Events Manager",
        "Operations Manager",
        "Operations Strategist",
      ],
    },
    {
      id: 6,
      key: "industries",
      likeOption: true,
      subtitle: "Industries you want to explore ",
      question: "What industries are you interested in exploring?",
      description: "",
      type: "choices",
      options: [
        "Event Management",
        "E-Sports",
        "Advertising and Film",
        "Fashion",
        "Retail",
        "FnB",
        "News and Media",
        "Healthcare",
      ],
    },

    {
      id: 7,
      key: "transferrableSkills",
      likeOption: true,
      subtitle: "Transferable skills you want to learn",
      question:
        "What are the top 5 transferable skills you would prefer to continue upskilling in?",
      description: "",
      type: "choices",
      limit: 5,
      options: [
        "Giving Feedback",
        "Business Strategy",
        "Research & Analysis",
        "Critical Thinking",
        "Personal Motivation",
        "Time Management",
        "Personal Organization",
        "Innovation",
        "Commercial Awareness",
        "Creativity",
        "Multi-tasking",
        "Personal Development",
        "Leadership Skills",
        "Effective Listening",
        "Problem solving",
        "Intentional Learning",
        "Numeracy Skills",
        "IT/Design Skills",
        "Team-work",
        "Written Communication",
        "Verbal Communication",
        "Attention to Detail",
      ],
    },
  ],
  Preferences: [
    {
      id: 1,
      key: "workTimings",
      subtitle: "Work timings ",
      question: "How would you describe your work timings?",
      description: "",
      type: "choice",
      options: [
        "Fixed work timings",
        "Flexible work timings",
        "Almost always available",
        "Not sure currently",
      ],
    },
    {
      id: 2,
      key: "workHours",
      subtitle: "Workload management",
      question: "How many hours would you like to work per week?",
      description: "",
      type: "choice",
      options: ["<10 hrs", "10-15 hrs", "20 hrs", "20+ hrs"],
    },
    {
      id: 3,
      key: "weekendWork",
      subtitle: "Weekend work",
      question: "Are you open to work on the weekends?",
      description: "",
      type: "choice",
      options: ["Sometimes", "Definitely", "Prefer not to"],
    },
    {
      id: 4,
      key: "workExperience",
      subtitle: "Work experience ",
      question:
        "How much prior work experience would you consider yourself to have?",
      description: "",
      type: "choice",
      options: ["Fresher", "0-1 yr", "1-2 yrs", "2-3 yrs", "3+ yrs"],
    },
    {
      id: 5,
      key: "priorExperience",
      subtitle: "Current skill set",
      question: "What roles do you previously have experience in?",
      description: "",
      type: "choices",
      options: [
        "Business Strategist",
        "Market Research Analyst",
        "Project Manager",
        "Brand Strategist",
        "Graphic Designer",
        "Copywriter",
        "UX Researcher",
        "Product Strategist",
        "Product Manager",
        "UX Designer",
        "UI Designer",
        "Technical Writer",
        "Frontend Developer",
        "Backend Developer",
        "Fullstack Developer",
        "Quality Assurance Engineer",
        "DevOps Engineer",
        "Content Strategist",
        "Content Writer",
        "Other",
      ],
    },
  ],
};
