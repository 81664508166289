import { createSlice } from "@reduxjs/toolkit";
import { getAllAlumni } from "config/APIs/alumni";

const initialState = {
  list: [],
};

// Storing members
export const alumni = createSlice({
  name: "alumni",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload.list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = alumni.actions;

export default alumni.reducer;

export function fetchAllAlumni() {
  return async (dispatch) => {
    try {
      const response = await getAllAlumni();
      if (response.status === 200) {
        let data = response.data.data;
        // Dispatching roles data to redux-store
        dispatch(
          updateList({
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("SD fetching error", err);
    }
  };
}
