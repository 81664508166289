import { ChevronRightIcon } from "@heroicons/react/outline";
import { Briefcase, GraduationCap, Star, User } from "@phosphor-icons/react";
import React from "react";

function MemberCard({ item, onSelect }) {
  return (
    <div className="relative p-4 rounded-lg bg-white border border-primary-gray-100 shadow-md">
      <div className="flex flex-row items-start space-x-4">
        {item.image?.url ? (
          <img
            onClick={() => {
              if (onSelect) onSelect();
            }}
            className="h-12 w-12 rounded-full object-cover"
            src={item.image?.url || "/assets/images/defaultUser.png"}
            alt=""
          />
        ) : (
          <div
            onClick={() => {
              if (onSelect) onSelect();
            }}
            className="h-12 w-12 text-primary-green-700 rounded-full object-cover bg-primary-green-30 flex flex-row items-center justify-center"
          >
            <User size={40} />
          </div>
        )}
        <div className="flex flex-col items-start space-y-2">
          <div className="flex flex-col items-start">
            <h1 className="text-primary-gray-400 text-xs font-semibold font-lato">
              {item?.firstName || item?.first_name}{" "}
              {item?.lastName
                ? item?.lastName
                : item?.last_name
                ? item?.last_name
                : ""}
            </h1>
            <p className="mt-1 text-primary-gray-250 font-lato text-2xs font-light flex flex-row items-center space-x-2">
              <GraduationCap size={10} /> <p>{item?.college?.college_name}</p>
            </p>
          </div>
          <div className="flex flex-col items-start">
            {item?.designation && (
              <p className="mt-1 text-primary-gray-250 font-lato text-2xs font-light flex flex-row items-center space-x-2">
                <Star size={10} /> <p>{item?.designation?.designation_name}</p>
              </p>
            )}
            {item?.appliedRole && (
              <p className="mt-1 text-primary-gray-250 font-lato text-2xs font-light flex flex-row items-center space-x-2">
                <Briefcase size={10} />{" "}
                <p>
                  {" "}
                  {item?.appliedRole?.role_name ||
                    item?.designation?.role?.role_name}
                </p>
              </p>
            )}
          </div>
        </div>
      </div>
      {/* <ChevronRightIcon className="w-4 h-4 text-primary-green-700 absolute top-4 right-4" /> */}
    </div>
  );
}

export default MemberCard;
