import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import { BiChevronUp } from "react-icons/bi";

function OptionalResponses({ driveSection, response }) {
  const getAnswers = (key, question) => {
    let section = response[key];
    let value = section?.find((s) => s?.id == question?.id)?.value;

    return value;
  };

  const getScores = (key, question) => {
    let list = getAnswers(key, question);

    let sum = 0;

    list?.map((i) => {
      sum += parseInt(i?.score);
    });
    return sum;
  };

  const getAnsComponent = (key, question) => {
    let ans = getAnswers(key, question);

    switch (ans?.type) {
      case "url":
        return (
          <a
            href={ans?.url}
            className="underline text-primary-green-700 cursor-pointer underline-offset-2"
          >
            {ans?.url || question?.id || question?.name}
          </a>
        );

      case "file":
        return (
          <a
            href={ans?.url}
            className="border rounded-lg border-primary-gray-200 bg-primary-gray-50 px-3 py-1 text-primary-gray-400 font-lato text-sm font-light"
          >
            {ans?.name || ans?.url || question?.id || question?.name}
          </a>
        );
      case "ratio":
        let keys = Object.keys(ans?.ratio);
        let arr = [];
        keys?.map((k) => {
          let str = k + ": " + ans?.ratio[k];
          arr.push(str);
        });

        return <div>{arr.join(",")}</div>;
      default:
        return <>{ans}</>;
    }
  };

  return (
    <div className="bg-white lg:px-6 py-2 px-0">
      <div className="bg-primary-gray-50 p-4 space-y-6 rounded-2xl">
        {Object.keys(driveSection)?.map((key) => {
          if (key !== "Personal Information")
            return (
              <>
                <div className="flex flex-col items-start w-full space-y-6">
                  <Disclosure className="bg-white rounded-2xl space-y-4 w-full">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="w-full flex flex-row items-center space-x-4 cursor-pointer px-4 md:px-0 justify-between">
                          <div className="flex flex-row items-center space-x-4 cursor-pointer px-4 md:px-0">
                            <div className="h-0.5 w-6 bg-primary-green-lightest" />
                            <label
                              htmlFor={key}
                              className="font-karla text-base text-primary-gray-800 font-medium"
                            >
                              {key}
                            </label>
                            <div className="font-lato text-sm text-primary-gray-250 font-light">
                              ({driveSection[key]?.length || 0} Questions)
                            </div>
                          </div>
                          <div className="flex items-center justify-between">
                            <p className="text-base font-light text-primary-green-700 font-lato">
                              (8/10)
                            </p>
                            <BiChevronUp
                              className={
                                open
                                  ? "transform w-6 scale-125"
                                  : "-rotate-180 w-6 scale-125"
                              }
                            />
                          </div>
                        </Disclosure.Button>

                        <Transition
                          className="w-full"
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel className="w-full flex flex-col items-start space-y-4">
                            {driveSection[key]?.map((item, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="text-left w-full flex flex-row justify-start items-start space-x-6 bg-white rounded-2xl px-8 py-4 text-primary-gray-800 font-lato font-semibold text-sm leading-6"
                                >
                                  {item?.type == "attributes" ? (
                                    <div className="w-full">
                                      <div className="flex flex-col items-start space-y-5 w-full">
                                        <div className="flex flex-row items-center space-x-2.5 justify-between w-full">
                                          {item?.question &&
                                          item?.question !== "" ? (
                                            <h3 className="text-primary-gray-800 font-lato font-light text-base leading-6">
                                              {item?.question}
                                            </h3>
                                          ) : item?.name &&
                                            item?.name !== "" ? (
                                            <h3 className="text-primary-gray-800 font-lato font-light text-base leading-6">
                                              {item?.name}
                                            </h3>
                                          ) : (
                                            <h3 className="text-primary-gray-350 font-lato font-light text-base leading-6">
                                              {item?.placeholder}
                                            </h3>
                                          )}

                                          <p className="font-lato text-primary-green-700 text-sm font-semibold">
                                            ({getScores(key, item)})
                                          </p>
                                        </div>

                                        <div className="flex flex-row items-center flex-wrap text-xs -ml-2 -mt-2">
                                          {item?.list?.map(
                                            (option, idx) =>
                                              (
                                                <div key={idx} className="m-2">
                                                  <div
                                                    className={`py-2 px-6 rounded-full border text-xs font-lato ${
                                                      getAnswers(key, item)
                                                        ?.map((a) => a?.id)
                                                        ?.includes(option?.id)
                                                        ? "bg-primary-green-30 border-primary-green-700 text-primary-green-700 font-semibold"
                                                        : "bg-primary-gray-50 border-primary-gray-200 text-primary-gray-400 font-light"
                                                    }`}
                                                  >
                                                    {option?.name}
                                                  </div>
                                                </div>
                                              ) || (
                                                <div>
                                                  <h6>No options</h6>
                                                </div>
                                              )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex flex-col items-start space-y-5 w-full">
                                      <h3 className="text-primary-gray-800 font-lato font-light text-base leading-6">
                                        {item?.name}
                                      </h3>
                                      <p className="">
                                        {getAnsComponent(key, item)}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </div>
              </>
            );
        })}
      </div>
    </div>
  );
}

export default OptionalResponses;
