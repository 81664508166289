import { Plus } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import SearchBox from "components/Comman/Inputs/SearchBox";
import PageHeader from "components/Layout/PageHeader";
import TalentRequestCard from "components/TalentRequest/Card/RequestCard";
import TalentRequestCreateEdit from "components/TalentRequest/Modal";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAllTalentRequest } from "redux/talent";

const TalentPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const list = useSelector((state) => state?.talent?.list);
  const [addNewModal, setAddNewModal] = useState(false);
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });
  return (
    <>
      <TalentRequestCreateEdit
        isOpen={addNewModal}
        closeModal={() => setAddNewModal(false)}
        onCreate={() => {
          dispatch(fetchAllTalentRequest());
        }}
      />
      <PageHeader
        name="Talent Requests"
        ctaComponent={
          <ChronosButton
            text="New Talent Request"
            icon={<Plus size={12} className="mr-2" />}
            reverseIcon
            primary
            onClick={() => {
              setAddNewModal(true);
            }}
          />
        }
      />
      <div className="lg:px-5 lg:pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="p-4 lg:p-6 space-y-6 lg:mt-2 bg-white lg:rounded-2xl">
          <div className="max-w-xl">
            <SearchBox
              placeholder="Find a request!"
              search={search}
              setSearch={setSearch}
            />
          </div>{" "}
          {window.innerWidth < 1024 && (
            <div className="px-4">
              <ChronosButton
                text="New Talent Request"
                icon={<Plus size={12} className="mr-2" />}
                reverseIcon
                primary
                onClick={() => {
                  setAddNewModal(true);
                }}
              />
            </div>
          )}
          <div className="py-3 px-2 lg:px-6 lg:py-6 rounded-lg bg-primary-gray-50">
            <div className="bg-white divide-y hidden lg:flex flex-col">
              {list?.map((t) => {
                if (
                  search?.searchText == "" ||
                  (search?.searchText != "" &&
                    search?.searchText
                      ?.toLowerCase()
                      ?.includes(t?.name?.toLowerCase()))
                )
                  return (
                    <>
                      <TalentRequestCard
                        item={t}
                        cta={() => {
                          history.push(`/talents/${t?.id}`);
                        }}
                      />
                    </>
                  );
              })}
            </div>
            <div className="space-y-3 flex lg:hidden flex-col">
              {list?.map((t) => {
                if (
                  search?.searchText == "" ||
                  (search?.searchText != "" &&
                    search?.searchText
                      ?.toLowerCase()
                      ?.includes(t?.name?.toLowerCase()))
                )
                  return (
                    <>
                      <TalentRequestCard
                        item={t}
                        cta={() => {
                          history.push(`/talents/${t?.id}`);
                        }}
                      />
                    </>
                  );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentPage;
