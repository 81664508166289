import PageHeader from "components/Layout/PageHeader";
import React, { useEffect, useState } from "react";

import userDummy from "../../assets/dummy 1.png";
import { FaUniversity } from "react-icons/fa";
import {
  MdOutlineMilitaryTech,
  MdOutlineWorkOutline,
  MdOutlineMail,
  MdCall,
} from "react-icons/md";
import { ImAttachment } from "react-icons/im";
import { GoLinkExternal } from "react-icons/go";
import { BsStars } from "react-icons/bs";
import { VscFlame } from "react-icons/vsc";
import { RiSwordFill, RiLinkedinBoxFill } from "react-icons/ri";
import { SiDiscord } from "react-icons/si";
import { Calendar } from "@phosphor-icons/react";

import { getFTP as UserFtpAPI } from "config/APIs/ftp";
import { getUser as UserAPI } from "config/APIs/community";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const MemberDetails = (props) => {
  const { id } = useParams();

  console.log(props);
  const [tab, setSelectedTab] = useState(props.location.state);
  const [user, setUser] = useState({});
  useEffect(() => {
    setSelectedTab(props.location.state);
  }, [props]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      getDetails();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  const getDetails = async () => {
    switch (tab) {
      case "ftp":
        try {
          const response = await UserFtpAPI(id);
          let data = response.data.data;
          console.log(data);
          setUser(data);
        } catch (err) {
          console.log(err);
        }
        break;
      default:
        try {
          const response = await UserAPI(id);
          let data = response.data.data;
          console.log(data);
          setUser(data);
        } catch (err) {
          console.log(err);
        }
    }
  };

  return (
    <>
      <PageHeader back name="Member Details" />
      <div className="lg:px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="bg-white rounded-2xl">
          <div className="flex flex-col flex-start self-stretch w-full border-b border-primary-gray-100 gap-6 px-6 py-6">
            <div className="flex py-2 flex-start gap-8 self-stretch">
              <div className="w-[120px] h-[120px]">
                <img
                  src={user?.imageId ? user.imageId : userDummy}
                  className="rounded-full"
                />
              </div>

              <div className="flex flex-col flex-start gap-4">
                <div className="flex place-items-center gap-4">
                  <h1 className="text-2xl font-semibold">
                    {user.firstName} {user.lastName}
                  </h1>

                  <div className="flex flex-start px-3 py-1 gap-2 bg-primary-green-30">
                    <p className="text-xs font-semibold text-primary-green-dark">
                      Future Talent Pool
                    </p>
                  </div>
                </div>

                <div className="flex px-4 flex-col flex-start gap-2">
                  <div className="flex items-center gap-4">
                    <FaUniversity size={16} />
                    <p className="text-sm font-light">
                      {user?.college
                        ? user.college.college_name
                        : "College Name"}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <MdOutlineMilitaryTech size={16} />
                    <p className="text-sm font-light">
                      {user?.rank ? user.rank : "Rank"}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <MdOutlineWorkOutline size={16} />
                    <p className="text-sm font-light">
                      {user?.role ? user.role : "Role"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="flex flex-start gap-6 self-stretch">
              <div className="flex flex-col flex-start gap-6 flex-[1_0_0]">
                <div className="flex py-2 items-center gap-4">
                  <p className="text-lg text-primary-green-dark font-medium">
                    Caarya Details
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <BsStars size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    Program:
                  </p>
                  <p className="text-sm font-light  text-primary-gray-800">
                    {user?.program ? user.program : "NA"}
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <VscFlame size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    CGC:
                  </p>
                  <p className="text-sm font-light  text-primary-gray-800">
                    {user?.college?.cgcId ? user.college.cgcId : "NA"}
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <FaUniversity size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    College:
                  </p>
                  <p className="text-sm font-light  text-primary-gray-800">
                    {user?.college?.college_name
                      ? user.college.college_name
                      : "College Name"}
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <RiSwordFill size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    Stream:
                  </p>
                  <p className="text-sm font-light  text-primary-gray-800">
                    {user?.branch ? user.branch : "College Name"}
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <Calendar size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    Member Since:
                  </p>
                  <p className="text-sm font-light  text-primary-gray-800">
                    {user?.program ? user.program : "NA"}
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="flex flex-col gap-6 flex-start flex-[1_0_0]">
                <div className="flex py-2 items-center gap-4">
                  <p className="text-lg text-primary-green-dark font-medium">
                    Personal Details
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <MdOutlineMail size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    Email:
                  </p>
                  <p className="text-sm font-light  text-primary-gray-800">
                    {user?.email ? user.email : "email@domain.com"}
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <MdCall size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    Mobile:
                  </p>
                  <p className="text-sm font-light  text-primary-gray-800">
                    {user?.phoneNumber ? user.phoneNumber : "00000000"}
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <ImAttachment size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    Attachments:
                  </p>
                  <p className="text-sm font-light truncate text-primary-gray-800">
                    {user?.details?.resumeUrl
                      ? user.details.resumeUrl
                      : "Upload Resume"}
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4 overflow:hidden">
                  <GoLinkExternal size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    Links:
                  </p>
                  <p className="text-sm font-light truncate text-primary-gray-800">
                    {user?.details?.portfolio
                      ? user.details.portfolio
                      : "Upload Portfolio"}
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <RiLinkedinBoxFill size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    Linkedin:
                  </p>
                  <p className="text-sm font-light  text-primary-gray-800">
                    {user?.details?.linkedin
                      ? user.details.linkedin
                      : "@linkedinprofile666"}
                  </p>
                </div>

                <div className="flex px-4 items-center gap-4">
                  <SiDiscord size={16} />
                  <p className="text-sm font-semibold text-primary-gray-800">
                    Discord:
                  </p>
                  <p className="text-sm font-light  text-primary-gray-800">
                    {user?.details?.discord
                      ? user.details.discord
                      : "@discordprofile666"}
                  </p>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="flex flex-col flex-start gap-6 items-stretch">
              <div className="flex py-2 gap-4 items-center">
                <p className="text-primary-green-dark font-medium text-lg">
                  Profounding Details
                </p>
              </div>

              <div className="flex px-4 items-center gap-4">
                <p className="text-primary-green-dark font-semibold underline underline-offset-4 text-sm">
                  Go to Sourcing Drive
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberDetails;
