import { useEffect } from "react";
import { useState } from "react";
import { Drawer } from "@mui/material/index";
import { useDispatch, useSelector } from "react-redux";
import { updateCrew } from "config/APIs/crew";
import { fetchAllCGC, fetchAllCrew } from "redux/college";
import SearchBox from "components/Comman/Inputs/SearchBox";
import ChronosButton from "components/Comman/Buttons";

function AddCGC({ isOpen, closeModal, selectedCgcs, selectedCrew }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState();
  const list = useSelector((state) => state?.college?.cgc);

  useEffect(() => {
    setSelected(selectedCgcs);
  }, [selectedCgcs]);

  const update = async () => {
    try {
      let res = await updateCrew(selectedCrew?.id, { cgcs: selected });

      if (res) {
        dispatch(fetchAllCGC("cgc"));
        dispatch(fetchAllCrew("crew"));
        closeModal();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={() => {
        closeModal();
      }}
      PaperProps={{
        style: {
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100%" : "560px",
          background: "transparent",
        },
      }}
    >
      <div className="bg-white lg:h-screen w-full lg:max-w-3xl flex flex-col space-y-6 p-6">
        <div className="flex flex-col space-y-2">
          <h1 className="text-black font-poppins text-xl font-medium">
            Add CGCs
          </h1>
          <h1 className="text-primary-neutral-800 font-inter text-sm font-light">
            Select the CGCs you want to add
          </h1>
        </div>

        <div>
          <SearchBox />
          {/* <ChronosButton
            tertiary
            text="+ New CGC"
            onClick={() => {}}
            underline
          /> */}
        </div>

        <div className="w-full flex flex-col max-h-60vh overflow-y-auto">
          {list?.map((item) => {
            return (
              <div
                onClick={() => {
                  let temp = [...selected];
                  if (temp?.includes(item?.id)) {
                    temp = temp?.filter((i) => i !== item?.id);
                  } else temp.push(item?.id);

                  setSelected(temp);
                }}
                className={`w-full py-4 px-6 flex flex-row items-center space-x-4 ${
                  !selected?.includes(item?.id)
                    ? "bg-white"
                    : "bg-primary-indigo-50"
                }`}
              >
                <input type="checkbox" checked={selected?.includes(item?.id)} />
                <p className="text-primary-neutral-800 font-inter font-normal text-base">
                  {item?.name}
                </p>
              </div>
            );
          })}
        </div>
        <div className="border-t py-4 flex flex-row items-center justify-center space-x-4">
          <ChronosButton
            text="Cancel"
            secondary
            onClick={() => {
              closeModal();
            }}
          />
          <ChronosButton
            text="Link"
            primary
            dark
            onClick={() => {
              update();
            }}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default AddCGC;
