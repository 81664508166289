import { ArrowRight, User } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import moment from "moment";
import React from "react";

function FWSCard({ item, cta }) {
  return (
    <div className="relative p-6 space-y-4 rounded-2xl bg-white border border-primary-gray-100 shadow">
      <div className="flex flex-row items-start space-x-4">
        {item.image?.url ? (
          <img
            className="h-14 w-14 rounded-full object-cover"
            src={item.image?.url || "/assets/images/defaultUser.png"}
            alt=""
          />
        ) : (
          <div className="h-14 w-14 text-primary-green-700 rounded-full object-cover bg-primary-green-30 flex flex-row items-center justify-center">
            <User size={44} />
          </div>
        )}
        <div className="flex flex-col items-start">
          <h1 className="text-primary-gray-800 text-lg font-semibold font-lato">
            {item?.first_name} {item?.last_name ? item?.last_name : ""}
          </h1>
          <p className="mt-1 text-primary-gray-400 font-lato text-sm font-light">
            <p>
              {item?.branch}, {item?.collegeYear}
            </p>
            <p>{item?.college?.college_name}</p>
          </p>
        </div>
      </div>{" "}
      <div className=" text-primary-gray-400 font-lato text-sm flex flex-row items-center space-x-2">
        <p className="font-semibold">Role Applied For:</p>
        <p className="font-light">{item?.designation?.role?.role_name}</p>
      </div>
      <div className=" text-primary-gray-400 font-lato text-sm flex flex-row items-center space-x-2">
        <p className="font-semibold">Cohort:</p>
        <p className="font-light">
          {item?.projects
            ?.filter((p) => !p?.title?.includes("iceBox"))
            ?.map((p) => p?.title)
            ?.join(", ")}
        </p>
      </div>
      <div className="flex flex-row items-center justify-between">
        <p className="text-primary-gray-400 font-lato text-xs font-light">
          <span className="font-semibold">Added On:</span>{" "}
          {moment(item?.createdAt).format("ll")}
        </p>
        <ChronosButton
          tertiary
          text="View Details"
          icon={<ArrowRight icon={10} className="ml-1.5" />}
          onClick={cta}
        />
      </div>
    </div>
  );
}

export default FWSCard;
