import WishlistCard from "components/Applicant/Card/WishlistCard";
import ChronosButton from "components/Comman/Buttons";
import PageHeader from "components/Layout/PageHeader";
import { Plus } from "@phosphor-icons/react";
import React, { useState } from "react";
import ClearAllIcon from "@mui/icons-material/ClearAll";

const Wishlist = () => {
  const NewUsers = [
    {
      image: {
        url: "https://s3-alpha-sig.figma.com/img/6f1f/6996/97fe0dd0dc272dfac81e74f59ca56b3f?Expires=1693180800&Signature=hTU2izEOukg3BYZbe9jBlvKw3TPClyf-gKr6S~KyXg6y8KkjrulNnkVGZaorXxNGTbCayGBGRPF6IB-Cd2SmksWV8wpbkPFGOpX2oemcgnH9tyxOT2hweesxC1cdZ61QOzFlVXFG7II7Tjc4JwxOfedw3SzOIvM-RmhxDiF17Vp-zqRsKSMRnzzm6JxAcPD9K7QClZX8ByJ51ePRwA1WHT9cg78HmLdTCl4KIcE-n~1pu11jlg4tyWsCeD0tE6zTCvm5bcBOxsW-~Eqd3vdTuZPk2cn9g~~bBRJh4BlQSsDt2DXE0m~urx7nVjFKCP7FKfjBHa8XvceTq4jVpQQHAA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      },
      firstName: "Dianne",
      lastName: "Russell",
      title: "Marketing Coordinator",
      branch: "Marketing Coordinator",
      collegeYear: "3rd",
      college: {
        college_name: "St. Stephens College",
      },
      level: "Level 5 Student professional",
      address: "Washington University in St. Louis",
      email: "emailaddress@domain.com",
      phoneNumber: "00000 00000",
      status: "Waiting for Response",
      mailDate: "DD-MM-YYYY",
    },
    {
      image: {
        url: "https://s3-alpha-sig.figma.com/img/6f1f/6996/97fe0dd0dc272dfac81e74f59ca56b3f?Expires=1693180800&Signature=hTU2izEOukg3BYZbe9jBlvKw3TPClyf-gKr6S~KyXg6y8KkjrulNnkVGZaorXxNGTbCayGBGRPF6IB-Cd2SmksWV8wpbkPFGOpX2oemcgnH9tyxOT2hweesxC1cdZ61QOzFlVXFG7II7Tjc4JwxOfedw3SzOIvM-RmhxDiF17Vp-zqRsKSMRnzzm6JxAcPD9K7QClZX8ByJ51ePRwA1WHT9cg78HmLdTCl4KIcE-n~1pu11jlg4tyWsCeD0tE6zTCvm5bcBOxsW-~Eqd3vdTuZPk2cn9g~~bBRJh4BlQSsDt2DXE0m~urx7nVjFKCP7FKfjBHa8XvceTq4jVpQQHAA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      },
      firstName: "Dianne",
      lastName: "Russell",
      title: "Marketing Coordinator",
      branch: "Marketing Coordinator",
      collegeYear: "3rd",
      college: {
        college_name: "St. Stephens College",
      },
      level: "Level 5 Student professional",
      address: "Washington University in St. Louis",
      email: "emailaddress@domain.com",
      phoneNumber: "00000 00000",
      status: "Waiting for Response",
      mailDate: "DD-MM-YYYY",
    },
    {
      image: {
        url: "https://s3-alpha-sig.figma.com/img/6f1f/6996/97fe0dd0dc272dfac81e74f59ca56b3f?Expires=1693180800&Signature=hTU2izEOukg3BYZbe9jBlvKw3TPClyf-gKr6S~KyXg6y8KkjrulNnkVGZaorXxNGTbCayGBGRPF6IB-Cd2SmksWV8wpbkPFGOpX2oemcgnH9tyxOT2hweesxC1cdZ61QOzFlVXFG7II7Tjc4JwxOfedw3SzOIvM-RmhxDiF17Vp-zqRsKSMRnzzm6JxAcPD9K7QClZX8ByJ51ePRwA1WHT9cg78HmLdTCl4KIcE-n~1pu11jlg4tyWsCeD0tE6zTCvm5bcBOxsW-~Eqd3vdTuZPk2cn9g~~bBRJh4BlQSsDt2DXE0m~urx7nVjFKCP7FKfjBHa8XvceTq4jVpQQHAA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      },
      firstName: "Dianne",
      lastName: "Russell",
      title: "Marketing Coordinator",
      branch: "Marketing Coordinator",
      collegeYear: "3rd",
      college: {
        college_name: "St. Stephens College",
      },
      level: "Level 5 Student professional",
      address: "Washington University in St. Louis",
      email: "emailaddress@domain.com",
      phoneNumber: "00000 00000",
      status: "Waiting for Response",
      mailDate: "DD-MM-YYYY",
    },
    {
      image: {
        url: "https://s3-alpha-sig.figma.com/img/6f1f/6996/97fe0dd0dc272dfac81e74f59ca56b3f?Expires=1693180800&Signature=hTU2izEOukg3BYZbe9jBlvKw3TPClyf-gKr6S~KyXg6y8KkjrulNnkVGZaorXxNGTbCayGBGRPF6IB-Cd2SmksWV8wpbkPFGOpX2oemcgnH9tyxOT2hweesxC1cdZ61QOzFlVXFG7II7Tjc4JwxOfedw3SzOIvM-RmhxDiF17Vp-zqRsKSMRnzzm6JxAcPD9K7QClZX8ByJ51ePRwA1WHT9cg78HmLdTCl4KIcE-n~1pu11jlg4tyWsCeD0tE6zTCvm5bcBOxsW-~Eqd3vdTuZPk2cn9g~~bBRJh4BlQSsDt2DXE0m~urx7nVjFKCP7FKfjBHa8XvceTq4jVpQQHAA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      },
      firstName: "Dianne",
      lastName: "Russell",
      title: "Marketing Coordinator",
      branch: "Marketing Coordinator",
      collegeYear: "3rd",
      college: {
        college_name: "St. Stephens College",
      },
      level: "Level 5 Student professional",
      address: "Washington University in St. Louis",
      email: "emailaddress@domain.com",
      phoneNumber: "00000 00000",
      status: "Waiting for Response",
      mailDate: "DD-MM-YYYY",
    },
    {
      image: {
        url: "https://s3-alpha-sig.figma.com/img/6f1f/6996/97fe0dd0dc272dfac81e74f59ca56b3f?Expires=1693180800&Signature=hTU2izEOukg3BYZbe9jBlvKw3TPClyf-gKr6S~KyXg6y8KkjrulNnkVGZaorXxNGTbCayGBGRPF6IB-Cd2SmksWV8wpbkPFGOpX2oemcgnH9tyxOT2hweesxC1cdZ61QOzFlVXFG7II7Tjc4JwxOfedw3SzOIvM-RmhxDiF17Vp-zqRsKSMRnzzm6JxAcPD9K7QClZX8ByJ51ePRwA1WHT9cg78HmLdTCl4KIcE-n~1pu11jlg4tyWsCeD0tE6zTCvm5bcBOxsW-~Eqd3vdTuZPk2cn9g~~bBRJh4BlQSsDt2DXE0m~urx7nVjFKCP7FKfjBHa8XvceTq4jVpQQHAA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      },
      firstName: "Dianne",
      lastName: "Russell",
      title: "Marketing Coordinator",
      branch: "Marketing Coordinator",
      collegeYear: "3rd",
      college: {
        college_name: "St. Stephens College",
      },
      level: "Level 5 Student professional",
      address: "Washington University in St. Louis",
      email: "emailaddress@domain.com",
      phoneNumber: "00000 00000",
      status: "Waiting for Response",
      mailDate: "DD-MM-YYYY",
    },
  ];

  const [addTalent, setAddTalent] = useState(false);
  const [users, setUsers] = useState([
    {
      image: {
        url: "https://s3-alpha-sig.figma.com/img/6f1f/6996/97fe0dd0dc272dfac81e74f59ca56b3f?Expires=1693180800&Signature=hTU2izEOukg3BYZbe9jBlvKw3TPClyf-gKr6S~KyXg6y8KkjrulNnkVGZaorXxNGTbCayGBGRPF6IB-Cd2SmksWV8wpbkPFGOpX2oemcgnH9tyxOT2hweesxC1cdZ61QOzFlVXFG7II7Tjc4JwxOfedw3SzOIvM-RmhxDiF17Vp-zqRsKSMRnzzm6JxAcPD9K7QClZX8ByJ51ePRwA1WHT9cg78HmLdTCl4KIcE-n~1pu11jlg4tyWsCeD0tE6zTCvm5bcBOxsW-~Eqd3vdTuZPk2cn9g~~bBRJh4BlQSsDt2DXE0m~urx7nVjFKCP7FKfjBHa8XvceTq4jVpQQHAA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      },
      firstName: "Dianne",
      lastName: "Russell",
      title: "Marketing Coordinator",
      branch: "Marketing Coordinator",
      collegeYear: "3rd",
      college: {
        college_name: "St. Stephens College",
      },
      level: "Level 5 Student professional",
      address: "Washington University in St. Louis",
      email: "emailaddress@domain.com",
      phoneNumber: "00000 00000",
      status: "Waiting for Response",
      mailDate: "DD-MM-YYYY",
    },
    {
      image: {
        url: "https://s3-alpha-sig.figma.com/img/6f1f/6996/97fe0dd0dc272dfac81e74f59ca56b3f?Expires=1693180800&Signature=hTU2izEOukg3BYZbe9jBlvKw3TPClyf-gKr6S~KyXg6y8KkjrulNnkVGZaorXxNGTbCayGBGRPF6IB-Cd2SmksWV8wpbkPFGOpX2oemcgnH9tyxOT2hweesxC1cdZ61QOzFlVXFG7II7Tjc4JwxOfedw3SzOIvM-RmhxDiF17Vp-zqRsKSMRnzzm6JxAcPD9K7QClZX8ByJ51ePRwA1WHT9cg78HmLdTCl4KIcE-n~1pu11jlg4tyWsCeD0tE6zTCvm5bcBOxsW-~Eqd3vdTuZPk2cn9g~~bBRJh4BlQSsDt2DXE0m~urx7nVjFKCP7FKfjBHa8XvceTq4jVpQQHAA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      },
      firstName: "Dianne",
      lastName: "Russell",
      title: "Marketing Coordinator",
      branch: "Marketing Coordinator",
      collegeYear: "3rd",
      college: {
        college_name: "St. Stephens College",
      },
      level: "Level 5 Student professional",
      address: "Washington University in St. Louis",
      email: "emailaddress@domain.com",
      phoneNumber: "00000 00000",
      status: "Waiting for Response",
      mailDate: "DD-MM-YYYY",
    },
  ]);

  const addUserHandler = (user) => {
    setUsers([...users, user]);
    setAddTalent(!setAddTalent);
  };

  return (
    <>
      <PageHeader name="Wishlist" />
      {/* Add Border */}
      {addTalent ? (
        <div className="flex flex-col gap-4 py-6 px-4">
          <div className="flex flex-col px-4 gap-6">
            <p className="text-xs ">
              Set the people you would like to have in your team
            </p>
            <div className="flex flex-col gap-6">
              <p className="text-base text-primary-gray-300">Filter by: </p>
              <div className="bg-primary-gray-150 border-b-2 border-primary-gray-200 p-2 rounded-lg font-light">
                <p className="text-primary-gray-250 font-light">
                  No Filter Added
                </p>
              </div>
            </div>
            <div className="flex flex-row-reverse">
              <div className="flex flex-row">
                <ClearAllIcon className="mr-4" />
                <p className="text-sm underline self-center text-primary-gray-600 font-semibold">
                  Clear All Filters
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 max-[1200px]:grid-cols-2 max-[670px]:grid-cols-1 content-start self-stretch items-start flex-wrap gap-6 px-2 h-[794px]">
            {NewUsers.map((user) => (
              <WishlistCard
                item={user}
                addUser={addUserHandler}
                selected={addTalent}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4 py-6 px-4">
          <div className="flex flex-row-reverse w-full px-4 gap-2">
            <ChronosButton
              text="+ Add Talent"
              reverseIcon
              primary
              onClick={() => {
                setAddTalent(true);
              }}
            />
          </div>
          <div className="grid grid-cols-3 max-[1200px]:grid-cols-2 max-[670px]:grid-cols-1 content-start self-stretch items-start flex-wrap gap-6 px-2 h-[794px]">
            {users.map((user) => (
              <WishlistCard item={user} selected={addTalent} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Wishlist;
