import { configureStore } from "@reduxjs/toolkit";

import navigationReducer from "./navigation";
import toasterReducer from "./toaster";
import userReducer from "./user";
import collegeReducer from "./college";
import communityReducer from "./community";
import sourcingDriveReducer from "./sourcingDrive";
import dropdown from "./dropdown";
import ftpReducer from "./ftp";
import talentReducer from "./talent";
import alumniReducer from "./alumni";

const store = configureStore({
  reducer: {
    sideNav: navigationReducer,
    toaster: toasterReducer,
    user: userReducer,
    college: collegeReducer,
    community: communityReducer,
    dropdown: dropdown,
    sourcingDrive: sourcingDriveReducer,
    ftp: ftpReducer,
    talent: talentReducer,
    alumni: alumniReducer,
  },
});

export default store;
