import LiveMobileView from "components/Live/LiveMobileView";
import { findAllChronicles } from "config/APIs/chronicles";
import React, { useEffect, useState } from "react";

function Live() {
  const [list, setList] = useState([]);

  useEffect(() => {
    getDriveData();
  }, []);

  const getDriveData = async () => {
    try {
      const res = await findAllChronicles();
      console.log(res.data);
      if (res?.data) {
        setList(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <LiveMobileView list={list} />
    </div>
  );
}

export default Live;
