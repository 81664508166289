import { useState } from "react";
import SearchBox from "components/Comman/Inputs/SearchBox";
import CommunityList from "components/Community/CommunityList";
import Toggle from "components/Comman/Inputs/Toggle";
import { RadioTabs } from "components/Comman/Tabs";
import {
  USER_TYPE_CGC_LEADER,
  USER_TYPE_CUSTOMER_FIC,
  USER_TYPE_CUSTOMER_FWS,
  USER_TYPE_USER,
} from "helpers/constants/users";

function CommunityMobileView() {
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });
  const [showing, setShowing] = useState("");
  const [active, setActive] = useState(true);
  const [userType, setUserType] = useState("");

  return (
    <>
      <div className="px-7.5 pt-2.5">
        <SearchBox
          placeholder="Find a member!"
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div className="flex flex-col items-stretch justify-between pt-2 px-7.5 transition-all">
        <RadioTabs
          selectedTab={userType}
          setSelectedTab={setUserType}
          tabs={[
            { label: "Customer FIC", value: USER_TYPE_CUSTOMER_FIC },
            { label: "CGC Leaders", value: USER_TYPE_CGC_LEADER },
            { label: "Customer FWS", value: USER_TYPE_CUSTOMER_FWS },
          ]}
        />
      </div>

      <div className="z-20 w-full py-2 pl-8 pr-8 flex flex-row items-center justify-between">
        <h1 className="text-xs font-lato font-normal w-full text-primary-gray-900 flex flex-row items-center">
          Showing <p className="font-bold ml-1">{showing}</p>
        </h1>
      </div>

      <div className="max-h-70vh overflow-x-hidden">
        <CommunityList
          userType={userType}
          active={active}
          search={search}
          setShowing={setShowing}
        />
      </div>
    </>
  );
}

export default CommunityMobileView;
