import { corePublicInstance } from "config";

export const findAllCGC = async (query = {}) => {
  return corePublicInstance.get(
    `/cgc${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`
  );
};
export const updateCGC = async (id, body) =>
  corePublicInstance.patch(`/cgc/${id}`, body);
