import React from "react";

function MandatoryResponse({ response }) {
  return (
    <div className="bg-white lg:px-6 py-2 px-0">
      <div className="bg-primary-gray-50 p-4 space-y-2 rounded-2xl">
        <QuestionAns
          question="First Name:"
          answer={`${response?.find((r) => r?.id == "Name")?.value?.firstName}`}
        />
        <QuestionAns
          question="Last Name:"
          answer={`${response?.find((r) => r?.id == "Name")?.value?.lastName}`}
        />
        <QuestionAns
          question="Gender:"
          answer={`${response?.find((r) => r?.id == "Gender")?.value}`}
        />
        <QuestionAns
          question="Email Id:"
          answer={`${response?.find((r) => r?.id == "Email")?.value}`}
        />
        <QuestionAns
          question="Mobile No.:"
          answer={`${response?.find((r) => r?.id == "PhoneNumber")?.value}`}
        />
        <QuestionAns
          question="College Name:"
          answer={`${
            response?.find((r) => r?.id == "College")?.value?.college_name
          }`}
        />
        <QuestionAns
          question="Year of Study:"
          answer={`${response?.find((r) => r?.id == "YearOfStudy")?.value}`}
        />
        <QuestionAns
          question="Branch:"
          answer={`${response?.find((r) => r?.id == "Stream")?.value}`}
        />
      </div>
    </div>
  );
}

export default MandatoryResponse;

function QuestionAns({ question = "", answer = "" }) {
  return (
    <div className="text-primary-gray-800 font-lato text-base bg-white px-6 py-4 space-x-4 flex flex-row items-center">
      <p className="font-light">{question}</p>
      <p className="font-semibold">{answer}</p>
    </div>
  );
}
