import React from "react";
import collegeSvg from "assets/svg/college.svg";
import CastleLogo from "assets/svg/CASTLE_LOGO.svg";

const CollegeCard = ({ title, memberName, year, image }) => {
  const optionToColor = {
    "Industrial Contracts": "border-primary-yellow-500",
    "Work Scholarship": "border-primary-blue-500",
    Observership: "border-primary-pink-100",
    "Leadership Accelerator Program": "border-primary-green-500",
  };

  const borderColor = optionToColor[title] || "blue-500";
  return (
    <div className="flex relative w-[240px] overflow-hidden h-[240px] flex-col justify-center items-center gap-5 rounded-[32px] border border-gray-150  college-card">
      <div>
        <div
          className={` border-l-[300px] border-l-transparent border-t-[100px] border-r-[300px] border-r-transparent ${borderColor} `}
        ></div>
        <img
          className="absolute top-5 right-5 "
          src={CastleLogo}
          alt="Castle Logo"
        />
        <img
          className="absolute right-[35%] top-[25%] w-20 h-20 rounded-full object-cover"
          src={image?.url || CastleLogo}
          alt="Castle Logo"
        />
      </div>
      <div className="font-lato text-center font-light m-auto mt-6">
        <div className="text-lg text-black">{memberName}</div>
        {year && <div className="text-sm">{year}</div>}
        <div className="flex gap-2 justify-center items-center mt-4">
          <img src={collegeSvg} alt="College Icon" />
          <img src={collegeSvg} alt="College Icon" />
          <img src={collegeSvg} alt="College Icon" />
        </div>
      </div>
    </div>
  );
};

export default CollegeCard;
