import React, { useState, useEffect } from "react";
import * as TalentAPI from "config/APIs/talent";
import { MdLocalFireDepartment } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Transition, Disclosure } from "@headlessui/react";
import moment from "moment";
import { BiChevronUp } from "react-icons/bi";
import {
  USER_TYPE_CUSTOMER_FIC,
  USER_TYPE_CUSTOMER_FWS,
} from "helpers/constants/users";
import CreateEditTemplate from "pages/Templates/CreateEditTemplate";
import PageHeader from "components/Layout/PageHeader";
import ChronosButton from "components/Comman/Buttons";

const CreateEditDrive = () => {
  const { createEdit: type } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [talentRequest, setTalentRequest] = useState({});

  const [create, setCreate] = React.useState(false);
  const [update, setUpdate] = React.useState(false);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && location?.state?.talentRequestId) {
      getTalentRequest(location?.state?.talentRequestId);
    }

    return () => {
      isMounted = false;
    };
  }, [location?.state]);

  const getTalentRequest = async (id) => {
    try {
      const response = await TalentAPI.getOneEntity(id);
      const request = response.data.data;
      setTalentRequest(request);
    } catch (err) {
      console.log("Fetch drive error", err);
    }
  };

  return (
    <>
      <PageHeader
        back
        name={"Create Sourcing Drive"}
        ctaComponent={
          <div className="flex flex-row items-center justify-end space-x-5">
            <ChronosButton
              text="Cancel"
              secondary
              onClick={() => {
                history.goBack();
              }}
            />
            <ChronosButton
              text={`${type == "create" ? "Create" : "Update"} Drive`}
              primary
              onClick={() => {
                type == "create" ? setCreate(true) : setUpdate(true);
              }}
            />
          </div>
        }
      />
      <div className="px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="space-y-6">
          <div className="px-8 py-4 bg-white rounded-2xl space-y-4 w-full">
            <Disclosure
              defaultOpen
              className="px-8 py-4 bg-white rounded-2xl space-y-4 w-full"
            >
              {({ open }) => (
                <>
                  <Disclosure.Button className="w-full flex flex-row items-center justify-between">
                    <div className="w-full flex items-center justify-between px-10 py-2 border-b border-gray-200">
                      <p className="text-lg font-semibold text-primary-green-dark font-lato">
                        Talent Request Details
                      </p>
                      <BiChevronUp
                        className={
                          open
                            ? "transform w-6 scale-125"
                            : "-rotate-180 w-6 scale-125"
                        }
                      />
                    </div>
                  </Disclosure.Button>

                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="w-full space-y-6 px-8 py-4 text-primary-gray-800 font-lato rounded-2xl">
                      <p className="flex items-center space-x-4">
                        <span className="text-sm font-semibold font-lato">
                          Talent Request Name:
                        </span>
                        <div className="flex items-center">
                          <span className="text-sm font-light text-gray-800">
                            {talentRequest?.name}
                          </span>
                        </div>
                      </p>
                      <div className="flex items-center justify-between">
                        <p className="flex items-center space-x-4">
                          <span className="text-sm font-semibold font-lato">
                            Role:
                          </span>
                          <span className="text-sm font-light text-gray-800">
                            {talentRequest?.designation?.role?.role_name}
                          </span>
                        </p>
                        <p className="flex items-center space-x-4">
                          <span className="text-sm font-semibold font-lato">
                            Priority:
                          </span>
                          <span className="flex items-center p-1 rounded bg-primary-red-100">
                            <span>
                              <MdLocalFireDepartment
                                className="text-primary-red-900"
                                size={16}
                              />
                            </span>
                            <span className="text-xs">
                              {talentRequest?.priority}
                            </span>
                          </span>
                        </p>
                      </div>
                      <p className="flex items-center space-x-4">
                        <span className="mr-4 text-sm font-semibold font-lato">
                          Contract Type:
                        </span>
                        <span className="text-sm font-light text-gray-800">
                          {talentRequest?.userType == USER_TYPE_CUSTOMER_FIC
                            ? "FIC"
                            : talentRequest?.userType == USER_TYPE_CUSTOMER_FWS
                            ? "FWS"
                            : "Core"}
                        </span>
                      </p>
                      <div className="flex items-center space-x-12">
                        <p className="flex items-center space-x-4">
                          <span className="text-sm font-semibold font-lato">
                            No. of Openings:
                          </span>
                          <span className="text-sm font-light text-gray-800">
                            {talentRequest?.openings}
                          </span>
                        </p>
                        <p className="flex items-center space-x-4">
                          <span className="text-sm font-semibold font-lato">
                            Skill Level:
                          </span>
                          <span className="text-sm font-light text-gray-800">
                            {talentRequest?.userType == USER_TYPE_CUSTOMER_FIC
                              ? "P1-P2"
                              : talentRequest?.userType ==
                                USER_TYPE_CUSTOMER_FWS
                              ? "P3-P5"
                              : "Core"}
                          </span>
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="flex items-center space-x-4">
                          <span className="text-sm font-semibold font-lato">
                            Created On:
                          </span>
                          <span className="text-sm font-light text-gray-800">
                            {moment(talentRequest?.createdAt).format("ll")}
                          </span>
                        </p>
                        <p className="flex items-center space-x-4">
                          <span className="text-sm font-semibold font-lato">
                            Expiring On:
                          </span>
                          <span className="text-sm font-semibold text-primary-red-500">
                            {moment(talentRequest?.expiryDate).format("ll")}
                          </span>
                        </p>
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </div>
          <p className="text-lg font-medium text-primary-green-700 font-karla">
            Create Sourcing Drive
          </p>

          <CreateEditTemplate
            isDrive
            talentRequest={talentRequest}
            create={create}
            update={update}
          />
        </div>
      </div>
    </>
  );
};

export default CreateEditDrive;
