import { BoxedTabs } from "components/Comman/Tabs";
import ApplicantCard from "components/Applicant/Card/ApplicantCard";
import { useEffect, useState } from "react";
import EmptyState from "components/Comman/EmptyState";

const tabs = [
  {
    label: "Not Reviewed",
    value: "notReviewed",
  },
  {
    label: "Reviewed",
    value: "reviewed",
  },
  {
    label: "All",
    value: "all",
  },
];
function ApplicantTable({ applicants }) {
  const [list, setList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("notReviewed");

  useEffect(() => {
    switch (selectedTab) {
      case "notReviewed":
        setList(applicants?.filter((a) => !a?.userSourcingDrive?.viewed));
        break;
      case "reviewed":
        setList(applicants?.filter((a) => a?.userSourcingDrive?.viewed));

        break;
      default:
        setList(applicants);
    }
  }, [selectedTab, applicants]);

  return (
    <div className="py-7 space-y-8 px-4 lg:px-8">
      <BoxedTabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <div className=" py-4 px-2 lg:p-4 bg-primary-gray-50 rounded-lg">
        <div className="space-y-3 lg:space-y-1">
          {list?.length > 0 ? (
            list?.map((t) => {
              return (
                <>
                  <ApplicantCard item={t} />
                </>
              );
            })
          ) : (
            <EmptyState
              text={`No applicants found ${
                selectedTab !== "all"
                  ? `in ${tabs?.find((t) => t?.value == selectedTab)?.label}`
                  : ""
              }`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ApplicantTable;
