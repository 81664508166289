import { useState } from "react";

import SearchBox from "components/Comman/Inputs/SearchBox";

function LikendIn() {
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  return (
    <>
      <div className="px-7.5 pt-2.5">
        <SearchBox
          placeholder="Start typing to find...."
          search={search}
          setSearch={setSearch}
        />
      </div>

      <div className="h-70vh overflow-y-hidden flex flex-row items-center justify-center">
        <img src="/assets/images/empty/logo.png" alt="" className="w-full" />
      </div>
    </>
  );
}

export default LikendIn;
