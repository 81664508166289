import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const CGCCard = ({ cgc, data, crew }) => {
  const membersList = useSelector((state) => state?.college?.users);
  const history = useHistory();

  return (
    <div
      style={{
        background: `url('/assets/images/demo.png')`,
        backgroundSize: "cover",
      }}
      className="flex flex-col flex-start gap-4 bg-white rounded-2xl cursor-pointer"
      onClick={() => history.push(`/college/${data.id}`)}
    >
      <div className="h-12" />
      <div className="p-4 flex flex-col space-y-2 text-white font-satoshi">
        <div className="flex flex-col space-y-2">
          <p className="text-xs font-normal uppercase leading-4 tracking-[3.6px]">
            The Future Club
          </p>
          <h1 className="text-[28px] font-bold uppercase leading-8 tracking-[4.2px]">
            {data?.college_name}
          </h1>
        </div>
        <div className="text-xs font-lato font-bold leading-4 tracking-[0.24px] flex flex-row items-center space-x-2">
          <p>
            {membersList?.filter(
              (a) => a?.details?.college == data?.college_name
            )?.length || 0}{" "}
            Members
          </p>
          <p>
            •
            {membersList?.filter(
              (a) => a?.details?.college == data?.college_name && a?.is_active
            )?.length || 0}{" "}
            Active
          </p>
        </div>
      </div>
    </div>
  );
};

export default CGCCard;
