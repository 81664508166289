import { createSlice } from "@reduxjs/toolkit";
import { findAllCGC } from "config/APIs/cgc";
import { findAllCollege, findAllUsers } from "config/APIs/college";
import { findAllCrew } from "config/APIs/crew";

const initialState = {
  list: [],
  cgc: [],
  crew: [],
  typeWiseList: {
    Live: [],
    Pipeline: [],
  },
  users: [],
};

// Storing colleges
export const college = createSlice({
  name: "college",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload.list;
    },
    updateUser: (state, action) => {
      state.users = action.payload.list;
    },
    updateCGC: (state, action) => {
      state.cgc = action.payload.cgc;
    },
    updateCrew: (state, action) => {
      state.crew = action.payload.crew;
    },
    updateTypeWise: (state, action) => {
      let list = action.payload.list;

      let types = ["Live", "Pipeline"];

      let temp = { Live: [], Pipeline: [] };

      list?.map((i) => {
        if (types?.includes(i?.type)) {
          temp[i.type].push(i);
        } else {
          types.push(i?.type);
          temp[i.type] = [i];
        }
      });
      state.typeWiseList = temp;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList, updateTypeWise, updateCGC, updateCrew, updateUser } =
  college.actions;

export default college.reducer;

export function fetchAllCollege() {
  return async (dispatch) => {
    try {
      const response = await findAllCollege({});
      if (response.status === 200) {
        const l = response.data.data?.response;

        // Dispatching roles data to redux-store
        dispatch(
          updateList({
            list: l,
          })
        );
      }
    } catch (err) {
      console.log("College fetching error", err);
    }
  };
}

export function fetchAllUsers() {
  return async (dispatch) => {
    try {
      const response = await findAllUsers({});
      if (response.status === 200) {
        const l = response.data.data;

        // Dispatching roles data to redux-store
        dispatch(
          updateUser({
            list: l,
          })
        );
      }
    } catch (err) {
      console.log("College fetching error", err);
    }
  };
}

export function fetchAllCGC() {
  return async (dispatch) => {
    try {
      const response = await findAllCGC();
      if (response.status === 200) {
        const l = response.data.data?.response;

        // Dispatching roles data to redux-store
        dispatch(
          updateCGC({
            cgc: l,
          })
        );
      }
    } catch (err) {
      console.log("College fetching error", err);
    }
  };
}

export function fetchAllCrew() {
  return async (dispatch) => {
    try {
      const response = await findAllCrew();
      if (response.status === 200) {
        const l = response.data.data?.response;

        // Dispatching roles data to redux-store
        dispatch(
          updateCrew({
            crew: l,
          })
        );
      }
    } catch (err) {
      console.log("College fetching error", err);
    }
  };
}
