import {
  Stack,
  User,
  Users,
  GraduationCap,
  LinkedinLogo,
  StackSimple,
} from "@phosphor-icons/react";
import { TbUserSearch } from "react-icons/tb";

const mobileBottomNav = [
  {
    name: "FTP",
    path: "/home/ftp",
    image: "/assets/svg/tabbar/ftp.svg",
    selectedImage: "/assets/svg/tabbar/selectedFtp.svg",
  },

  {
    name: "Tribe",
    path: "/home/community",

    image: "/assets/svg/tabbar/requests.svg",
    selectedImage: "/assets/svg/tabbar/selectedRequests.svg",
  },
  {
    name: "Live",
    path: "/home/live",
    image: "/assets/caaryaLogos/logo.png",
    selectedImage: "/assets/caaryaLogos/logo.png",
  },
  {
    name: "Drives",
    path: "/home/drives",

    image: "/assets/svg/tabbar/drives.svg",
    selectedImage: "/assets/svg/tabbar/selectedDrives.svg",
  },

  {
    name: "TFC",
    path: "/home/cgc",

    image: "/assets/svg/tabbar/cgc.svg",
    selectedImage: "/assets/svg/tabbar/selectedCgc.svg",
  },
];
const laptopNav = [
  {
    name: "Community",
    path: "/home/community/count",
    icon: <Users size={25} />,
  },
  {
    name: "Sourcing Drives",
    path: "/home/drives",
    icon: <Stack size={25} />,
  },
  {
    name: "Talent Requests",
    path: "/home/talents",
    icon: <TbUserSearch size={25} />,
  },

  // {
  //   name: "Profiling Templates",
  //   path: "/home/templates",
  //   icon: <StackSimple size={25} />,
  // },
  {
    name: "The Future Club",
    path: "/home/cgc",
    icon: <TbUserSearch size={25} />,
  },
  {
    name: "CGC Management",
    path: "/cgcManagement",
    icon: <TbUserSearch size={25} />,
  },
];

export { mobileBottomNav, laptopNav };
