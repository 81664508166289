import { TrashIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router";

import DateInputForObject from "components/Comman/Inputs/DateInputForObject";
import DropdownInput from "components/Comman/Inputs/DropdownInput";
import SimpleInput from "components/Comman/Inputs/SimpleInput";
import SimpleRadioBoxesForObject from "components/Comman/Inputs/SimpleRadioBoxeForObject";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import WideModalsWrapper from "components/Modals/ModalsWrapper/WideModalWrapper";
import AddOwnerModal from "components/Modals/MultipleAddModals/AddMembers/AddOwner";
import AddMultipleModal from "components/Modals/MultipleAddModals/AddMultiple";
import * as APIs from "config/APIs/sourcingDrive";
import { driveCategory } from "helpers/constants/stargazer";
import { fetchAllCollege, fetchAllRoles } from "redux/dropdown";
import { showToast } from "redux/toaster";
import { PencilIcon } from "@heroicons/react/outline";
import { User } from "@phosphor-icons/react";
import { fetchAllDrives } from "redux/sourcingDrive";

function Details({ details, setDetails, type, templateDrive }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const colleges = useSelector((state) => state.dropdown.colleges);
  const roles = useSelector((state) => state.dropdown.roles);
  const users = useSelector((state) => state.dropdown.users);
  const [ownersModal, setOwnersModal] = useState(false);
  const [collegeModal, setCollegeModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [dataToDelete, setDataToDelete] = useState();

  useEffect(() => {
    if (colleges.length === 0) {
      dispatch(fetchAllCollege());
    }
    if (roles.length === 0) {
      dispatch(fetchAllRoles());
    }
  }, []);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await APIs.deleteEntity(dataToDelete.id);
      dispatch(
        showToast({ message: "Deleted successfully!", type: "success" })
      );
      dispatch(fetchAllDrives());
      setDataToDelete();
      history.goBack();
    } catch (err) {
      console.log("Delete error", err);
      dispatch(
        showToast({
          message: err?.response?.data?.message,
          type: "error",
        })
      );
    }
    setDeleting(false);
  };
  return (
    <>
      <ConfirmModal
        loader={deleting}
        isOpen={dataToDelete ? true : false}
        closeModal={(val) => {
          setDataToDelete(null);
        }}
        onAccept={() => handleDelete()}
        text={<>Are you sure you want to delete {dataToDelete?.name}?</>}
      />

      <AddOwnerModal
        selectOne
        onAdd={(val) => {
          let assignedTo = users.filter((e) => val.includes(e?.id))[0];

          setDetails({ ...details, owner: assignedTo, owner_id: val[0] });
        }}
        selected={[details?.owner]}
        isOpen={ownersModal}
        closeModal={() => {
          setOwnersModal(false);
        }}
      />

      <WideModalsWrapper
        isOpen={collegeModal}
        closeModal={(val) => {
          setCollegeModal(val);
        }}
        height="100vh"
        borderRadius="0px"
        component={
          <AddMultipleModal
            list={colleges
              ?.filter((i) => i?.type == "Live" || i?.type == "Pipeline")
              ?.map((i) => ({
                label: i?.college_name,
                value: i?.id,
                image: i?.image || {},
              }))}
            label="CGCs"
            onAdd={(val) => {
              let selected = colleges.filter((e) => val.includes(e?.id));
              setDetails({
                ...details,
                colleges: selected,
              });
            }}
            selected={details?.colleges?.map((i) => ({
              value: i?.id,
              label: i?.college_name,
              image: i?.image || {},
            }))}
            isOpen={collegeModal}
            closeModal={() => {
              setCollegeModal(false);
            }}
          />
        }
      />
      <div className="rounded-2xl bg-white p-4 relative flex flex-col w-full space-y-6">
        <h1 className="font-karla text-base font-medium text-primary-green-700">
          {templateDrive == "template"
            ? "Template Details"
            : "Add Drive Details"}
        </h1>
        <SimpleInput
          required
          label={`${templateDrive == "drive" ? "Drive" : "Template"} Name`}
          field="name"
          details={details}
          setDetails={setDetails}
        />

        {templateDrive == "drive" && (
          <SimpleInput
            required
            label={`Typeform UTM`}
            field="utm"
            details={details}
            setDetails={setDetails}
          />
        )}
        {templateDrive == "drive" && (
          <>
            <DateInputForObject
              required
              label={"Start Date"}
              field="startDate"
              details={details}
              setDetails={setDetails}
            />

            <DateInputForObject
              required
              label={"End Date"}
              field="endDate"
              details={details}
              setDetails={setDetails}
            />
          </>
        )}

        {templateDrive == "drive" && (
          <div className="flex flex-row space-x-5 md:space-x-10">
            <div className="w-full">
              <div className="flex flex-col items-start w-full">
                <label htmlFor="Drive Owner" className="input-label">
                  Drive Owner
                </label>
                <div
                  onClick={() => {
                    setOwnersModal(true);
                  }}
                  className="flex flex-row items-center space-x-2 mt-2"
                >
                  {details?.owner ? (
                    <div className="flex flex-row items-center px-4 py-2 space-x-4">
                      {details?.owner?.image?.url ? (
                        <img
                          className="w-3 h-3 mr-1.5 rounded-full bg-primary-gray-200 object-cover"
                          src={
                            details?.owner?.image?.url ||
                            "/assets/images/defaultUser.png"
                          }
                          alt=""
                        />
                      ) : (
                        <div className="h-4 w-4 text-primary-green-700 rounded-full object-cover bg-primary-green-30 flex flex-row items-center justify-center">
                          <User size={12} />
                        </div>
                      )}
                      <h1 className="text-primary-green-700 text-sm font-lato font-semibold">
                        {details?.owner?.first_name}
                      </h1>
                    </div>
                  ) : (
                    <p className="text-primary-gray-350 font-lato font-normal text-xs">
                      Add Owner
                    </p>
                  )}
                  <PencilIcon className="w-3 h-3 ml-1.5 text-primary-red-medium " />
                </div>
              </div>
            </div>
          </div>
        )}

        {templateDrive == "template" && (
          <>
            <SimpleRadioBoxesForObject
              label="Template Category"
              field="category"
              minItems={2}
              maxItems={5}
              details={details}
              setDetails={setDetails}
              list={Object.keys(driveCategory).map((i) => ({
                label: i,
                value: i,
              }))}
              outline
            />

            {details?.category && (
              <SimpleRadioBoxesForObject
                label="Template Type"
                field="type"
                minItems={2}
                maxItems={5}
                details={details}
                setDetails={setDetails}
                list={
                  details["category"]
                    ? driveCategory[details["category"]].map((i) => ({
                        label: i,
                        value: i,
                      }))
                    : [
                        {
                          label: "Please select a drive category",
                          value: null,
                        },
                      ]
                }
                outline
              />
            )}
          </>
        )}

        {templateDrive == "template" && (
          <DropdownInput
            horizontal
            label="Role"
            value={details?.role_id}
            setValue={(val) => {
              setDetails({ ...details, role_id: val });
            }}
            list={
              roles.map((i) => ({
                label: i?.role_name,
                value: i?.id,
              })) || []
            }
          />
        )}

        {/* {templateDrive == "drive" && (
            <div className="flex flex-col items-start w-full">
              <label
                onClick={() => {
                  setCollegeModal(true);
                }}
                htmlFor="CGC"
                className="input-label cursor-pointer"
              >
                CGCs +
              </label>
              <div className="flex flex-row items-center flex-wrap -m-1">
                {details?.colleges?.length > 0
                  ? details?.colleges?.map((i) => {
                      return (
                        <div className="flex flex-row items-center m-1">
                          <img
                            src={i?.image?.url || DefaultAvatar}
                            alt="member"
                            className="w-3 h-3 mr-1.5 rounded-full cards-taskboard-v2-index-img2 object-cover"
                          />
                          <h1 className="text-primary-indigo-650 text-xs inter font-bold mr-1">
                            {i?.college_name}
                          </h1>
                        </div>
                      );
                    })
                  : "-"}
              </div>
            </div>
          )} */}

        {type == "edit" && (
          <TrashIcon
            className="w-4 h-4 absolute top-4 right-4 text-primary-red-500 cursor-pointer"
            onClick={() => setDataToDelete(details)}
          />
        )}
      </div>
    </>
  );
}

export default Details;
