import CommunityMobileView from "components/Community/MobileView";
import PageHeader from "components/Layout/PageHeader";

function Community() {
  return (
    <>
      <PageHeader name="Tribe" ctaComponent={<></>} /> <CommunityMobileView />
    </>
  );
}

export default Community;
