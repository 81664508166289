import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Disclosure } from "@headlessui/react";
import * as AttributeType from "config/APIs/attributes";
import { XIcon } from "@heroicons/react/outline";
import { fetchAllAttributesType } from "redux/dropdown";
import EmptyState from "components/Comman/EmptyState";
import ChronosButton from "components/Comman/Buttons";
import DropdownInput from "components/Comman/Inputs/DropdownInput";
import { Plus } from "@phosphor-icons/react";

function AddEditProfilingAttributes({
  details,
  setDetails,
  closeModal,
  isOpen,
}) {
  const dispatch = useDispatch();
  const types = useSelector((state) => state.dropdown.attributeTypes);
  const attributesList = useSelector((state) => state.dropdown.attributes);
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [attributes, setAttributes] = useState({});

  useEffect(() => {
    if (isOpen) {
      setData(details);
      if (details?.id) {
        fetchAllAttributes(details?.id);
      }
    }
  }, [isOpen]);

  const fetchAllAttributes = async (typeId) => {
    if (Object.keys(attributes)?.includes(typeId)) return;
    if (attributesList?.length > 0) {
      let temp = attributesList?.filter((i) => i?.attributeTypes?.id == typeId);
      let a = { ...attributes };
      a[typeId] = temp;
      setAttributes(a);

      return;
    } else {
      dispatch(fetchAllAttributesType("attributes"));
    }
    setFetching(true);
    try {
      const response = await AttributeType.getAttributes("attributes", typeId);
      if (response.data.data && response.data.data.length > 0) {
        let a = { ...attributes };
        a[typeId] = response.data.data;
        setAttributes(a);
      }
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  const addOrRemove = (item) => {
    let d = [...data.list] ?? [];

    if (d?.find((i) => i?.id == item?.id)) {
      d = d?.filter((i) => i?.id !== item?.id);
    } else {
      let obj = { ...item };

      d.push(obj);
    }
    setData({ ...data, list: d });
  };

  return (
    <div className="bg-white max-w-xl p-6 w-full mx-auto relative rounded-lg space-y-8">
      <div className="flex flex-row items-center justify-between">
        <h5 className="font-karla font-medium text-xl text-primary-green-700 leading-6">
          Add question under {data?.section}
        </h5>

        <div className="flex flex-row items-center space-x-3">
          <button aria-label="Close" type="button" onClick={() => closeModal()}>
            <XIcon className="h-5 w-5" />
          </button>
        </div>
      </div>

      <DropdownInput
        label="Parameter"
        value={data?.typeId}
        setValue={(val) => {
          let id = val;
          let d = { ...data };
          let item = types?.find((t) => t?.id == id);

          if (d?.id !== id) {
            setData({
              ...data,
              name: item?.name,
              list: [],
              id: item?.id,
              typeId: item?.id,
              questionList: item?.questions,
              inputType: item?.inputType,
              inputLimit: item?.inputLimit,
            });
          } else {
            setData({
              ...data,
              name: item?.name,
              questionList: item?.questions,
              inputType: item?.inputType,
              inputLimit: item?.inputLimit,
            });
          }
          fetchAllAttributes(id);
        }}
        list={(
          types?.filter((i) => i?.profilingSection == data?.section) || []
        )?.map((item) => ({
          value: item?.id,
          label: item?.name,
        }))}
      />
      <DropdownInput
        label="Question"
        placeholder="Select the question to show"
        value={data?.question}
        setValue={(val) => {
          let d = { ...data };

          d["question"] = val;
          setData(d);
        }}
        list={(data?.questionList || [])?.map((item) => ({
          value: item,
          label: item,
        }))}
      />

      <div className="h-70vh lg:h-40vh overflow-y-auto space-y-1">
        <label className="input-label">Options</label>
        <div className="flex flex-row items-center flex-wrap -ml-2 -mt-2">
          {attributes[data?.id] && attributes[data?.id]?.length > 0 ? (
            attributes[data?.id]?.map((a) => {
              return (
                <div
                  onClick={() => addOrRemove(a)}
                  className={`rounded-lg px-6 py-2 text-center text-sm border font-lato cursor-pointer m-2 ${
                    data?.list?.find((d) => d?.id == a?.id)
                      ? "font-normal border-primary-green-700 bg-primary-green-lightest text-primary-green-700"
                      : "font-normal bg-primary-gray-50 border-primary-gray-250 text-primary-gray-800"
                  }`}
                >
                  {a?.name}
                </div>
              );
            })
          ) : (
            <div className="w-full">
              <EmptyState text="No Options found!" />
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-row items-center justify-center space-x-8">
        <ChronosButton
          text="Cancel"
          secondary
          onClick={() => {
            closeModal();
          }}
        />
        <ChronosButton
          text="Add Now"
          icon={<Plus size={12} className="mr-2" />}
          reverseIcon
          primary
          onClick={() => {
            setDetails(data);
            closeModal();
          }}
        />
      </div>
    </div>
  );
}

export default AddEditProfilingAttributes;
