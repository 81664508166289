import React, { useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import { useDispatch } from "react-redux";

import {
  PROSPECTS,
  TALENT_SHORTLIST_STAGES,
  TALENT_SHORTLIST_STAGES_STATUS,
} from "helpers/constants/talentRequest";

const lists = TALENT_SHORTLIST_STAGES;

/**
 * Displays the draggable goals filtered on the status of task
 * @param {Object} data
 * @returns
 */

function DragList({ data, fetching, onUpdate, roleId }) {
  let dispatch = useDispatch();
  const [elements, setElements] = React.useState([]);

  useEffect(() => {
    getElements();
  }, [data, fetching]);

  const getElements = async () => {
    let stages = [];
    let arr = [];

    data?.map((item) => {
      if (stages?.includes(item?.stage)) {
        let idx = arr?.findIndex((a) => a?.stage == item?.stage);
        arr[idx].applicant?.push(item);
      } else {
        let obj = { stage: item?.stage, applicant: [item] };
        arr.push(obj);
        stages.push(item?.stage);
      }
    });

    let temp = {};

    TALENT_SHORTLIST_STAGES?.map((l) => {
      temp[l] = [];
    });

    arr.map((item) => {
      item?.applicant.map((item2) => {
        let stage = item2?.stage || PROSPECTS;
        temp[stage]?.push(item2);
      });
    });
    console.log("list", temp);
    setElements(temp);
  };

  const updateStage = async (body) => {
    let mainBody = {
      ...body,
    };
    console.log(body);
    try {
      // const response = await updateApplicant(body.id, mainBody);
      onUpdate();
    } catch (err) {
      console.log(err);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const listCopy = { ...elements };

    // Getting the source list
    let sourceList = listCopy[result.source.droppableId];

    // Getting the itm to drag
    let itemToMove = sourceList?.find((e) => e?.id === result?.draggableId);
    let tempBody = { ...itemToMove };
    tempBody.stage = result.destination.droppableId;
    tempBody.status =
      TALENT_SHORTLIST_STAGES_STATUS[result.destination.droppableId][0];
    updateStage(tempBody);

    // Removing the item from the source list

    sourceList = sourceList.filter((e) => e?.id !== itemToMove?.id);
    listCopy[result.source.droppableId] = sourceList;

    // Getting the old drop list
    const oldDropList = listCopy[result.destination.droppableId];

    // Add the item to the dragged location
    oldDropList.push(itemToMove);

    listCopy[result.destination.droppableId] = oldDropList;

    setElements(listCopy);
  };

  return (
    <div className="max-w-max md:max-w-80vw xl:max-w-70vw mx-auto min-w-max overflow-x-auto">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="grid grid-cols-5 max-w-max min-w-max divide-x-2 divide-primary-gray-150">
          {lists.map((listKey) => (
            <DraggableElement
              elements={elements[listKey]}
              key={listKey}
              prefix={listKey}
              onUpdate={onUpdate}
              roleId={roleId}
            />
          ))}
        </div>
      </DragDropContext>
    </div>
  );
}

export default DragList;
