import { useState } from "react";
import AddCollege from "./AddCollege";
import Tabs from "components/Comman/Tabs";
import SearchBox from "components/Comman/Inputs/SearchBox";
import ChronosButton from "components/Comman/Buttons";
import Toggle from "components/Comman/Inputs/Toggle";
import EmptyState from "components/Comman/EmptyState";
import { updateCollege } from "config/APIs/college";
import { fetchAllCollege } from "redux/college";
import { useDispatch, useSelector } from "react-redux";

function CgcDetails({ list, selectedCgc }) {
  const [addCollege, setAddCollege] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Colleges");
  const [search, setSearch] = useState();

  return (
    <>
      <AddCollege
        isOpen={addCollege}
        closeModal={() => {
          setAddCollege(false);
        }}
        selectedColleges={list?.map((i) => i?.id)}
        selectedCgc={selectedCgc}
      />
      <div className="px-4 flex pb-6 flex-col space-y-4 w-full">
        <div className="pt-4 pb-2 text-primary-green-dark font-poppins text-xl font-medium leading-9">
          Details
        </div>
        <Tabs
          tabs={[
            { label: "Colleges", value: "Colleges" },
            { label: "Insights", value: "Insights" },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          components={<></>}
        />
        <SearchBox
          dark
          search={search}
          setSearch={(val) => {
            setSearch(val);
          }}
        />
        <ChronosButton
          primary
          dark
          text="+ Link College"
          onClick={() => {
            setAddCollege(true);
          }}
        />
        <div className="flex max-h-[60vh] overflow-y-auto pb-6 flex-col space-y-4 w-full">
          {selectedTab == "Colleges" && (
            <>
              {list?.length > 0 ? (
                <>
                  {list?.map((item) => {
                    if (
                      !search?.searchText ||
                      search?.searchText == "" ||
                      (search?.searchText &&
                        search?.searchText !== "" &&
                        item?.college_name
                          ?.toLowerCase()
                          ?.includes(search?.searchText?.toLowerCase()))
                    )
                      return (
                        <CollegeCard item={item} selectedCgc={selectedCgc} />
                      );
                  })}
                </>
              ) : (
                <>
                  <EmptyState
                    imageMedium
                    image="/assets/images/empty/college.svg"
                    text="No Colleges found!"
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CgcDetails;

function CollegeCard({ item, selectedCgc }) {
  const ftp = useSelector((state) => state?.ftp?.list);
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(item?.archived);

  const update = async (e) => {
    try {
      let res = await updateCollege(item?.id, { type: e ? "Live" : "Other" });

      if (res) {
        dispatch(fetchAllCollege("colleges"));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      className={`w-full shadow-md rounded-2xl p-5 text-primary-neutral-800 flex flex-col space-y-2 ${
        item?.id == selectedCgc?.id
          ? "bg-primary-green-30 border border-primary-green-medium"
          : "bg-white"
      }`}
    >
      <div className="w-full flex flex-row items-center justify-end">
        <Toggle
          disabled
          theme="dark"
          enabled={
            ftp?.filter((a) => a?.collegeId == item?.id)?.length > 0
              ? true
              : false
          }
          setEnabled={() => {
            // update(!enabled);
            // setEnabled(!enabled);
          }}
        />
      </div>

      <div className="flex flex-col space-y-2.5">
        <h1 className="font-inter text-lg font-semibold leading-7">
          {item?.college_name}
        </h1>
        <p className="opacity-50 font-inter text-sm font-light">
          {ftp?.filter((a) => a?.collegeId == item?.id)?.length > 0
            ? "Currently Sourcing"
            : "Currently Not Sourcing"}
        </p>
      </div>
    </div>
  );
}
