import axios from "axios";
import config from "config/APIEndpoints";

const CORE_BASE_API_URL = config.getEndpoint()?.coreEndpoint;
const BASE_API_URL = config.getEndpoint()?.talentEndpoint;
const ADMIN_API_URL = BASE_API_URL + "/api/v1";
const STARGAZE_ENDPOINT = ADMIN_API_URL + "/stargaze/";
const BASE_ME_API_URL = config.getEndpoint()?.meEndpoint + "/api/v1";

export { BASE_API_URL };

export const feBaseLink = {
  applicationForm: "https://apply.caarya.life/",
};

// Util
export const getHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
};

export const getMultipartHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "multipart/form-data",
    },
  };
};

export const instance = axios.create({
  baseURL: ADMIN_API_URL,
});

export const stargazeInstance = axios.create({
  baseURL: STARGAZE_ENDPOINT,
});

export const corePublicInstance = axios.create({
  baseURL: CORE_BASE_API_URL + "/api/v1/public",
});

export const meInstance = axios.create({
  baseURL: BASE_ME_API_URL,
});
