import { useState, useEffect } from "react";
import PageHeader from "components/Layout/PageHeader";
import CGCList from "components/CGC/CGCList";
import { useSelector } from "react-redux";
import Tabs from "components/Comman/Tabs";

function CGC() {
  const cGCs = useSelector((state) => state?.college?.cgc);
  const crew = useSelector((state) => state?.college?.crew);
  const colleges = useSelector((state) => state?.college?.list);
  const [selectedTab, setSelectedTab] = useState();

  useEffect(() => {
    if (crew?.length > 0) {
      setSelectedTab(crew?.[0]?.id);
    }
  }, [crew]);

  return (
    <>
      <PageHeader name="TFC" />

      <div className="flex flex-col flex-start gap-6 bg-neutral-50">
        <Tabs
          tabs={crew?.map((item) => ({
            label: item?.name,
            value: item?.id,
            image: item?.image?.url,
          }))}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          center
        />
        <div className="flex flex-col gap-2 pb-6">
          {cGCs.map((cgc) => {
            if (cgc?.crewId == selectedTab)
              return (
                <CGCList
                  cgc={cgc}
                  data={colleges?.filter((i) => i?.cgcId == cgc?.id)}
                />
              );
          })}
        </div>
      </div>
    </>
  );
}

export default CGC;
