import EmptyState from "components/Comman/EmptyState";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { showToast } from "redux/toaster";
import * as APIs from "config/APIs/sourcingDrive";
import { fetchAllDrives } from "redux/sourcingDrive";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import SearchBox from "components/Comman/Inputs/SearchBox";
import ChronosButton from "components/Comman/Buttons";
import { Plus } from "@phosphor-icons/react";
import PageHeader from "components/Layout/PageHeader";

function Templates() {
  const list = useSelector((state) => state?.sourcingDrive?.templates);
  const dispatch = useDispatch();
  const history = useHistory();

  const [deleting, setDeleting] = useState(false);
  const [dataToDelete, setDataToDelete] = useState();
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });
  const handleDelete = async () => {
    setDeleting(true);
    try {
      await APIs.deleteEntity(dataToDelete.id);
      dispatch(
        showToast({ message: "Deleted successfully!", type: "success" })
      );
      dispatch(fetchAllDrives());
      setDataToDelete();
    } catch (err) {
      console.log("Delete error", err);
      dispatch(
        showToast({
          message: err?.response?.data?.message,
          type: "error",
        })
      );
    }
    setDeleting(false);
  };
  return (
    <>
      <ConfirmModal
        loader={deleting}
        isOpen={dataToDelete ? true : false}
        closeModal={(val) => {
          setDataToDelete(null);
        }}
        onAccept={() => handleDelete()}
        text={<>Are you sure you want to delete {dataToDelete?.name}?</>}
      />
      <PageHeader
        name="Profiling Templates"
        ctaComponent={
          <ChronosButton
            text="Add New Template"
            icon={<Plus size={12} className="mr-2" />}
            reverseIcon
            primary
            onClick={() => {
              history.push("/template/create/0");
            }}
          />
        }
      />
      <div className="px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="px-8 py-2 bg-white rounded-2xl space-y-6">
          <div className="relative  flex flex-col lg:flex-row items-center justify-between space-y-4 lg:space-y-0 lg:space-x-4">
            <SearchBox
              placeholder="Find a template!"
              search={search}
              setSearch={setSearch}
            />
          </div>
          {list?.length > 0 ? (
            <div className="w-full space-y-6">
              {list?.map((template) => {
                if (
                  search?.searchText == "" ||
                  (search?.searchText !== "" &&
                    template?.name
                      ?.toLowerCase()
                      ?.includes(search?.searchText?.toLowerCase()))
                )
                  return (
                    <div className="border border-primary-gray-200 rounded-2xl p-4 flex flex-col lg:flex-row items-stretch space-y-1.5 lg:space-y-0 lg:space-x-2.5">
                      <div className="flex flex-col space-y-0.5 w-full lg:w-1/2">
                        <h1 className="text-primary-green-900 font-lato text-base font-semibold">
                          {template?.name}
                        </h1>
                        <h5 className="text-primary-green-700 font-lato text-sm font-semibold">
                          {template?.role?.role_name}
                        </h5>
                        <p className="text-primary-green-900 font-lato text-sm font-light line-clamp-3">
                          {template?.description}
                        </p>
                      </div>
                      <div className="flex flex-col space-y-2.5 h-full justify-between items-end w-full lg:w-1/2">
                        <div className="flex flex-row items-center space-x-10 cursor-pointer">
                          <p
                            onClick={() => setDataToDelete(template)}
                            className="text-primary-red-500 font-lato text-sm underline underline-offset-2 font-semibold"
                          >
                            Delete Template
                          </p>{" "}
                          <p
                            onClick={() => {
                              history.push(`/template/edit/${template?.id}`);
                            }}
                            className="text-primary-gray-800 font-lato text-sm underline underline-offset-2 font-semibold"
                          >
                            Edit Template
                          </p>
                        </div>
                        <p className="text-primary-gray-200 font-lato text-xs font-semibold line-clamp-3">
                          Created on: {moment(template?.createdAt).format("ll")}
                        </p>
                      </div>
                    </div>
                  );
              })}
            </div>
          ) : (
            <EmptyState />
          )}
        </div>
      </div>
    </>
  );
}

export default Templates;
