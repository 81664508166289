import React, { useState, useEffect } from "react";
import * as TalentAPI from "config/APIs/talent";
import { MdLocalFireDepartment } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Transition, Disclosure } from "@headlessui/react";
import moment from "moment";
import { BiChevronUp } from "react-icons/bi";
import {
  USER_TYPE_CUSTOMER_FIC,
  USER_TYPE_CUSTOMER_FWS,
} from "helpers/constants/users";
import ChronosButton from "components/Comman/Buttons";
import PageHeader from "components/Layout/PageHeader";
import { Paperclip } from "@phosphor-icons/react";
import BoardView from "../../../components/TalentRequest/BoardView";
import { useSelector } from "react-redux";
import ShortlistTalent from "components/TalentRequest/ShortlistTalent";

const TalentInfo = () => {
  const { id } = useParams();
  const ftpList = useSelector((state) => state?.ftp?.list);
  const [details, setDetails] = useState({});
  const [shortlistTalentButton, setShortlistTalentButton] = useState(false);

  const [users, setUsers] = useState([]);
  const [selectedFTP, setSelectedFTP] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      getDetails();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  const getDetails = async () => {
    try {
      const response = await TalentAPI.getOneEntity(id);
      const request = response.data.data;
      setDetails(request);
    } catch (err) {
      console.log("Fetch drive error", err);
    }
  };

  return (
    <>
      {shortlistTalentButton ? (
        <PageHeader
          back
          name="Shortlist Talent"
          ctaComponent={
            <div className="relative">
              <div className="absolute -top-1 -right-1 w-4 h-4 rounded-full text-xs font-latp font-semibold flex flex-row items-center justify-center border border-primary-green-700 text-primary-green-700 bg-white">
                {selectedFTP?.length || 0}
              </div>
              <ChronosButton
                text="View Shortlist"
                onClick={() => {
                  setShortlistTalentButton(false);
                  setUsers(selectedFTP);
                  setSelectedFTP([]);
                }}
                primary
              />
            </div>
          }
        />
      ) : (
        <PageHeader back name="Talent Request Details" />
      )}
      <div className="px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="space-y-6">
          <div className="px-6 py-4 bg-white rounded-2xl space-y-4 w-full">
            <Disclosure
              defaultOpen
              className="bg-white rounded-2xl space-y-4 w-full"
            >
              {({ open }) => (
                <>
                  <Disclosure.Button className="w-full flex flex-row items-center justify-between">
                    <div className="w-full flex items-center justify-between pb-4 border-b border-gray-200">
                      <p className="text-lg font-light text-primary-green-dark font-lato">
                        Request Details
                      </p>
                      <BiChevronUp
                        className={
                          open
                            ? "transform w-6 scale-125"
                            : "-rotate-180 w-6 scale-125"
                        }
                      />
                    </div>
                  </Disclosure.Button>

                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="w-full text-primary-gray-800 font-lato rounded-2xl">
                      <div className="border-b px-4 py-8 space-y-8 border-primary-gray-200">
                        <div className=" w-full flex flex-row items-start justify-between space-x-2">
                          <div className="flex flex-col items-start space-y-1 font-lato text-primary-gray-800">
                            <p className="text-sm font-semibold">
                              {details?.name}
                            </p>
                            <p className="text-sm font-light">
                              For {details?.designation?.role?.role_name}
                            </p>
                          </div>
                          <p className="flex items-center space-x-8">
                            <span className="text-sm font-semibold font-lato">
                              Priority:
                            </span>
                            <span className="flex items-center p-1 rounded bg-primary-red-100">
                              <span>
                                <MdLocalFireDepartment
                                  className="text-primary-red-900"
                                  size={16}
                                />
                              </span>
                              <span className="text-xs">
                                {details?.priority}
                              </span>
                            </span>
                          </p>
                        </div>
                        <div className="w-full flex flex-row items-stretch justify-start space-x-12">
                          <div className="flex flex-col items-start space-y-1 font-lato text-primary-gray-800">
                            <p className="text-sm font-semibold">
                              {details?.userType == USER_TYPE_CUSTOMER_FIC
                                ? "FIC"
                                : details?.userType == USER_TYPE_CUSTOMER_FWS
                                ? "FWS"
                                : "Core"}
                            </p>
                            <p className="text-sm font-light">Contract Type</p>
                          </div>
                          <div className="flex flex-col items-start space-y-1 font-lato text-primary-gray-800">
                            <p className="text-sm font-semibold">
                              {details?.openings}
                            </p>
                            <p className="text-sm font-light">Openings</p>
                          </div>
                          <div className="flex flex-col items-start space-y-1 font-lato text-primary-gray-800">
                            <p className="text-sm font-semibold">
                              {details?.userType == USER_TYPE_CUSTOMER_FIC
                                ? "P1-P2"
                                : details?.userType == USER_TYPE_CUSTOMER_FWS
                                ? "P3-P5"
                                : "Core"}
                            </p>
                            <p className="text-sm font-light">Skill Level</p>
                          </div>
                        </div>
                        <div className="w-full flex flex-row items-start justify-between space-x-4">
                          <div className="flex flex-row items-center text-primary-gray-250 font-lato text-xs font-light">
                            Requested by {details?.creator?.first_name}
                          </div>
                          <div className="flex flex-col items-start text-left space-y-1 font-lato text-primary-gray-800">
                            <p className="text-sm font-semibold text-primary-red-500">
                              {moment(details?.expiryDate).format("ll")}
                            </p>
                            <p className="text-sm font-light">Expiring On</p>
                          </div>{" "}
                        </div>
                      </div>
                      <div className="px-6 py-4 space-y-3">
                        <h1 className="text-base font-karla text-black">
                          Attachments
                        </h1>
                        <div className="flex underline-offset-2 flex-row items-center flex-wrap -ml-3">
                          <div className="m-3 space-x-3 flex flex-row items-center bg-primary-gray-50 border border-primary-gray-200 rounded-lg px-4 py-1">
                            <Paperclip size={20} className="text-blue-500" />
                            <p className="text-primary-gray-500 underline text-sm font-lato font-light">
                              About Caarya Me
                            </p>
                          </div>
                          <div className="m-3 space-x-3 flex flex-row items-center bg-primary-gray-50 border border-primary-gray-200 rounded-lg px-4 py-1">
                            <Paperclip size={20} className="text-blue-500" />
                            <p className="text-primary-gray-500 underline text-sm font-lato font-light">
                              About The Job
                            </p>
                          </div>
                          <div className="m-3 space-x-3 flex flex-row items-center bg-primary-gray-50 border border-primary-gray-200 rounded-lg px-4 py-1">
                            <Paperclip size={20} className="text-blue-500" />
                            <p className="text-primary-gray-500 underline text-sm font-lato font-light">
                              Link to Website
                            </p>
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </div>
          <div className="px-6 py-6 bg-white rounded-2xl space-y-4 w-full">
            <div className="w-full flex flex-row items-center justify-between">
              <div className="w-full flex items-center justify-between pb-2 pt-2 border-b border-gray-200">
                <p className="text-lg font-dark text-primary-green-300 font-lato">
                  Shortlisted Candidates
                </p>
                <ChronosButton
                  onClick={() =>
                    setShortlistTalentButton(!shortlistTalentButton)
                  }
                  primary
                  text={
                    users.length ? `Shortlist More Talent` : `Shortlist Now`
                  }
                />
              </div>
            </div>
            <div className="w-full text-primary-gray-800 font-lato rounded-2xl">
              {shortlistTalentButton ? (
                <ShortlistTalent
                  users={ftpList?.filter(
                    (a) => a?.roleId == details?.designation?.role?.role_name
                  )}
                  addUserHandler={(user) =>
                    setSelectedFTP([...selectedFTP, user])
                  }
                />
              ) : users?.length ? (
                <BoardView data={users} onUpdate={() => {}} />
              ) : (
                <div className="flex flex-col items-center py-20 px-8">
                  <p className="text-3xl text-gray-400 font-light">
                    No Talent Shortlisted Yet
                  </p>
                  <p className="text-base text-gray-400 font-light mx-6 my-6">
                    Talent you shortlist will appear here
                  </p>
                  <ChronosButton
                    onClick={() =>
                      setShortlistTalentButton(!shortlistTalentButton)
                    }
                    secondary
                    text="Shortlist Now"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentInfo;
