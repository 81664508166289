import { ArrowLeft } from "@phosphor-icons/react";
import Tabs from "components/Comman/Tabs";
import PageHeader from "components/Layout/PageHeader";
import { findAllCollegeApplicants, getCollegeById } from "config/APIs/college";
import { ftpCount } from "config/APIs/ftp";
import CollegeCardsV4 from "components/Colleges/CollegeCards/CollegeCardsV4";
import moment from "moment";
import bg2 from "assets/svg/bg2.svg";
import PolygonLogo from "assets/icons/PolygonLogo.svg";
import { FiActivity } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

const tabs = [
  { label: "Current Members", value: "current" },
  { label: "Roster", value: "roster" },
  { label: "Insights", value: "insights" },
  { label: "Applicants", value: "applicants" },
];

const College = () => {
  const history = useHistory();
  const membersList = useSelector((state) => state?.college?.users);
  const ftp = useSelector((state) => state?.ftp?.list);
  const [collegeData, setCollegeData] = useState({});
  const [stats, setStats] = useState({});
  const { id } = useParams();
  const [members, setMembers] = useState([]);
  const [selectedTab, setSelectedTab] = useState("current");

  useEffect(() => {
    getCollegeData();

    getStats();
  }, []);

  useEffect(() => {
    if (membersList?.length > 0) {
      setMembers(
        membersList?.filter(
          (a) => a?.details?.college == collegeData?.college_name
        )
      );
    }
  }, [membersList, collegeData]);

  const renderSection = (title, list = []) => {
    return (
      <div>
        <div className="text-center text-3xl font-medium font-satoshi my-8">
          {title}
        </div>
        <div className="flex flex-wrap gap-8 justify-center items-start">
          {list?.map((it, index) => (
            <CollegeCardsV4
              title={it?.first_name}
              key={index}
              memberName={
                it?.first_name + " " + (it?.last_name ? it?.last_name : "")
              }
              image={it?.image}
              year={
                it?.details?.college_year == "Graduated"
                  ? it?.details?.college_year
                  : `${it?.details?.college_year} Year`
              }
            />
          ))}
        </div>
      </div>
    );
  };

  const getCollegeData = async () => {
    try {
      const res = await getCollegeById(id);

      setCollegeData(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getStats = async () => {
    try {
      const res = await ftpCount(id);

      let temp = {
        total: res?.data?.data?.length || 0,
        newApplicants:
          res?.data?.data?.filter(
            (a) => moment(a?.createdAt).unix() > moment().add(-7, "days").unix()
          )?.length || 0,
      };
      setStats(temp);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <PageHeader name={collegeData?.college_name} />
      <div className="flex flex-col gap-6 flex-start bg-neutral-50">
        <div className="mt-2 lg:px-4 px-0">
          <div
            onClick={() => history.goBack()}
            className="px-4 cursor-pointer flex flex-row items-center space-x-2 font-lato text-primary-neutral-500 text-base font-bold"
          >
            <ArrowLeft size={12} />
            <p>Back To TFCs</p>
          </div>
          <div className="mt-10 bg-white rounded-[40px] flex flex-col">
            <div
              className="relative w-full h-[328px]"
              style={{
                backgroundImage: `url('/assets/images/demo.png')`,
                backgroundSize: "cover",
                backgroundPosition: "unset",
              }}
            >
              <img
                src={bg2}
                className="absolute bottom-0 w-full"
                alt="background"
              />
              <img
                src={PolygonLogo}
                alt="logo"
                className=" absolute right-1/4 top-[15%]"
              />
              <div className="flex gap-4 flex-col justify-center absolute top-[20%] left-[5%] w-fit ">
                <div className="uppercase text-xl font-light font-santoshi text-white">
                  THE FUTURE CLUB
                </div>
                <div className="text-white font-satoshi text-[52px] font-bold uppercase leading-[120%] tracking-[10.4px]">
                  {collegeData?.college_name}
                </div>
                <div className="flex gap-4 items-center">
                  <div className="text-base text-white font-bold font-santoshi ">
                    {(ftp?.filter((a) => a?.collegeId == collegeData?.id)
                      ?.length || 0) + (members?.length || 0)}{" "}
                    Members
                  </div>
                  <div className="flex px-2 py-1 items-center rounded bg-green-50 w-fit">
                    {" "}
                    <FiActivity className="m-1" />
                    Most Active
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4">
              <Tabs
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </div>
            <div className="py-6">
              {selectedTab == "applicants" ? (
                <div className="py-6 px-10">
                  <div className="flex flex-wrap gap-8 justify-center items-start">
                    {ftp
                      ?.filter((a) => a?.collegeId == collegeData?.id)
                      ?.map((it, index) => (
                        <CollegeCardsV4
                          title={it?.name}
                          key={index}
                          memberName={it?.name}
                          year={
                            it?.yearOfStudy == "Graduated"
                              ? it?.yearOfStudy
                              : `${it?.yearOfStudy} Year`
                          }
                        />
                      ))}
                  </div>
                </div>
              ) : selectedTab == "roster" ? (
                <div className="py-6 px-10">
                  <div className="flex flex-wrap gap-8 justify-center items-start">
                    {members?.map((it, index) => (
                      <CollegeCardsV4
                        title={it?.first_name}
                        key={index}
                        memberName={
                          it?.first_name +
                          " " +
                          (it?.last_name ? it?.last_name : "")
                        }
                        image={it?.image}
                        // year={
                        //   it?.details?.college_year == "Graduated"
                        //     ? it?.details?.college_year
                        //     : `${it?.details?.college_year} Year`
                        // }
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div className="py-6 px-10">
                  {renderSection("Work Scholarship")}
                  {renderSection(
                    "Observership",
                    members?.filter((m) => m?.is_active)
                  )}
                  {renderSection("Leadership Accelerator Program")}
                  {renderSection("Industrial Contracts")}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="flex p-4 flex-col flex-start gap-4 bg-white shadow-md">
          <div className="flex flex-col gap-4 items-center">
            {collegeData?.image?.url ? (
              <img
                src={collegeData?.image?.url}
                alt=""
                className="w-14 h-14 object-contain rounded-full"
              />
            ) : (
              <div className="w-14 h-14 bg-[#D9D9D9] rounded-full" />
            )}
            <div className="flex flex-col items-center self-stretch">
              <h1 className="text-neutral-800 font-poppins text-xl font-medium">
                {collegeData?.college_name}
              </h1>
              <h1 className="text-neutral-400 font-inter text-xs font-light">
                {collegeData?.cgc?.name
                  ? `CGC ${collegeData?.cgc?.name}`
                  : "No CGC"}
              </h1>
            </div>
            <div className="flex px-20 py-4 flex-col items-center justify-center">
              <h1 className="text-primary-gray-800 font-inter text-[32px] font-medium">
                {stats?.total || 0}
              </h1>
              <h1 className="text-neutral-400 font-inter text-xs font-semibold">
                Total Applicants
              </h1>
            </div>
          </div>
        </div>

        <div className="flex px-4 flex-col flex-start gap-6 items-center">
          <div className="flex px-20 py-4 flex-col items-center justify-center">
            <h1 className="text-primary-green-dark font-inter text-[32px] font-medium">
              +{stats?.newApplicants || 0}
            </h1>
            <h1 className="text-neutral-800 font-inter text-xs font-light">
              New Applicants
            </h1>
          </div>
          <div className="flex flex-col w-full space-y-6 pb-20">
            {members.length > 0 ? (
              members?.map((a) => {
                return (
                  <div className="bg-white rounded-lg shadow-md p-4 flex flex-col space-y-4">
                    <h1 className="text-primary-gray-800 font-inter text-base font-semibold">
                      {a?.firstName} {a?.lastName}
                    </h1>
                    <div className="flex flex-row items-center space-x-2">
                      <p className="text-primary-neutral-400 font-inter text-xs font-semibold">
                        Status
                      </p>{" "}
                      <div className="-mt-2 w-1/2">
                        <StatusDropdown
                          details={{ status: "Life Onboarding" }}
                          value={status}
                          setValue={(val) => {}}
                          list={APPLICANT_STAGES?.map((a) => ({
                            label: a,
                            value: a,
                          }))}
                          colorList={[
                            "text-primary-orange-300",
                            "text-primary-blue-400",
                            "text-primary-green-300",
                            "text-primary-yellow-700",
                            "text-primary-red-darker",
                          ]}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-col">
                        <div className="text-primary-gray-800 font-semibold font-inter text-2xl leading-9">
                          {getScore(a?.assessment1?.data, a?.assessment2?.data)}
                        </div>
                        <div className="text-primary-gray-800 font-inter font-light text-xs">
                          AI5 Score
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          history.push(`/applicant/${a?.id}`);
                        }}
                        className="flex flex-row justify-end w-full items-end mb-1 gap-2 text-primary-green-dark"
                      >
                        <h1 className="font-inter text-xs font-semibold underline-offset-2 underline ">
                          View Response
                        </h1>
                        <ArrowRight size={16} />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyState text="No applicants found!" />
            )}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default College;
