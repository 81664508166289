import { useState, useEffect } from "react";

import EmptyState from "components/Comman/EmptyState";

import { useDispatch, useSelector } from "react-redux";
import MemberCard from "components/Community/Card/MemberCard";

function CommunityList({ search, setShowing, active = true, userType }) {
  const dispatch = useDispatch();
  const community = useSelector((state) => state.alumni);
  const [fetching, setFetching] = useState(false);
  const [list, setList] = useState([]);

  const fetchAll = async () => {
    if (community?.list?.length > 0) {
      setList(community?.list);
      let str = `${community?.list?.length} members`;
      setShowing(str);
      return;
    }
  };

  useEffect(() => fetchAll(), [community?.list]);

  useEffect(() => {
    let str = `${community?.list?.length} members`;
    setShowing(str);
  }, [active, userType]);

  return (
    <div className="space-y-5 flex flex-col w-full overflow-y-auto max-h-70vh pb-20 bg-white pt-4 px-4">
      {!fetching ? (
        <>
          <div className="flex flex-col w-full space-y-3 bg-primary-gray-50 rounded-lg py-3 px-2">
            {list?.length > 0 ? (
              list?.map((i) => {
                return (
                  <MemberCard
                    hideStats
                    item={i}
                    onSelect={() => {
                      history.push({
                        pathname: `/squadUser/${i?.id}`,
                        state: { user: i },
                      });
                    }}
                  />
                );
              })
            ) : (
              <EmptyState />
            )}
          </div>
        </>
      ) : (
        <>
          {[1].map((item) => {
            return <></>;
          })}
        </>
      )}
    </div>
  );
}

export default CommunityList;
