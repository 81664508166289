import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import CrewList from "./CrewList";
import CgcList from "./CgcList";
import CgcDetails from "./CgcDetails";

function CollegeList() {
  const colleges = useSelector((state) => state?.college?.list);
  const crews = useSelector((state) => state?.college?.crew);
  const cgcs = useSelector((state) => state?.college?.cgc);
  const ftp = useSelector((state) => state?.ftp?.list);

  const [selectedCrew, setSelectedCrew] = useState();
  const [selectedCgc, setSelectedCgc] = useState();

  useEffect(() => {
    if (crews?.length > 0) {
      let c = crews[0];
      if (!selectedCrew) setSelectedCrew(c);
    }
  }, [crews]);

  useEffect(() => {
    if (cgcs?.filter?.((a) => a?.crewId == selectedCrew?.id)?.length > 0) {
      let cl = cgcs?.filter?.((a) => a?.crewId == selectedCrew?.id);
      setSelectedCgc(cl[0]);
    }
  }, [selectedCrew, cgcs]);

  return (
    <div className="mt-5 bg-primary-gray-50 shadow rounded-lg grid lg:grid-cols-3 divide-x-2 divide-primary-neutral-200">
      <CrewList
        list={crews}
        selectedCrew={selectedCrew}
        setSelectedCrew={setSelectedCrew}
      />
      <CgcList
        list={cgcs
          ?.filter((i) => i?.crewId == selectedCrew?.id)
          ?.sort((a, b) => {
            return b.colleges?.length - a.colleges?.length;
          })}
        selectedCgc={selectedCgc}
        setSelectedCgc={setSelectedCgc}
        selectedCrew={selectedCrew}
      />
      <CgcDetails
        list={colleges
          ?.filter((i) => i?.cgcId == selectedCgc?.id)
          ?.sort((a, b) => {
            return (
              ftp?.filter((c) => c?.collegeId == b?.id)?.length -
              ftp?.filter((c) => c?.collegeId == a?.id)?.length
            );
          })}
        selectedCgc={selectedCgc}
      />
    </div>
  );
}

export default CollegeList;
