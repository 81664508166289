import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import { TALENT_SHORTLIST_STAGES_COLORS } from "helpers/constants/talentRequest";

/**
 * Displays the draggable tasks under a particular tasks status
 * @param {Object} prefix - index of status (i.e 0 if "Not Started")
 * @param {Array} elements - Array containing tasks details
 * @param {Function} setConfirmDeleteModal Function to set the boolean value on deleting the task card
 * @param {Function} setToDelete - Function to set item details to delete
 * @param {Object} permissions
 * @param {Function} onUpdate
 * @param {Function} onEdit
 * @returns
 */

const DraggableElement = ({ prefix, elements, onUpdate }) => {
  return (
    <Droppable droppableId={`${prefix}`}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ minHeight: "100px", minWidth: "380px" }}
        >
          <div className="">
            <div className="px-6 py-4 flex flex-row items-center justify-between">
              <div className="flex flex-row items-center space-x-4 font-lato">
                <div
                  className={`text-sm rounded-lg px-4 py-2 font-semibold ${TALENT_SHORTLIST_STAGES_COLORS[prefix]?.color} ${TALENT_SHORTLIST_STAGES_COLORS[prefix]?.backgroundColor}`}
                >
                  {prefix}
                </div>
                <p className="text-xs font-inter text-primary-gray-300">
                  {elements?.length || 0}
                </p>
              </div>
            </div>

            {elements?.length > 0 &&
              elements?.map((ele, index) => {
                return (
                  <>
                    <ListItem
                      key={ele.id}
                      item={ele}
                      index={index}
                      onUpdate={onUpdate}
                    />
                    {provided.placeholder}
                  </>
                );
              })}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default DraggableElement;
