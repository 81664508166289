import moment from "moment";
import React from "react";

const LiveCard = ({ item }) => {
  return (
    <div className="flex px-4 py-5 flex-start gap-4 border-b">
      <div className="">
        <img src={item.icon} alt="" className="w-full" />
      </div>

      <div className="flex flex-col flex-start gap-3 flex-[1_0_0]">
        <p className="font-inter text-sm font-light text-neutral-800">
          {item?.description || item?.text}
        </p>

        {item?.meta && (
          <div className="space-y-1">
            {Object.keys(item?.meta)?.map((a) => {
              return (
                <div className="flex gap-3 font-inter text-xs font-semibold text-neutral-500 capitalize">
                  {a}:
                  <p className="flex-[1_0_0] text-neutral-800">
                    {item?.meta[a]}
                  </p>
                </div>
              );
            })}
          </div>
        )}

        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            {item?.cgc?.image?.url && (
              <img
                src={item?.cgc?.image?.url}
                className="w-3 h-3 rounded-full"
              />
            )}
            <h1 className="font-inter text-[10px] font-semibold text-neutral-500">
              {item?.cgc?.name}
            </h1>
          </div>

          <h1 className="font-inter text-[10px] font0-light text-neutral-500">
            {moment(item?.createdAt).format("ll")}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default LiveCard;
