import { useEffect, useState } from "react";
import ChronosButton from "components/Comman/Buttons";
import WideModalsWrapper from "components/Modals/ModalsWrapper/WideModalWrapper";
import {
  constantOptions,
  profilingSections,
} from "helpers/constants/sourcingDrive";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAttributesType } from "redux/dropdown";
import AddEditProfilingAttributes from "../Modals/ProfilingAttributes/AddEditProfilingAttributes";
import { Pencil, Plus } from "@phosphor-icons/react";

function ProfilingAttributes({
  details,
  setDetails,
  fetching,
  defaultSection = false,
  type,
}) {
  const dispatch = useDispatch();
  const types = useSelector((state) => state.dropdown.attributeTypes);
  const [selectedItem, setSelectedItem] = useState();
  const [options, setOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(() => {
    if (details?.templateSections) {
      updateOptionStates(details?.templateSections);
    } else if (details?.sectionMetaData && type == "edit") {
      updateOptionStates(details?.sectionMetaData);
    } else {
      setOptions(constantOptions);
      setSelectedOptions(constantOptions);
    }
  }, [fetching, details?.templateSections]);

  const updateOptionStates = (sectionMetaData) => {
    let temp = constantOptions;

    profilingSections?.map((key) => {
      if (sectionMetaData[key]?.length > 0) {
        let cQuestions = temp[key]?.map((i) => i?.id);
        let newQuestions = sectionMetaData[key]?.filter(
          (i) => !cQuestions?.includes(i?.id)
        );
        temp[key] = temp[key].concat(newQuestions);
      }
    });

    setOptions(temp);
    setSelectedOptions(sectionMetaData);
  };

  useEffect(() => {
    if (types?.length == 0) {
      dispatch(fetchAllAttributesType());
    }
  }, []);

  useEffect(() => {
    setDetails({ ...details, sectionMetaData: selectedOptions });
  }, [selectedOptions]);
  return (
    <>
      <WideModalsWrapper
        height="100vh"
        component={
          <AddEditProfilingAttributes
            isOpen={selectedItem ? true : false}
            details={selectedItem}
            setDetails={(val) => {
              let key = val.section;
              let tempOp = { ...options };
              let d = [...tempOp[key]];

              if (d?.find((i) => i?.oldId == val?.oldId)) {
                d?.map((i, idx) => {
                  if (i?.oldId == val?.oldId) {
                    d[idx] = {
                      ...val,
                      oldId: val?.id,
                      question: val?.question || "",
                    };
                  }
                });
              } else {
                d.push({
                  ...val,
                  oldId: val?.id,
                  question: val?.question || "",
                });
              }

              tempOp[key] = [...d];

              let tempSOp = { ...selectedOptions };
              d = [...tempSOp[key]];
              if (d?.find((i) => i?.oldId == val?.oldId)) {
                d?.map((i, idx) => {
                  if (i?.oldId == val?.oldId) {
                    d[idx] = {
                      ...val,
                      oldId: val?.id,
                      question: val?.question || "",
                    };
                  }
                });
              } else {
                d.push({
                  ...val,
                  oldId: val?.id,
                  question: val?.question || "",
                });
              }
              tempSOp[key] = [...d];

              setOptions(tempOp);
              setSelectedOptions(tempSOp);
            }}
            closeModal={() => setSelectedItem()}
          />
        }
        isOpen={selectedItem ? true : false}
        closeModal={() => setSelectedItem()}
      />
      <div className="w-full space-y-6">
        <div className="w-full flex flex-col space-y-1.5 px-4 md:px-0">
          <h1 className="font-lato text-lg text-primary-green-700 font-medium">
            {defaultSection ? "Mandatory Questions" : "Optional Questions"}
          </h1>
          {/* <h2 className="inter font-normal text-sm text-primary-gray-600">
            These cannot be changed once the drive is created, as it will result
            in data inconsistency.
          </h2> */}
        </div>

        {Object.keys(options)?.map((key) => {
          if (
            (defaultSection == true && key == "Personal Information") ||
            (defaultSection == false && key !== "Personal Information")
          )
            return (
              <div
                key={key}
                className="flex flex-col items-start w-full space-y-6"
              >
                <div className="flex flex-row items-center space-x-4 cursor-pointer px-4 md:px-0">
                  <div className="h-0.5 w-6 bg-primary-green-lightest" />
                  <label
                    htmlFor={key}
                    className="font-karla text-base text-primary-gray-800 font-medium"
                  >
                    {key}
                  </label>
                  <div className="font-lato text-sm text-primary-gray-250 font-light">
                    ({options[key]?.length || 0} Questions)
                  </div>
                </div>

                <div className="w-full flex flex-col items-start space-y-6">
                  {options[key]?.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className="text-left w-full flex flex-row justify-start items-start space-x-6 bg-white rounded-2xl px-8 py-4 text-primary-gray-800 font-lato font-semibold text-sm leading-6"
                      >
                        <input
                          type="checkbox"
                          value={true}
                          checked={
                            selectedOptions[key]?.find((i) => i?.id == item?.id)
                              ? true
                              : false
                          }
                          onChange={() => {
                            let tempOp = { ...selectedOptions };
                            let d = [...tempOp[key]];
                            if (d?.find((x) => x?.id == item?.id)) {
                              d = d?.filter((x) => x?.id !== item?.id);
                            } else {
                              d.push(item);
                            }

                            tempOp[key] = [...d];
                            setSelectedOptions(tempOp);
                          }}
                          className="text-primary-green-700 focus:ring-0 mt-1"
                        />
                        {item?.type == "attributes" ? (
                          <div className="w-full">
                            <div className="flex flex-col items-start space-y-5 w-full">
                              <div className="flex flex-row items-center space-x-2.5 justify-between w-full">
                                {item?.question && item?.question !== "" ? (
                                  <h3 className="text-primary-gray-800 font-lato font-semibold text-sm leading-6">
                                    {item?.question}
                                  </h3>
                                ) : item?.name && item?.name !== "" ? (
                                  <h3 className="text-primary-gray-800 font-lato font-semibold text-sm leading-6">
                                    {item?.name}
                                  </h3>
                                ) : (
                                  <h3 className="text-primary-gray-350 font-lato font-semibold text-sm leading-6">
                                    {item?.placeholder}
                                  </h3>
                                )}

                                <Pencil
                                  onClick={() => {
                                    setSelectedItem({ ...item });
                                  }}
                                  className="w-3 h-3 text-primary-green-700 cursor-pointer"
                                />
                              </div>
                              <div className="flex flex-row items-start space-x-8">
                                <h3 className="text-primary-gray-350 font-lato font-light text-sm">
                                  Options:
                                </h3>
                                <div className="flex flex-row items-center flex-wrap text-xs -ml-2 -mt-2">
                                  {item?.list?.map(
                                    (option, idx) =>
                                      (
                                        <div
                                          key={idx}
                                          className="m-2 components-controls-attributes-single-div"
                                        >
                                          <div className="py-2 px-6 rounded-full bg-primary-green-30 border border-primary-green-700">
                                            <p className="text-primary-green-700 font-lato text-xs">
                                              {option?.name}
                                            </p>
                                          </div>
                                        </div>
                                      ) || (
                                        <div>
                                          <h6>No options</h6>
                                        </div>
                                      )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <h3 className="text-primary-gray-800 font-lato font-semibold text-sm leading-6">
                            {item?.name}
                          </h3>
                        )}
                      </div>
                    );
                  })}
                  {key !== "Personal Information" && (
                    <div className="flex flex-row items-center justify-start px-4">
                      <ChronosButton
                        text="Add Question"
                        icon={<Plus size={12} className="mr-2" />}
                        reverseIcon
                        underline
                        tertiary
                        onClick={() => {
                          let tempOp = { ...options };

                          let t = [...tempOp[key]];
                          let obj = {
                            name: "",
                            type: "attributes",
                            placeholder: "Select an segment",
                            id: t?.length + 1,
                            oldId: t?.length + 1,
                            section: key,
                          };
                          t.push(obj);

                          tempOp[key] = t;

                          setOptions(tempOp);
                          setSelectedItem(obj);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
        })}
      </div>
    </>
  );
}

export default ProfilingAttributes;
