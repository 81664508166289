// Kanban board
import DragList from "./DragList";

/**
 * BoardView Component
 * @param {Object} data
 * @returns
 */

function BoardView({ data, fetching, onUpdate }) {
  return (
    <div className="kanban-wrapper mx-auto overflow-x-auto pb-32">
      <DragList fetching={fetching} data={data} onUpdate={onUpdate} />
    </div>
  );
}

export default BoardView;
