import { Check, Plus } from "@phosphor-icons/react";
import React from "react";
import { useSelector } from "react-redux";

function TemplateSelection({ details, setDetails }) {
  const templates = useSelector((state) => state?.sourcingDrive?.templates);
  return (
    <div className="bg-white p-6 space-y-4 relative rounded-2xl w-full lg:w-8/12">
      <h1 className="font-karla font-medium text-base text-primary-green-700">
        Profiling Templates
      </h1>
      <h2 className="font-lato font-light leading-6 text-sm text-primary-gray-800">
        Select a template to edit
      </h2>

      <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 h-[20vh] overflow-y-auto">
        {templates?.length > 0 ? (
          templates?.map((t) => {
            return (
              <div
                onClick={() => {
                  setDetails({
                    ...details,
                    templateId: t?.id,
                    category: t?.category,
                    type: t?.type,
                    role_id: t?.role_id,
                    templateSections: t?.sectionMetaData,
                  });
                }}
                className={`p-4 border cursor-pointer space-y-2 rounded-2xl w-full relative ${
                  t?.id == details?.templateId
                    ? "border-primary-green-700 bg-primary-green-30"
                    : "border-primary-gray-80"
                } `}
              >
                <p className="font-lato text-sm font-semibold text-primary-green-900">
                  {t?.name}
                </p>
                <p className="font-lato text-xs font-light line-clamp-2 text-primary-green-900">
                  {t?.description}
                </p>
                <p className="font-lato text-xs font-semibold text-primary-green-700">
                  {t?.role?.role_name}
                </p>
              </div>
            );
          })
        ) : (
          <span
            className={`mt-5 font-karla block text-sm md:text-base leading-5 md:leading-6 text-red-600`}
          >
            No Profiling templates found!
          </span>
        )}
      </div>
    </div>
  );
}

export default TemplateSelection;
