import { useEffect, useState } from "react";
import PageHeader from "components/Layout/PageHeader";
import {
  USER_TYPE_CUSTOMER_FIC,
  USER_TYPE_CUSTOMER_FWS,
  USER_TYPE_USER,
} from "helpers/constants/users";
import { RadioTabs } from "components/Comman/Tabs";
import { useLocation } from "react-router-dom";
import DropdownInput from "components/Comman/Inputs/DropdownInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCollege, fetchAllRoles } from "redux/dropdown";
import DropdownInputForObject from "components/Comman/Inputs/DropdownInputForObject";
import { getAllFTPs } from "config/APIs/ftp";
import { getAllUsers } from "config/APIs/community";
import FTPCard from "./Card/FTPCard";
import FWSCard from "./Card/FWSCard";
import FICCard from "./Card/FICCard";
import CoreCard from "./Card/CoreCard";
import MemberCard from "./Card/MemberCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function CommunityLaptopView() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const roles = useSelector((state) => state?.dropdown?.roles);
  const colleges = useSelector((state) => state.dropdown.colleges);
  const [selectedTab, setSelectedTab] = useState("ftp");
  const [fetching, setFetching] = useState(false);
  const [filter, setFilter] = useState({});
  const [roleId, setRoleId] = useState();
  const [list, setList] = useState([]);

  useEffect(() => {
    setSelectedTab(location?.state?.userType);
  }, [location?.state]);

  useEffect(() => {
    setRoleId(location?.state?.roleId);
  }, [location?.state]);

  useEffect(() => {
    if (roles.length === 0) {
      dispatch(fetchAllRoles());
    }
    if (colleges.length === 0) {
      dispatch(fetchAllCollege());
    }
  }, []);

  const getList = async () => {
    setFetching(true);
    let query = { ...filter };
    switch (selectedTab) {
      case "ftp":
        try {
          if (roleId) query["roleId"] = roleId;
          console.log(query);
          const response = await getAllFTPs(query);
          let data = response.data.data;
          setList(data);
        } catch (err) {
          console.log(err);
        }
        break;
      default:
        try {
          query["userType"] = selectedTab;
          if (roleId) query["roleId"] = roleId;
          const response = await getAllUsers(query);
          let data = response.data.data;
          setList(data);
        } catch (err) {
          console.log(err);
        }
    }
    setFetching(false);
  };

  useEffect(() => {
    if (!fetching && roleId && selectedTab) getList();
  }, [selectedTab, roleId, filter]);

  const viewDetailsHandler = (item) => {
    console.log(item);
    history.push(`/home/community/roleWise/${item?.id}`);
  };

  return (
    <>
      <PageHeader back name="Community" />

      <div className="lg:px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="bg-white rounded-2xl">
          <div className="w-full border-b border-primary-gray-100 px-6 py-4 hidden lg:block">
            <RadioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={[
                {
                  label: "Future Talent Pool",
                  value: "ftp",
                },
                {
                  label: "Fusion Work Study",
                  value: USER_TYPE_CUSTOMER_FWS,
                },
                {
                  label: "Fusion Industrial Contracts",
                  value: USER_TYPE_CUSTOMER_FIC,
                },
                {
                  label: "Core",
                  value: USER_TYPE_USER,
                },
              ]}
            />
          </div>
          <div className="bg-primary-gray-50 w-full p-4 space-y-6">
            <div className="w-full lg:w-1/2 lg:px-6 lg:py-4">
              <DropdownInput
                label="Role"
                horizontal
                value={roleId}
                setValue={(val) => {
                  setRoleId(val);
                }}
                list={roles?.map((r) => ({
                  label: r?.role_name,
                  value: r?.id,
                }))}
              />
            </div>
            <div className="w-full lg:px-6 flex flex-row items-center space-x-6">
              <div className="min-w-max text-primary-green-dark text-sm font-lato font-semibold">
                Filter By:
              </div>
              <div className="w-full lg:w-1/2 flex flex-row items-center space-x-6 overflow-x-auto">
                <DropdownInputForObject
                  srOnly
                  label="Skill Level"
                  field="skillLevel"
                  details={filter}
                  setDetails={setFilter}
                  list={roles?.map((r) => ({
                    label: r?.role_name,
                    value: r?.id,
                  }))}
                />
                <DropdownInputForObject
                  srOnly
                  label="College"
                  field="collegeId"
                  details={filter}
                  setDetails={setFilter}
                  list={colleges?.map((r) => ({
                    label: r?.college_name,
                    value: r?.id,
                  }))}
                />
                <DropdownInputForObject
                  srOnly
                  label="Course"
                  field="course"
                  details={filter}
                  setDetails={setFilter}
                  list={roles?.map((r) => ({
                    label: r?.role_name,
                    value: r?.id,
                  }))}
                />
              </div>
            </div>
            <div className="w-full lg:px-6 -mt-3 py-3 flex flex-row items-center space-x-6 border-b border-primary-gray-200">
              <p className="text-primary-gray-400 text-xs font-lato">
                No Filters Added
              </p>
            </div>
            <div className="w-full md:grid flex flex-col md:grid-cols-2 lg:grid-cols-3 gap-4 h-60vh lg:h-auto overflow-y-auto">
              {list?.length > 0 ? (
                <>
                  {list?.map((item) => {
                    if (window?.innerWidth >= 1024)
                      switch (selectedTab) {
                        case "ftp":
                          return (
                            <FTPCard
                              item={item}
                              cta={() => {
                                console.log(item);
                                history.push({
                                  pathname: `/home/community/${item?.id}`,
                                  state: selectedTab,
                                });
                              }}
                            />
                          );
                        case USER_TYPE_CUSTOMER_FWS:
                          return (
                            <FWSCard
                              item={item}
                              cta={() => {
                                console.log(item);
                                history.push({
                                  pathname: `/home/community/${item?.id}`,
                                  state: selectedTab,
                                });
                              }}
                            />
                          );
                        case USER_TYPE_CUSTOMER_FIC:
                          return (
                            <FICCard
                              item={item}
                              cta={() => {
                                console.log(item);
                                history.push({
                                  pathname: `/home/community/${item?.id}`,
                                  state: selectedTab,
                                });
                              }}
                            />
                          );
                        case USER_TYPE_USER:
                          return (
                            <CoreCard
                              item={item}
                              cta={() => {
                                console.log(item);
                                history.push({
                                  pathname: `/home/community/${item?.id}`,
                                  state: selectedTab,
                                });
                              }}
                            />
                          );
                        default:
                          <></>;
                      }
                    else return <MemberCard item={item} />;
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommunityLaptopView;
