import { User } from "@phosphor-icons/react";
import MandatoryResponse from "components/Applicant/Response/Mandatory";
import OptionalResponses from "components/Applicant/Response/Optional";
import ChronosButton from "components/Comman/Buttons";
import { LongTabs } from "components/Comman/Tabs";
import PageHeader from "components/Layout/PageHeader";
import {
  getApplicantResponse,
  updateApplicant,
  updateApplicantDrive,
} from "config/APIs/applicants";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const tabs = [
  { label: "Mandatory Questions", value: "mandatory" },
  { label: "Optional Questions", value: "optional" },
];

function ApplicantResponse() {
  const { id } = useParams();
  const location = useLocation();
  const [driveId, setDriveId] = useState();
  const [drive, setDrive] = useState();
  const [details, setDetails] = useState();
  const [response, setResponse] = useState();
  const [viewed, setViewed] = useState(false);
  const [selectedTab, setSelectedTab] = useState("mandatory");

  useEffect(() => {
    setDriveId(location?.state?.driveId);
  }, [location?.state]);

  useEffect(() => {
    if (id && driveId) getDetails();
  }, [id, driveId]);

  const getDetails = async () => {
    try {
      const res = await getApplicantResponse(id, driveId);
      const { applicant, drive, response } = res.data.data;
      setDetails(applicant);
      setDrive(drive);
      setResponse(response);
      setViewed(applicant?.appliedDrives[0]?.userSourcingDrive?.viewed);
    } catch (err) {
      console.log("Fetch drive error", err);
    }
  };

  const updateDetails = async (body) => {
    try {
      const res = await updateApplicant(id, body);
      getDetails();
    } catch (err) {
      console.log("Fetch drive error", err);
    }
  };

  const markAsViewed = async () => {
    try {
      const res = await updateApplicantDrive(id, driveId, { viewed: true });
      getDetails();
    } catch (err) {
      console.log("Fetch drive error", err);
    }
  };
  return (
    <>
      <PageHeader
        back
        name="Applicant Response"
        ctaComponent={
          <div className="flex flex-row items-center justify-end space-x-5">
            {!viewed && (
              <ChronosButton
                text="Mark as Viewed"
                tertiary
                onClick={() => {
                  markAsViewed();
                }}
              />
            )}
            {details?.shortlisted == null ? (
              <ChronosButton
                text="Shortlist Applicant"
                secondary
                onClick={() => {
                  updateDetails({ shortlisted: { date: moment() } });
                }}
              />
            ) : (
              <ChronosButton text="Shortlisted" tertiary onClick={() => {}} />
            )}
          </div>
        }
      />
      <div className="px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px] space-y-6">
        <div className="w-full bg-white rounded-2xl justify-between p-4 lg:py-8 lg:px-10 flex flex-col items-start space-y-2.5 lg:space-y-0 lg:flex-row lg:items-center lg:space-x-2">
          <div className="flex flex-row items-center space-x-5">
            {details?.image?.url ? (
              <img
                className="h-20 w-20 rounded-full object-cover"
                src={details?.image?.url || "/assets/images/defaultUser.png"}
                alt=""
              />
            ) : (
              <div className="h-20 w-20 text-primary-green-700 rounded-full object-cover bg-primary-green-30 flex flex-row items-center justify-center">
                <User size={64} />
              </div>
            )}
            <div className="flex flex-col items-start space-y-4">
              <h1 className="text-primary-gray-800 font-lato text-lg font-semibold">
                {details?.firstName}{" "}
                {details?.lastName ? details?.lastName : ""}
              </h1>
              <p className="text-primary-gray-400 font-lato text-xs font-light">
                Applied on. {moment(response?.createdAt).format("ll")}
              </p>
            </div>
          </div>
          <div className="h-full space-y-2 items-end flex flex-col w-full lg:w-2/12">
            <h1 className="text-primary-green-700 text-lg font-semibold font-lato">
              24/30
            </h1>
            <p className="text-primary-gray-400 font-lato text-sm font-light">
              Score
            </p>
          </div>
        </div>

        <div className="bg-white rounded-2xl font-lato text-primary-gray-800 px-2 space-y-6">
          <LongTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />

          {selectedTab == "mandatory" && (
            <MandatoryResponse
              response={
                response?.sectionMetaData["Personal Information"]
                  ? response?.sectionMetaData["Personal Information"]
                  : []
              }
            />
          )}
          {selectedTab == "optional" && (
            <OptionalResponses
              driveSection={drive?.sectionMetaData}
              response={response?.sectionMetaData}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ApplicantResponse;
