import React from "react";

const DrivesCard = ({ drive, onClick, type }) => {
  return (
    <div className="flex p-4 flex-col flex-start gap-4 border bg-white rounded-lg shadow-md">
      <div className="flex items-center gap-4 ">
        {drive?.image?.url ? (
          <img
            src={drive?.image?.url}
            className="w-8 h-8 object-contain rounded-full"
          />
        ) : (
          <div className="w-8 h-8 rounded-full bg-[#D9D9D9]" />
        )}
        <div className="flex flex-col flex-start flex-[1_0_0] cursor-pointer">
          <h1 className="font-poppins text-base font-medium text-neutral-800">
            {drive?.name || drive?.college_name}
          </h1>
          {drive?.colleges?.length > 0 && drive?.colleges[0]?.cgc && (
            <h1 className="font-inter text-[10px] font-light text-neutral-500">
              CGC {drive?.colleges[0]?.cgc?.name}
            </h1>
          )}
        </div>
      </div>

      <div className="flex py-2 flex-col justify-center gap-3 flex-end">
        <div className="flex px-3 py-2 flex-col gap-2 flex-start border border-[#75DD67] rounded-lg">
          <h1 className="text-neutral-800 font-inter font-light text-[10px]">
            Total Applicants
          </h1>
          <div className="flex flex-col flex-start gap-2">
            <h1 className="font-inter text-xl font-semibold text-primary-green-dark">
              {drive?.applicantsLength}
            </h1>
            <div className="flex items-center">
              <div className="flex flex-col items-center mt-[2px] mr-[2px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  className=""
                >
                  <path
                    d="M12.5374 2.88577L9.814 3.2139C9.72377 3.22483 9.68548 3.33558 9.74974 3.39983L10.5605 4.21058L7.47064 7.30042L6.07884 5.90999C5.99271 5.82386 5.85462 5.82522 5.76986 5.90999L1.37162 10.3096C1.35126 10.3302 1.33984 10.3579 1.33984 10.3868C1.33984 10.4158 1.35126 10.4435 1.37162 10.4641L1.98685 11.0821C2.02923 11.1244 2.09896 11.1244 2.14134 11.0821L5.92435 7.30042L7.31478 8.69085C7.40091 8.77562 7.539 8.77562 7.62376 8.69085L11.3343 4.98304L12.1451 5.79378C12.1596 5.80824 12.1779 5.81833 12.1979 5.8229C12.2178 5.82748 12.2387 5.82635 12.2581 5.81966C12.2774 5.81296 12.2945 5.80097 12.3074 5.78503C12.3203 5.7691 12.3285 5.74986 12.331 5.72952L12.6591 3.00608C12.6687 2.93636 12.6085 2.8762 12.5374 2.88577Z"
                    fill="#008B46"
                  />
                </svg>
              </div>
              <h1 className="font-inter text-xs font-semibold text-primary-green-dark leading-[18px]">
                {drive?.weekApplicantsLength || 0} from this week
              </h1>
            </div>
          </div>
        </div>

        {type !== "cgc" && (
          <div
            onClick={onClick}
            className="flex px-2 flex-row justify-end w-full items-center gap-2 text-neutral-500"
          >
            <h1 className="font-inter text-xs font-semibold underline-offset-2 underline text-primary-green-400">
              View Details
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default DrivesCard;
