import React, { useEffect, useState } from "react";
import PageHeader from "components/Layout/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { updateCommunity } from "redux/community";
import SearchBox from "components/Comman/Inputs/SearchBox";
import { getFTPCount } from "config/APIs/ftp";

function CommunityCount() {
  const dispatch = useDispatch();
  const community = useSelector((state) => state.community);
  const [selectedTab, setSelectedTab] = useState("ftp");
  const [count, setCount] = useState({});
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });
  useEffect(() => {
    if (localStorage.getItem("communityCountTab")) {
      setSelectedTab(localStorage.getItem("communityCountTab"));
      localStorage.removeItem("communityCountTab");
    }
  }, []);

  const getCount = async () => {
    if (Object.keys(community?.count)?.length > 0) {
      setCount(community?.count);

      return;
    }
    setFetching(true);
    try {
      const response = await getFTPCount();
      let data = response.data.data;
      setCount(data);
      dispatch(updateCommunity({ key: "count", value: data }));
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => getCount(), [community?.count]);

  return (
    <>
      {window.innerWidth > 780 ? <PageHeader name="Community" /> : <></>}
      <div className="lg:px-5 pt-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="bg-primary-gray-50 rounded-2xl">
          <div className="px-5 py-2.5">
            <SearchBox
              placeholder="Find a role!"
              search={search}
              setSearch={setSearch}
            />
          </div>

          <div className="w-full px-4 pt-4 pb-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 h-70vh lg:h-auto overflow-y-auto">
            {community &&
              community?.count &&
              community?.count?.map((item) => {
                if (
                  search?.searchText == "" ||
                  (search?.searchText != "" &&
                    item?.role
                      ?.toLowerCase()
                      ?.includes(search?.searchText?.toLowerCase()))
                )
                  return (
                    <div className="relative shadow cursor-pointer bg-white p-4 rounded-2xl w-full h-40 flex flex-col justify-between space-y-4">
                      <h1 className="text-primary-gray-800 font-karla text-base font-medium">
                        {item?.role || "No Role"}
                      </h1>
                      <p
                        className={`flex flex-row justify-end text-5xl font-medium font-karla ${
                          item?.ftpCount < 1
                            ? "text-primary-red-lightest"
                            : item?.ftpCount > 0 && item?.ftpCount < 5
                            ? "text-primary-dawn-300"
                            : item?.ftpCount > 4 && item?.ftpCount < 10
                            ? "text-primary-yellow-100"
                            : item?.ftpCount > 9
                            ? "text-primary-green-lighter"
                            : "text-primary-green-lighter"
                        }`}
                      >
                        {item?.ftpCount}
                      </p>
                      <div className="absolute bottom-0 left-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="76"
                          height="76"
                          viewBox="0 0 76 76"
                          fill="none"
                        >
                          <g filter="url(#filter0_i_2335_28541)">
                            <path
                              d="M30 0L46.2635 29.7365L76 46L46.2635 62.2635L30 92L13.7365 62.2635L-16 46L13.7365 29.7365L30 0Z"
                              fill="#FAFAFA"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_i_2335_28541"
                              x="-16"
                              y="0"
                              width="92"
                              height="94"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="2" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite
                                in2="hardAlpha"
                                operator="arithmetic"
                                k2="-1"
                                k3="1"
                              />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="shape"
                                result="effect1_innerShadow_2335_28541"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default CommunityCount;
