import { Draggable } from "react-beautiful-dnd";
import { useEffect } from "react";
import ApplicantBoardCard from "components/Applicant/Card/ApplicantBoardCard";

/**
 * Displays the individual task under a particular project
 * @param {Object} item - Object containing task details
 * @returns
 */

const ListItem = ({ item, index, onUpdate }) => {
  useEffect(() => {}, [item]);

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            className="m-4 kanban-board"
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <ApplicantBoardCard index={index} item={item} onUpdate={onUpdate} />
          </div>
        );
      }}
    </Draggable>
  );
};

export default ListItem;
