import { User } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import StatusDropdown from "components/Comman/Inputs/StatusDropdown";
import { updateApplicant } from "config/APIs/applicants";
import {
  APPLICANT_STAGES,
  APPLICANT_STAGES_STATUS,
} from "helpers/constants/applicants";
import moment from "moment";
import React, { useEffect, useState } from "react";

function ShortlistedApplicantCard({ item, onUpdate, roleId }) {
  const [status, setStatus] = useState("");
  const [stage, setStage] = useState("");

  useEffect(() => {
    setStatus(item?.status);
    setStage(item?.stage);
  }, [item]);

  const updateStatus = async (val, stageVal) => {
    let mainBody = {
      appliedRoleId: roleId,
    };
    if (val) mainBody["status"] = val;
    if (stageVal) {
      mainBody["stage"] = stageVal;
      if (stageVal !== item?.stage) {
        mainBody["status"] = APPLICANT_STAGES_STATUS[stageVal][0];
      }
    }
    try {
      const response = await updateApplicant(item.id, mainBody);
      setStatus(val);
      onUpdate();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-white p-4 rounded-2xl shadow space-y-4 block">
      <div className="flex flex-row items-stretch justify-between space-x-2">
        <div className="flex flex-row items-center space-x-5">
          {item.image?.url ? (
            <img
              className="h-8 w-8 rounded-full object-cover"
              src={item.image?.url || "/assets/images/defaultUser.png"}
              alt=""
            />
          ) : (
            <div className="h-8 w-8 text-primary-green-700 rounded-full object-cover bg-primary-green-30 flex flex-row items-center justify-center">
              <User size={26} />
            </div>
          )}
          <div className="flex flex-col items-start space-y-0.5">
            <h1 className="text-primary-gray-400 font-lato text-xs font-semibold">
              {item?.firstName} {item?.lastName ? item?.lastName : ""}
            </h1>
            <p className="text-primary-gray-400 font-lato text-2xs font-light">
              {item?.branch}, {item?.collegeYear}
            </p>
          </div>
        </div>
        <div className="">
          <StatusDropdown
            srOnly
            label="Stage"
            details={item}
            value={stage}
            setValue={(val) => {
              updateStatus(null, val);
            }}
            list={APPLICANT_STAGES?.map((a) => ({
              label: a,
              value: a,
            }))}
            colorList={[
              "text-primary-orange-300",
              "text-primary-blue-400",
              "text-primary-green-300",
              "text-primary-yellow-700",
              "text-primary-red-darker",
            ]}
          />
        </div>
      </div>
      <div className="flex flex-col items-start space-y-1 text-primary-gray-400 font-lato text-2xs font-light">
        <p className="">{item?.college?.college_name}</p>
        <p className="">{item?.email}</p>
        <p className="">{item?.phoneNumber}</p>
      </div>
      <div className="w-3/4">
        <StatusDropdown
          srOnly
          label="Status"
          details={item}
          value={status}
          setValue={(val) => {
            updateStatus(val);
          }}
          list={APPLICANT_STAGES_STATUS[item?.stage]?.map((a) => ({
            label: a,
            value: a,
          }))}
          colorList={[
            "text-primary-orange-300",
            "text-primary-blue-400",
            "text-primary-green-300",
            "text-primary-yellow-700",
            "text-primary-red-darker",
          ]}
        />
      </div>
      <div className="flex flex-row items-center space-x-2.5 justify-between text-primary-gray-400 font-lato font-light">
        <p className="text-3xs">
          Shortlisted on. {moment(item?.shortlisted?.date).format("ll")}
        </p>
        <ChronosButton
          text="View Response"
          tertiary
          underline
          onClick={() => {
            history.push({
              pathname: `/applicant/${item?.id}/response`,
              state: { driveId: item?.userSourcingDrive?.sourcingDriveId },
            });
          }}
        />
      </div>
    </div>
  );
}

export default ShortlistedApplicantCard;
