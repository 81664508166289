import { User } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";

function ApplicantCard({ item }) {
  const history = useHistory();
  return (
    <>
      <div className="bg-white p-4 lg:px-10 space-y-4 hidden lg:block">
        <div className="flex flex-row items-stretch justify-between space-x-2">
          <div className="flex flex-row items-center space-x-5">
            {item.image?.url ? (
              <img
                className="h-12 w-12 rounded-full object-cover"
                src={item.image?.url || "/assets/images/defaultUser.png"}
                alt=""
              />
            ) : (
              <div className="h-12 w-12 text-primary-green-700 rounded-full object-cover bg-primary-green-30 flex flex-row items-center justify-center">
                <User size={40} />
              </div>
            )}
            <div className="flex flex-col items-start space-y-0.5">
              <h1 className="text-primary-gray-800 font-lato text-base font-semibold">
                {item?.firstName} {item?.lastName ? item?.lastName : ""}
              </h1>
              <p className="text-primary-gray-400 font-lato text-sm font-light">
                {item?.branch}, {item?.collegeYear}
              </p>
            </div>
          </div>
          <div className="h-full space-y-2 items-end flex flex-col">
            <h1 className="text-primary-green-700 text-lg font-semibold font-lato">
              24/30
            </h1>
            <p className="text-primary-gray-400 font-lato text-sm font-light">
              Score
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start space-y-1 text-primary-gray-400 font-lato text-sm font-light">
          <p className="">{item?.college?.college_name}</p>
          <p className="">{item?.email}</p>
          <p className="">{item?.phoneNumber}</p>
        </div>
        <div className="flex flex-row items-center space-x-2.5 justify-between text-primary-gray-400 font-lato font-light">
          <p className="text-xs">
            Applied on.{" "}
            {moment(item?.userSourcingDrive?.createdAt).format("ll")}
          </p>
          <ChronosButton
            text="View Response"
            tertiary
            underline
            onClick={() => {
              history.push({
                pathname: `/applicant/${item?.id}/response`,
                state: { driveId: item?.userSourcingDrive?.sourcingDriveId },
              });
            }}
          />
        </div>
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md space-y-4 block lg:hidden">
        <div className="flex flex-row items-stretch justify-between space-x-2">
          <div className="flex flex-row items-center space-x-5">
            {item.image?.url ? (
              <img
                className="h-8 w-8 rounded-full object-cover"
                src={item.image?.url || "/assets/images/defaultUser.png"}
                alt=""
              />
            ) : (
              <div className="h-8 w-8 text-primary-green-700 rounded-full object-cover bg-primary-green-30 flex flex-row items-center justify-center">
                <User size={26} />
              </div>
            )}
            <div className="flex flex-col items-start space-y-0.5">
              <h1 className="text-primary-gray-400 font-lato text-xs font-semibold">
                {item?.firstName} {item?.lastName ? item?.lastName : ""}
              </h1>
              <p className="text-primary-gray-400 font-lato text-2xs font-light">
                {item?.branch}, {item?.collegeYear}
              </p>
            </div>
          </div>
          <div className="h-full space-y-2 items-end flex flex-col">
            <h1 className="text-primary-green-700 text-lg font-semibold font-lato">
              24/30
            </h1>
            <p className="text-primary-gray-400 font-lato text-2xs font-light">
              Score
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start space-y-1 text-primary-gray-400 font-lato text-2xs font-light">
          <p className="">{item?.college?.college_name}</p>
          <p className="">{item?.email}</p>
          <p className="">{item?.phoneNumber}</p>
        </div>
        <div className="flex flex-row items-center space-x-2.5 justify-between text-primary-gray-400 font-lato font-light">
          <p className="text-3xs">
            Applied on.{" "}
            {moment(item?.userSourcingDrive?.createdAt).format("ll")}
          </p>
          <ChronosButton
            text="View Response"
            tertiary
            underline
            onClick={() => {
              history.push({
                pathname: `/applicant/${item?.id}/response`,
                state: { driveId: item?.userSourcingDrive?.sourcingDriveId },
              });
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ApplicantCard;
