import { User } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";

import { HeartIcon as HeartIconOutlined } from "@heroicons/react/outline";
import { HeartIcon } from "@heroicons/react/solid";

function WishlistCard({ item, addUser, selected }) {
  const history = useHistory();
  return (
    <>
      <div className="bg-white p-6 rounded-2xl xl:px-6 space-y-4 shadow-lg">
        <div className="flex flex-row items-stretch justify-between space-x-2">
          <div className="flex flex-row items-center w-full space-x-5">
            {item.image?.url ? (
              <img
                className="h-12 w-12 rounded-full object-cover"
                src={item.image?.url || "/assets/images/defaultUser.png"}
                alt=""
              />
            ) : (
              <div className="h-12 w-12 text-primary-green-700 rounded-full object-cover bg-primary-green-30 flex flex-row items-center justify-center">
                <User size={40} />
              </div>
            )}
            <div className="flex flex-col items-start space-y-0.5">
              <h1 className="text-primary-gray-800 font-lato text-base font-semibold">
                {item?.firstName} {item?.lastName ? item?.lastName : ""}
              </h1>
              <p className="text-primary-gray-400 font-lato text-sm font-light">
                {item?.branch}, {item?.collegeYear}
              </p>
            </div>
            <div className="grow self-start flex flex-row-reverse">
              {selected ? (
                <HeartIconOutlined
                  className="h-6 w-6"
                  onClick={() => addUser(item)}
                />
              ) : (
                <HeartIcon className="h-6 w-6" />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start space-y-1 text-primary-gray-400 font-lato text-sm font-light">
          <p className="">{item?.college?.college_name}</p>
          <p className="">{item?.email}</p>
        </div>
        <div className="flex flex-row w-full justify-center items-center gap-4">
          <div className="flex  justify-center w-[80px] p-2 items-center bg-primary-blue-100 rounded-lg">
            <p className="text-base text-primary-gray-300 font-semibold">
              Status
            </p>
          </div>
          <div className="grow p-2 bg-primary-gray-150 rounded-lg flex flex-row-reverse justify-center">
            <p className="">Status Dropdown</p>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-2.5 justify-between text-primary-gray-400 font-lato font-light">
          <p className="flex text-xs text-ellipsis">
            Shortlisted on:{" "}
            {moment(item?.userSourcingDrive?.createdAt).format("ll")}
          </p>
          <ChronosButton
            text="View Response"
            tertiary
            underline
            onClick={() => {
              history.push({
                pathname: `/applicant/${item?.id}/response`,
                state: { driveId: item?.userSourcingDrive?.sourcingDriveId },
              });
            }}
          />
        </div>
      </div>
    </>
  );
}

export default WishlistCard;
