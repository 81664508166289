import { useState } from "react";
import AddCGC from "./AddCGC";
import ChronosButton from "components/Comman/Buttons";
import EmptyState from "components/Comman/EmptyState";
import SearchBox from "components/Comman/Inputs/SearchBox";

function CgcList({ list, selectedCgc, setSelectedCgc, selectedCrew }) {
  const [addCGC, setAddCGC] = useState(false);
  const [search, setSearch] = useState();

  return (
    <>
      <AddCGC
        isOpen={addCGC}
        closeModal={() => {
          setAddCGC(false);
        }}
        selectedCgcs={list?.map((i) => i?.id)}
        selectedCrew={selectedCrew}
      />
      <div className="px-4 flex pb-6 flex-col space-y-4 w-full">
        <div className="pt-4 pb-2 text-primary-green-dark font-poppins text-xl font-medium leading-9">
          CGCs
        </div>
        <SearchBox
          dark
          search={search}
          setSearch={(val) => {
            setSearch(val);
          }}
        />
        <ChronosButton
          primary
          dark
          text="+ Link CGC"
          onClick={() => {
            setAddCGC(true);
          }}
        />
        <div className="flex max-h-[70vh] overflow-y-auto pb-6 flex-col space-y-4 w-full">
          {list?.length > 0 ? (
            <>
              {list?.map((item) => {
                if (
                  !search?.searchText ||
                  search?.searchText == "" ||
                  (search?.searchText &&
                    search?.searchText !== "" &&
                    item?.name
                      ?.toLowerCase()
                      ?.includes(search?.searchText?.toLowerCase()))
                )
                  return (
                    <div
                      onClick={() => {
                        setSelectedCgc(item);
                      }}
                      className={`w-full shadow-md rounded-2xl p-5 text-primary-neutral-800 flex flex-row space-x-4 items-center ${
                        item?.id == selectedCgc?.id
                          ? "bg-primary-green-30 border border-primary-green-medium"
                          : "bg-white"
                      }`}
                    >
                      <div className="flex flex-col space-y-2.5">
                        <h1 className="font-inter text-lg font-semibold leading-7">
                          {item?.name}
                        </h1>
                        <p className="font-inter text-sm font-light text-primary-neutral-400">
                          {item?.colleges?.length || 0} Colleges
                        </p>
                      </div>
                    </div>
                  );
              })}
            </>
          ) : (
            <>
              <EmptyState
                imageMedium
                image="/assets/images/empty/college.png"
                text="No CGCs found!"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CgcList;
