import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  rankWiseList: [],
  count: [],
};

// Storing members
export const community = createSlice({
  name: "community",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload.list;
    },
    updateRankWise: (state, action) => {
      let list = action.payload.list;

      let ranks = [];
      let mem = [];
      list.map((item) => {
        let rank = item?.designation?.rank;
        if (ranks.find((e) => e?.id == rank?.id)) {
          mem.map((item1) => {
            if (item1?.id == rank?.id) {
              item1.members.push(item);
            }
          });
        } else {
          let obj = { ...rank };
          obj["members"] = [item];
          ranks.push(rank);
          mem.push(obj);
        }
      });

      state.rankWiseList = mem;
    },
    updateCommunity: (state, action) => {
      let key = action.payload.key;
      let value = action.payload.value;

      state[key] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList, updateRankWise, updateCommunity } =
  community.actions;

export default community.reducer;
