import EmptyState from "components/Comman/EmptyState";
import { useSelector } from "react-redux";

function CrewList({ list, selectedCrew, setSelectedCrew }) {
  const colleges = useSelector((state) => state?.college?.list);
  return (
    <div className="px-4 flex pb-6 flex-col space-y-4 w-full">
      <div className="pt-4 pb-2 text-primary-green-dark font-poppins text-xl font-medium leading-9">
        CREWs
      </div>
      {list?.length > 0 ? (
        <>
          {list?.map((item) => {
            return (
              <div
                onClick={() => {
                  setSelectedCrew(item);
                }}
                className={`w-full shadow-md rounded-2xl p-5 text-primary-neutral-800 flex flex-row space-x-4 items-center ${
                  item?.id == selectedCrew?.id
                    ? "bg-primary-green-30 border border-primary-green-medium"
                    : "bg-white"
                }`}
              >
                <img
                  src={item?.image?.url}
                  alt=""
                  className="rounded-full w-20 h-20 object-cover"
                />

                <div className="flex flex-col space-y-2.5">
                  <h1 className="font-inter text-lg font-semibold leading-7">
                    {item?.name}
                  </h1>
                  <div className="flex flex-row items-center space-x-2">
                    <p className="font-inter text-sm font-light text-primary-neutral-400">
                      {item?.cgcs?.length || 0} CGCs
                    </p>
                    <p className="font-inter text-sm font-light text-primary-neutral-400">
                      •{" "}
                      {colleges?.filter((a) =>
                        item?.cgcs?.map((x) => x?.id)?.includes(a?.cgcId)
                      )?.length || 0}{" "}
                      Colleges
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <EmptyState
            imageMedium
            image="/assets/images/empty/college.png"
            text="No CREWs found!"
          />
        </>
      )}
    </div>
  );
}

export default CrewList;
