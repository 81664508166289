import CommunityLaptopView from "components/Community/LaptopView";

function RoleWiseCommunity() {
  return (
    <>
      <CommunityLaptopView />
    </>
  );
}

export default RoleWiseCommunity;
