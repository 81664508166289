import { createSlice } from "@reduxjs/toolkit";
import { getAllEntity } from "config/APIs/talent";
import { feBaseLink } from "config";

const initialState = {
  list: [],
};

// Storing members
export const talent = createSlice({
  name: "talent",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload.list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = talent.actions;

export default talent.reducer;

export function fetchAllTalentRequest() {
  return async (dispatch) => {
    try {
      const response = await getAllEntity();
      if (response.status === 200) {
        let data = response.data.data;
        // Dispatching roles data to redux-store
        dispatch(
          updateList({
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("SD fetching error", err);
    }
  };
}
