import ShortlistedApplicantCard from "components/Applicant/Card/ShortlistedApplicantCard";
import EmptyState from "components/Comman/EmptyState";

function ShortlistedList({ roleId, onUpdate, data }) {
  return (
    <div className="py-7 space-y-8 px-4 lg:px-8">
      <div className="py-4 px-2 lg:p-4 bg-primary-gray-50 rounded-lg">
        <div className="space-y-3 lg:space-y-1">
          {data?.length > 0 ? (
            data?.map((t) => {
              return (
                <>
                  <ShortlistedApplicantCard
                    item={t}
                    onUpdate={onUpdate}
                    roleId={roleId}
                  />
                </>
              );
            })
          ) : (
            <EmptyState text={`No shortlisted applicant found!`} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ShortlistedList;
